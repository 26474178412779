import Country from "@Shared/types/enums/Country";
import { CountryPageMeta, PageMeta } from "@Shared/types/types";
import lReduce from "lodash/reduce";
import lMapValues from "lodash/mapValues";

export enum WorkshopPage {
    DASHBOARD = "DASHBOARD",
    CALENDAR = "CALENDAR",
    ORDERS = "ORDERS",
    SETTINGS = "SETTINGS",
    LOGIN = "LOGIN",
    SIGNUP = "SIGNUP",
}

export const workshopPageToMeta: { [k in WorkshopPage]: PageMeta } = {
    DASHBOARD: {
        url: { [Country.CA]: "/dashboard", [Country.LT]: "/panele" },
        title: {
            [Country.CA]: "Dashboard",
            [Country.LT]: "Informacijos panelė | Nevažiuoja",
        },
        description: {
            [Country.CA]: "Dashboard",
            [Country.LT]: "Informacijos panelė",
        },
    },
    CALENDAR: {
        url: "/",
        title: {
            [Country.CA]: "Calendar",
            [Country.LT]: "Kalendorius | Nevažiuoja",
        },
        description: {
            [Country.CA]: "Calendar",
            [Country.LT]: "Kalendorius",
        },
    },
    ORDERS: {
        url: { [Country.CA]: "/invoices", [Country.LT]: "/saskaitos" },
        title: {
            [Country.CA]: "Invoices",
            [Country.LT]: "Sąskaitos | Nevažiuoja",
        },
        description: {
            [Country.CA]: "Invoices",
            [Country.LT]: "Sąskaitos",
        },
    },
    SETTINGS: {
        url: { [Country.CA]: "/settings", [Country.LT]: "/nustatymai" },
        title: {
            [Country.CA]: "Settings",
            [Country.LT]: "Nustatymai | Nevažiuoja",
        },
        description: {
            [Country.CA]: "Settings",
            [Country.LT]: "Nustatymai",
        },
    },
    LOGIN: {
        url: { [Country.CA]: "/login", [Country.LT]: "/prisijungti" },
        title: {
            [Country.CA]: "Login",
            [Country.LT]: "Prisijungti | Nevažiuoja",
        },
        description: {
            [Country.CA]: "Login",
            [Country.LT]: "Prisijungti",
        },
    },
    SIGNUP: {
        url: { [Country.CA]: "/register", [Country.LT]: "/registracija" },
        title: {
            [Country.CA]: "Registration",
            [Country.LT]: "Registracija | Nevažiuoja",
        },
        description: {
            [Country.CA]: "Register",
            [Country.LT]: "Registracija",
        },
    },
};

export function countryWorkshopPageToMeta(country: Country): {
    [k in WorkshopPage]: CountryPageMeta;
} {
    const countryPagesMeta = lMapValues(workshopPageToMeta, (meta) => {
        return {
            ...meta,
            url: typeof meta.url === "object" ? meta.url[country] : meta.url,
            title: meta.title[country],
            description: meta.description[country],
            path: meta.path ? meta.path[country] : undefined,
        };
    });
    return countryPagesMeta;
}

export const workshopUrlToPage = (country: Country) => {
    const res = lReduce(
        workshopPageToMeta,
        (res, meta, pageEnum) => {
            const path =
                typeof meta.path === "object" ? meta.path[country] : meta.path;
            const revObj = {} as { [url: string]: WorkshopPage };

            if (path) {
                if (Array.isArray(path)) {
                    path.forEach((p) => {
                        revObj[p] = pageEnum as WorkshopPage;
                    });
                } else {
                    revObj[path] = pageEnum as WorkshopPage;
                }
            } else {
                const url =
                    typeof meta.url === "object" ? meta.url[country] : meta.url;
                revObj[url] = pageEnum as WorkshopPage;
            }
            return {
                ...res,
                ...revObj,
            };
        },
        {}
    ) as { [url: string]: WorkshopPage };
    return res;
};
