import Country from "@Shared/types/enums/Country";
import { City } from "@Shared/consts/CITIES";
import LangCode from "@Shared/types/enums/LangCode";

export type CountryMeta = {
    currencySymbol: string;
    currencyCode: string;
    currencyIconClass: string;
    langCode: LangCode;
    clientEmail: string;
    domain: string;
    baseUrl: string;
    defaultCity: City;
    country: string;
    countryWhere: string;
    phoneNumber: string;
    socials: {
        facebook: string;
        instagram: string;
        linkedin: string;
    };
    warranty: {
        mileage: string;
        durationMonths: number;
    };
    locale: string;
};

const COUNTRY_TO_META: Record<Country, CountryMeta> = {
    LT: {
        currencySymbol: "€",
        currencyCode: "EUR",
        currencyIconClass: "icon-eur",
        langCode: LangCode.LT,
        country: "Lietuva",
        countryWhere: "Lietuvoje",
        clientEmail: "labas@nevaziuoja.lt",
        domain: "nevaziuoja.lt",
        baseUrl: "https://nevaziuoja.lt",
        defaultCity: "Vilnius",
        socials: {
            facebook: "https://facebook.com/nevaziuoja.lt",
            instagram: "https://instagram.com/nevaziuoja.lt",
            linkedin: "https://linkedin.com/company/nevaziuoja",
        },
        phoneNumber: "+370 677 20241",
        warranty: {
            mileage: "10,000 km",
            durationMonths: 6,
        },
        locale: "lt-LT",
    },
    CA: {
        country: "Canada",
        countryWhere: "Canada",
        currencySymbol: "$",
        currencyCode: "CAD",
        currencyIconClass: "icon-usd",
        langCode: LangCode.EN,
        clientEmail: "hey@repairpunk.com",
        domain: "repairpunk.com",
        baseUrl: "https://repairpunk.com",
        defaultCity: "Toronto",
        socials: {
            facebook: "https://facebook.com/repairpunk.com",
            instagram: "https://instagram.com/repairpunk.com",
            linkedin: "https://linkedin.com/company/repairpunk.com",
        },
        phoneNumber: "+1 289-204-5568",
        warranty: {
            mileage: "5,000 km",
            durationMonths: 3,
        },
        locale: "en-CA",
    },
};

export default function countryToMeta(
    country: string
): (typeof COUNTRY_TO_META)[keyof typeof COUNTRY_TO_META] {
    const meta = COUNTRY_TO_META[country as keyof typeof COUNTRY_TO_META];

    if (!meta)
        throw new Error(`Country ${country} not found in COUNTRY_TO_META`);

    return meta;
}
