import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import { InputP } from "../Input/Input";
import * as S from "./CarAutocomplete.styled";
import { Fzf, byStartAsc } from "fzf";
import { CarAutocompleteStage, CarDataArr } from "./CarAutocomplete.types";
import {
    CURRENT_YEAR,
    DONT_KNOW_MODEL,
    DONT_KNOW_YEAR,
    LAST_CAR_YEAR,
    OTHER_BRAND,
    OTHER_MODEL_OR_YEAR,
    STAGES_ORDER,
    STAGE_TO_TEXT,
} from "./CarAutocomplete.consts";
import { runInAction } from "mobx";
import orderBy from "lodash/orderBy";
import showToast from "@FEShared/utils/showToast";
import { carDataArrToTransObj } from "./CarAutocomplete.utils";
import brandsModelsData from "./brandsModelsData.json";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import { transStr, transStringFunctionalFE } from "@FEShared/i18n";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";

const brandsWithModelsNCounts = brandsModelsData as unknown as Record<
    string,
    {
        c: number;
        m: [string, number][];
    }
>;
const carBrands = orderBy(
    Object.keys(brandsWithModelsNCounts),
    (brand) => +brandsModelsData[brand].c,
    "desc"
);

interface P {
    placeholder?: string;
    className?: string;
    onChange: (val: string[]) => void;
    value: CarDataArr;
    forceInputErr?: boolean;
    onClose?: () => void;
    errorsEnabled?: boolean;
    allowedCarBrands?: string[];
    fnsRef?: React.RefObject<{ open: () => void }>;
}
const CarAutocomplete = observer((p: P) => {
    const isMobile = useIsMobile();
    const LS = useLocalObservable(() => ({
        currentSearchVal: "",
        onSearchValChange(val: string) {
            this.currentSearchVal = val;
        },
        wasOpenedOnce: false,
        isOpen: false,
        stage: STAGES_ORDER[Math.min(p.value.length, STAGES_ORDER.length - 1)],
        close() {
            p.onClose && p.onClose();
            LS.isOpen = false;
            LS.modal.toggle(false);
        },
        open() {
            LS.wasOpenedOnce = true;
            if (isMobile) {
                this.modal.toggle(true);
            } else {
                LS.isOpen = true;
            }
        },
        modal: {
            isOpen: false,
            toggle(isOpen: boolean) {
                LS.wasOpenedOnce = true;
                if (isOpen) {
                    LS.isOpen = true;
                } else {
                    /**
                     * This is needed to prevent scrolling to the bottom of the page on iOS devices. Because if input
                     * is focused on mobile, then keyboard is active and that forces scrolling to the bottom of the page.
                     */
                    (
                        window.document
                            ?.activeElement as HTMLInputElement | null
                    )?.blur();

                    p.onClose && p.onClose();
                }
                LS.modal.isOpen = isOpen;
            },
        },
    }));

    React.useImperativeHandle(
        p.fnsRef,
        () => {
            return {
                open: LS.open,
            };
        },
        [LS]
    );

    const isError = React.useMemo(() => {
        return !!(
            p.errorsEnabled &&
            (LS.wasOpenedOnce || p.forceInputErr) &&
            !LS.isOpen &&
            p.value.length < STAGES_ORDER.length
        );
    }, [
        p.value,
        LS.wasOpenedOnce,
        LS.isOpen,
        p.forceInputErr,
        p.errorsEnabled,
    ]);

    const inputProps = {
        leftIconClass: "icon-car",
        placeholder:
            p.value.length > 0
                ? ""
                : p.placeholder || transStr("Automobilis", { id: "mWPuLWf0" }),
        error: isError,
        helperText: p.errorsEnabled
            ? transStr("Pasirinkite automobilį", { id: "N3Nsqi8t" })
            : undefined,
        forceError: isError,
    } as InputP;

    const transCarDataObj = React.useMemo(
        () => carDataArrToTransObj(p.value),
        [p.value]
    );

    const getStageData = React.useCallback((): string[] => {
        let initialData: string[] = [];
        if (LS.stage == CarAutocompleteStage.BRAND) {
            initialData = p.allowedCarBrands
                ? carBrands.filter((brand) =>
                      p.allowedCarBrands?.includes(brand)
                  )
                : carBrands;
        } else if (LS.stage == CarAutocompleteStage.MODEL) {
            const brandModelsPairs =
                brandsWithModelsNCounts[transCarDataObj.vehicleBrand]?.m;
            if (!brandModelsPairs) return [];
            const sortedBrandModels = orderBy(
                brandModelsPairs,
                ([_modelName, modelCount]) => +modelCount,
                "desc"
            ).map(([modelName, _modelCount]) => modelName);
            initialData = sortedBrandModels;
        } else if (LS.stage === CarAutocompleteStage.YEAR) {
            for (let i = CURRENT_YEAR; i > LAST_CAR_YEAR; i--) {
                initialData.push(i.toString());
            }
        }

        return initialData;
    }, [LS.stage, transCarDataObj.vehicleBrand, p.allowedCarBrands]);

    const filteredOptions = React.useMemo(() => {
        const initialData = getStageData();
        const fzf = new Fzf(initialData, {
            casing: "case-insensitive",
            tiebreakers: [byStartAsc],
        });
        const filteredOpts = fzf.find(LS.currentSearchVal);
        const slicedOpts = LS.currentSearchVal
            ? filteredOpts.slice(0, 10)
            : filteredOpts;

        const mappedOpts = slicedOpts.map((o) => o.item);
        let sortedOpts: string[] = [];

        if (LS.stage === CarAutocompleteStage.BRAND) {
            sortedOpts = mappedOpts;
            if (p.allowedCarBrands?.includes(OTHER_BRAND)) {
                sortedOpts = sortedOpts.filter((o) => o !== OTHER_BRAND);
                sortedOpts.push(OTHER_BRAND);
            }
        }
        if (LS.stage === CarAutocompleteStage.MODEL) {
            sortedOpts = mappedOpts;
            sortedOpts.unshift(DONT_KNOW_MODEL);
            sortedOpts.push(OTHER_MODEL_OR_YEAR);
        }
        if (LS.stage === CarAutocompleteStage.YEAR) {
            sortedOpts = mappedOpts;
            sortedOpts.unshift(DONT_KNOW_YEAR);
            sortedOpts.push(OTHER_MODEL_OR_YEAR);
        }
        return sortedOpts;
    }, [LS.stage, LS.currentSearchVal, getStageData, p.allowedCarBrands]);

    const renderTags = React.useCallback((values: string[]) => {
        return values.map((option, index) => {
            return (
                <React.Fragment key={option as string}>
                    <>
                        <S.CarOpt>{transCommonTranslatable(option)}</S.CarOpt>
                        {index < STAGES_ORDER.length - 1 && (
                            <S.StateSeperator>•</S.StateSeperator>
                        )}
                    </>
                </React.Fragment>
            );
        });
    }, []);

    const changeValue = React.useCallback(
        (val: string[]) => {
            if (val.length > STAGES_ORDER.length) {
                val.splice(val.length - 2, 1); // remove n-1 element, since the new data is the last option.
            }
            runInAction(() => {
                p.onChange(val);
                if (val.length === STAGES_ORDER.length) {
                    LS.isOpen = false;
                    return;
                }
                const nextStage = STAGES_ORDER[val.length];
                LS.stage = nextStage;
            });
        },
        [] //eslint-disable-line react-hooks/exhaustive-deps
    );

    const canFinalizeChoice = React.useCallback(() => {
        if (!p.errorsEnabled) return true;
        if (p.value.length === STAGES_ORDER.length) return true;
        showToast.warn(
            transStr(
                "Pasirinkite visus savo automobilio duomenis: modelį, markę ir metus.",
                { id: "l4LdiJgx" }
            )
        );
        return false;
    }, [p.value, p.errorsEnabled]);

    const onInputChange = React.useCallback(
        (_e, v: string) => {
            if (v.length === 1 && v === " ") return;

            const matchingValues = getStageData().filter((stageVal) =>
                stageVal.toLowerCase().includes(v.toLowerCase())
            );
            const exactMatchValue = getStageData().find(
                (stageVal) => stageVal.toLowerCase() === v.toLowerCase()
            );

            if (
                v.length > 0 &&
                exactMatchValue &&
                matchingValues.length === 1
            ) {
                changeValue([...p.value, exactMatchValue]);
            } else {
                LS.onSearchValChange(v);
            }
        },
        [changeValue, LS, p.value, getStageData]
    );

    const { close } = LS;
    const onChange = React.useCallback(
        (val: string[]) => {
            changeValue(val);
            if (val.length === STAGES_ORDER.length) {
                close();
            }
        },
        [changeValue, close]
    );

    const mobileInputProps: InputP = React.useMemo(() => {
        return {
            InputProps: {
                startAdornment: renderTags(p.value),
            },
        };
    }, [renderTags, p.value]);

    React.useEffect(() => {
        if (
            p.allowedCarBrands &&
            !p.allowedCarBrands.includes(transCarDataObj.vehicleBrand)
        ) {
            changeValue([]);
        }
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <S.CarAutocomplete
            className={p.className}
            onOpen={LS.open}
            open={LS.isOpen}
            canFinalizeChoice={canFinalizeChoice}
            disableCloseOnSelect
            value={p.value}
            multiple
            onChange={onChange}
            options={filteredOptions}
            filterOptions={(o) => o /*disable filtering from MUI side.*/}
            inputValue={LS.currentSearchVal}
            onInputChange={onInputChange}
            renderTags={renderTags}
            inputProps={inputProps}
            onClose={LS.close}
            mobileInputProps={mobileInputProps}
            modal={LS.modal}
            PaperComponent={(props) => {
                const { children, ...otherProps } = props;
                return (
                    <S.DropdownContainer {...otherProps}>
                        <S.StateWrapper>
                            {STAGES_ORDER.filter((stage) => {
                                const thisStageIndex =
                                    STAGES_ORDER.indexOf(stage);
                                const currentActiveStageIndex =
                                    STAGES_ORDER.indexOf(LS.stage);
                                return (
                                    thisStageIndex <= currentActiveStageIndex
                                );
                            }).map((stage) => {
                                return (
                                    <React.Fragment key={stage}>
                                        {stage !==
                                            CarAutocompleteStage.BRAND && (
                                            <S.StateSeperator>
                                                •
                                            </S.StateSeperator>
                                        )}
                                        <S.CurrentStateTitle
                                            $active={
                                                stage !==
                                                    CarAutocompleteStage.BRAND &&
                                                LS.stage === stage
                                            }
                                        >
                                            {transStringFunctionalFE(
                                                STAGE_TO_TEXT[stage]
                                            )}
                                        </S.CurrentStateTitle>
                                    </React.Fragment>
                                );
                            })}
                        </S.StateWrapper>
                        {children}
                    </S.DropdownContainer>
                );
            }}
            renderOption={(props, option, _state) => (
                <S.DropdownOption {...props}>
                    {STAGES_ORDER.filter((stage) => {
                        const thisStageIndex = STAGES_ORDER.indexOf(stage);
                        const currentActiveStageIndex = STAGES_ORDER.indexOf(
                            LS.stage
                        );
                        return thisStageIndex < currentActiveStageIndex;
                    }).map((stage) => {
                        return (
                            <React.Fragment key={transCarDataObj[stage]}>
                                <S.CurrentState>
                                    {transCarDataObj[stage]}
                                </S.CurrentState>
                                <S.StateSeperator>•</S.StateSeperator>
                            </React.Fragment>
                        );
                    })}
                    <S.CurrentState $active>
                        {transCommonTranslatable(option)}
                    </S.CurrentState>
                </S.DropdownOption>
            )}
        />
    );
});

export default CarAutocomplete;
