const sharedConfig = {
    i18n: {
        timeoutInMs: 3000,
        backend: {
            serverUrl: "https://i18n.virtuozo.dev",
            org: "l33t",
            project: "app",
            // Create a new version online after each release.
            // This ensures that i18n changes don't affect current deployments.
            // Enter the new name here.
            version: "main-1",
        }, //  as HostedI18nBackendConfig
    },
};
export default sharedConfig;

export type SharedConfig = typeof sharedConfig;
