import { fromPromise, IPromiseBasedObservable } from "mobx-utils";
import {
    ObservableMap,
    observable,
    action,
    runInAction,
    reaction,
    computed,
    makeObservable,
} from "mobx";
import { getOrInitialize, failAfterTimeout } from "../../Shared/i18n/utils";
import { EmbeddedTranslationEditorService } from "./EmbeddedTranslationEditorService";
import {
    LocaleTranslations,
    Translatable as I18NEditorTranslatable,
} from "@hediet/i18n-frontend/embedded-editor";
import {
    I18nBackend,
    ILocale,
    ITranslation,
    Translations,
} from "@Shared/i18n/i18n.types";

export class I18nService {
    public readonly initialized: IPromiseBasedObservable<void>;
    private readonly localesByLanguageCode = new ObservableMap<
        string,
        Locale
    >();
    private readonly embeddedTranslationEditorService =
        new EmbeddedTranslationEditorService();

    @observable private _currentLocale: Locale | undefined;

    @computed
    public get currentLocale(): Locale {
        if (!this._currentLocale) {
            throw new Error(
                "Locale has not been defined yet. You need to wait for the 'initialized' promise!"
            );
        }
        return this._currentLocale;
    }

    constructor(
        private readonly backend: I18nBackend,
        private readonly initTimeoutMs: number
    ) {
        makeObservable(this);
        reaction(
            () => this.embeddedTranslationEditorService.localeTranslations,
            (overridingLocaleTranslations) => {
                if (overridingLocaleTranslations) {
                    this.overrideTranslations(overridingLocaleTranslations);
                } else {
                    this.fetchFromBackendAndSetCurrentLocale().catch(
                        console.error
                    );
                }
            },
            {
                name: "Override translations from translations from embedded i18n editor",
            }
        );

        this.initialized = fromPromise(this.initialize().catch(console.error));
    }

    private async initialize(): Promise<void> {
        // By default, this promise resolves immediately.
        await this.embeddedTranslationEditorService.initialized;

        if (!this._currentLocale) {
            // This happens if the reaction has not been triggered, which is usually the case.
            try {
                await failAfterTimeout(
                    this.initTimeoutMs,
                    this.fetchFromBackendAndSetCurrentLocale()
                );
            } catch (e) {
                console.error(e);
                this._currentLocale = this.getLocale(navigator.language);
            }
        }
    }

    @action
    private overrideTranslations(
        overridingLocaleTranslations: LocaleTranslations
    ): void {
        const locale = this.getLocale(
            overridingLocaleTranslations.languageCode,
            "editor"
        );
        locale.handleUnknownTranslatable = (translatable) =>
            overridingLocaleTranslations.translateUnknown(translatable);
        for (const l of overridingLocaleTranslations.translations) {
            locale.setTranslatedFormat({
                codeId: l.codeId,
                translatedFormat: l.translatedFormat,
            });
        }
        this._currentLocale = locale;
    }

    private async fetchFromBackendAndSetCurrentLocale(): Promise<void> {
        const acceptedLangs = [...navigator.languages];
        const preferredLang = this.getPreferredLanguage();
        if (preferredLang) {
            acceptedLangs.unshift(preferredLang);
        }
        const translations = await this.backend.fetchTranslations(
            acceptedLangs
        );

        runInAction(() => {
            const locale = this.getLocale(translations.languageCode);
            locale.addPackages(translations.packagesById);
            this._currentLocale = locale;
            // console.log("currentlocale set", locale);
        });
    }

    public getSupportedLanguages(): Promise<{ languageCode: string }[]> {
        return this.backend.getSupportedLanguages();
    }

    private readonly localStorageKey = "i18nPreferredLang";

    private setPreferredLanguage(lang: string | undefined): void {
        if (lang) {
            localStorage.setItem(this.localStorageKey, lang);
        } else {
            localStorage.removeItem(this.localStorageKey);
        }
    }

    private getPreferredLanguage(): string | undefined {
        // return localStorage.getItem(this.localStorageKey) || undefined;
        return window._COUNTRY_META.langCode;
    }

    public setLanguage(options: { languageCode: string }): Promise<void> {
        this.setPreferredLanguage(options.languageCode);
        return this.fetchFromBackendAndSetCurrentLocale();
    }

    /** @param key Used to create overriding locales. */
    private getLocale(languageCode: string, key = "default"): Locale {
        return getOrInitialize(
            this.localesByLanguageCode,
            languageCode + "#" + key,
            () => new Locale(languageCode)
        );
    }
}

export class Locale implements ILocale {
    public handleUnknownTranslatable:
        | ((
              t: I18NEditorTranslatable
          ) => { translatedFormat: string } | undefined)
        | undefined;

    constructor(public readonly languageCode: string) {}

    private readonly translationByCodeId = new ObservableMap<
        string,
        Translation
    >();

    public getTranslation(codeId: string): Translation | undefined {
        return this.translationByCodeId.get(codeId);
    }

    @action
    public setTranslatedFormat(arg: {
        codeId: string;
        translatedFormat: string;
    }): void {
        const translation = getOrInitialize(
            this.translationByCodeId,
            arg.codeId,
            () => new Translation(arg.translatedFormat)
        );
        translation.translatedFormat = arg.translatedFormat;
    }

    public getTranslatedFormat(codeId: string, defaultFormat: string): string {
        if (!codeId) {
            throw new Error("CodeId is not defined!");
        }

        const t = this.getTranslation(codeId);
        if (!t) {
            if (this.handleUnknownTranslatable) {
                const result = this.handleUnknownTranslatable({
                    codeId,
                    defaultFormat,
                });
                if (result) {
                    return result.translatedFormat;
                }
            }
            //console.error(`Format ${codeId} is missing. Default translation is "${defaultFormat}".`);
            return defaultFormat;
        }
        return t.translatedFormat;
    }

    public addPackages(packagesById: Translations["packagesById"]): void {
        for (const pkg of Object.values(packagesById)) {
            for (const [codeId, translation] of Object.entries(
                pkg.translationsByCodeId
            )) {
                this.setTranslatedFormat({
                    codeId,
                    translatedFormat: translation.translatedFormat,
                });
            }
        }
    }
}

export class Translation implements ITranslation {
    @observable public translatedFormat: string;

    constructor(translatedFormat: string) {
        this.translatedFormat = translatedFormat;
    }
}
