import useStore from "@FEClient/logic/store";
import React from "react";
import * as S from "./StickyBottomBar.styled";
import { observer } from "mobx-react-lite";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import { WorkshopDTO } from "../Workshop.types";
import Text from "@FEShared/components/UI/Text/Text";
import Box from "@FEShared/components/UI/Box/Box";
import { TransMsg, transStr } from "@FEShared/i18n";

const StickyBottomBar: React.FC<{ selectedWorkshop: WorkshopDTO }> = observer(
    (p) => {
        const GS = useStore();
        const isMobile = useIsMobile();

        const priceNDuration = GS.getSelectedServicesPriceNDurationForWorkshop(
            p.selectedWorkshop
        );

        const hasSelectedServices = !!priceNDuration;

        if (!isMobile) return null;

        return (
            <S.StickyBottomBarContainer>
                <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                        const el = document.getElementById("time-section");
                        el?.scrollIntoView({ behavior: "smooth" });
                    }}
                >
                    <Text
                        fontSize={12}
                        color="white.main"
                        sx={{
                            whiteSpace: "nowrap",
                        }}
                    >
                        <TransMsg default={"Atvykimo laikas:"} id="0n9BpOav" />
                    </Text>
                    <Text
                        fontSize={12}
                        color="white.main"
                        semiBold
                        sx={{
                            textDecoration: "underline",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {GS.reservationConfirmationPageState.date.obj.YYYY_MM_DD
                            ? GS.reservationConfirmationPageState.date
                                  .formattedTimeString
                            : transStr("Pasirinkite laiką", { id: "4LtZzvKs" })}
                    </Text>
                </Box>
                <S.RightSideWrapper>
                    <S.TotalPrice>
                        {priceNDuration
                            ? priceNDuration.servicesPrice.text
                            : undefined}
                    </S.TotalPrice>
                    <S.ConfirmBtn
                        leftIconClass="icon-checkmark-circle"
                        disabled={!hasSelectedServices}
                        fullWidth={isMobile}
                        onClick={GS.workshopPageState.onConfirm}
                    >
                        {hasSelectedServices
                            ? transStr("Tęsti rezervaciją", { id: "Xlcb1PGB" })
                            : transStr("Pasirinkite paslaugas", {
                                  id: "oJUC84lP",
                              })}
                    </S.ConfirmBtn>
                </S.RightSideWrapper>
            </S.StickyBottomBarContainer>
        );
    }
);

export default StickyBottomBar;
