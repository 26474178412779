import React from "react";
import WorkshopServiceRow from "../WorkshopServiceRow/WorkshopServiceRow";
import { ServiceOrSubCateg } from "../ServicesSection.types";
import { MergedServiceOrPackageForWorkshop } from "@Shared/util/mergeServicesWithPackagesForWorkshop";
import { WorkshopDTO } from "../../Workshop.types";
import { isSubCateg } from "../ServicesSection.util";
import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import { observer, useLocalObservable } from "mobx-react-lite";
import Icon from "@FEShared/components/UI/Icon/Icon";
import Collapse from "@mui/material/Collapse";
import { runInAction } from "mobx";
import { TranslatableView } from "@FEShared/i18n";

export const SUBCATEG_WRAPPER_CLASS = "SUBCATEG_WRAPPER";

const ServiceOrSubcategRow: React.FC<{
    serviceOrSubcateg: ServiceOrSubCateg;
    onBtnClick: (serviceOrSubCateg: MergedServiceOrPackageForWorkshop) => void;
    hidden?: boolean;
    selectedWorkshop: WorkshopDTO;
    isSelected: (s: MergedServiceOrPackageForWorkshop) => boolean;
    isLast: boolean;
    isFirst: boolean;
}> = observer((p) => {
    const LS = useLocalObservable(() => ({
        isOpen: false,
    }));

    if (isSubCateg(p.serviceOrSubcateg)) {
        const atleastOneSelected = p.serviceOrSubcateg.services.some(
            (service) => p.isSelected(service)
        );

        return (
            <Box
                sx={{
                    display: p.hidden ? "none" : "block",
                }}
                className={SUBCATEG_WRAPPER_CLASS}
                vertical
                pointer
                bgcolor="#F0F2F8"
                mb={0.5}
                mt={0.5}
                borderRadius="10px"
                // overflow="hidden" // to properly hide overflow at the bottom
            >
                <Box
                    displayFlex
                    padding={1.5}
                    alignVertical="center"
                    justifyContent="space-between"
                    onClick={() => {
                        runInAction(() => {
                            LS.isOpen = !LS.isOpen;
                        });
                    }}
                >
                    <Box displayFlex>
                        <Text semiBold>
                            <TranslatableView
                                translatable={p.serviceOrSubcateg.name}
                            />
                        </Text>
                        {atleastOneSelected && (
                            <Icon
                                className="icon-checkmark"
                                ml={0.5}
                                color="#3c8b56"
                            />
                        )}
                    </Box>
                    <Icon
                        fontSize={10}
                        className={
                            LS.isOpen ? "icon-arrow-up" : "icon-arrow-down"
                        }
                    />
                </Box>
                <Collapse in={LS.isOpen}>
                    <Box vertical mt={0}>
                        {p.serviceOrSubcateg.services.map((service) => {
                            return (
                                <WorkshopServiceRow
                                    key={service.serviceName.id}
                                    service={service}
                                    onBtnClick={p.onBtnClick}
                                    hidden={p.hidden}
                                    selectedWorkshop={p.selectedWorkshop}
                                    isSelected={p.isSelected}
                                />
                            );
                        })}
                    </Box>
                </Collapse>
            </Box>
        );
    } else {
        return (
            <WorkshopServiceRow
                service={p.serviceOrSubcateg}
                onBtnClick={p.onBtnClick}
                hidden={p.hidden}
                selectedWorkshop={p.selectedWorkshop}
                isSelected={p.isSelected}
                isLast={p.isLast}
            />
        );
    }
});

export default ServiceOrSubcategRow;
