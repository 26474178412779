import {
    PriceObj,
    ServiceDataForWorkshopPriceNDuration,
} from "@Shared/types/types";
import getServicePriceNDuration from "./getServicePriceNDuration";
import { ServicePriceType } from "@FEShared/graphql/generated/graphql";
import priceObjToPriceStringObj from "./priceObjToPriceStringObj";
import { transStringFunctionalFE } from "@FEShared/i18n";
import transCommonTranslatable from "./transCommonTranslatable";

const PRICE_TYPES_ORDER = [
    ServicePriceType.Fixed,
    ServicePriceType.FromTo,
    ServicePriceType.From,
    ServicePriceType.Hourly,
];

export type ServicesPriceNDuration = {
    servicesPrice: {
        value: number;
        text: string;
    };
    duration: {
        mins: number;
        text: string;
    };
    partsPrice: {
        value: number;
        text: string;
    };
    totalPrice: {
        value: number;
        text: string;
    };
    priceObj: PriceObj;
};

function minsToString(mins: number, isFromDuration?: boolean): string {
    const hours = Math.max(Math.round(mins / 30) / 2, 0.5);
    return `${hours}${isFromDuration ? "+" : ""} ${transCommonTranslatable(
        "HOUR_SHORT"
    )}`;
}

export default function getServicesPriceNDuration(
    services: ServiceDataForWorkshopPriceNDuration[],
    hourCost: number,
    /* showAverage for from-to prices */
    showAverage?: boolean,
    noUnits?: boolean
): ServicesPriceNDuration {
    const priceObj: PriceObj = {
        price: 0,
        fixedPrice: 0,
        fromPrice: 0,
        toPrice: 0,
        type: ServicePriceType.Fixed,
        hourlyPrice: 0,
        defaultUnit: undefined,
        partsPrice: 0,
    };
    let durationMins = 0;

    priceObj.hourlyPrice = hourCost;
    services.forEach((service) => {
        const priceNDuration = getServicePriceNDuration(service, hourCost);

        // all price picking logic is in getServicePriceObj. Allow this to just sum everything.
        priceObj.price += priceNDuration.priceObj.price;
        priceObj.fixedPrice += priceNDuration.priceObj.fixedPrice;
        priceObj.fromPrice += priceNDuration.priceObj.fromPrice;
        priceObj.toPrice += priceNDuration.priceObj.toPrice;
        priceObj.partsPrice += priceNDuration.priceObj.partsPrice || 0;

        if (
            PRICE_TYPES_ORDER.indexOf(priceNDuration.priceObj.type) >
            PRICE_TYPES_ORDER.indexOf(priceObj.type)
        ) {
            // price type can only worsen. It can't get better;
            priceObj.type = priceNDuration.priceObj.type;
        }

        durationMins += priceNDuration.durationMins;
    });
    priceObj.defaultUnit =
        services.length === 1 && services[0].type.defaultUnit
            ? transStringFunctionalFE(services[0].type.defaultUnit)
            : undefined;

    const priceStrings = priceObjToPriceStringObj({
        priceObj,
        showAverage,
        noUnits,
    });
    return {
        servicesPrice: {
            value: priceObj.price,
            text: priceStrings.servicesPriceString,
        },
        partsPrice: {
            value: priceObj.partsPrice,
            text:
                priceObj.partsPrice > 0
                    ? `±${window._COUNTRY_META.currencySymbol}${priceObj.partsPrice}`
                    : ``,
        },
        totalPrice: {
            value: priceObj.partsPrice + priceObj.price,
            text: priceStrings.totalPriceString,
        },
        duration: {
            mins: durationMins,
            text: minsToString(
                durationMins,
                priceObj.type !== ServicePriceType.Fixed
            ),
        },
        priceObj,
    };
}
