import React from "react";
import * as WS from "../Workshop.styled";
import { WorkshopDTO } from "../Workshop.types";
import * as S from "./TopInfoSection.styled";
import MEDAL_IMG from "./assets/medal.svg"; // not used as icomoon icon, because icomoon somehow splits this svg into 3 different svgs. Might be just bad file format, but this works for now.
import useStore from "@FEClient/logic/store";
import isWorkshopSpecialized from "@FEClient/logic/utils/isWorkshopSpecialized";
import { observer } from "mobx-react-lite";
import BackButton from "@FEShared/components/UI/BackButton/BackButton";
import { UrlPathnameParser } from "../../Search/Search.logic";
import Box from "@FEShared/components/UI/Box/Box";
import Icon from "@FEShared/components/UI/Icon/Icon";
import Text from "@FEShared/components/UI/Text/Text";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import InternalLink from "@FEShared/components/InternalLink/InternalLink";
import WarrantyBox from "@FEClient/views/commonComps/WarrantyBox/WarrantyBox";
import { TransMsg, transStr, transStringFunctionalFE } from "@FEShared/i18n";

const TopInfoSection: React.FC<{ selectedWorkshop: WorkshopDTO }> = observer(
    (p) => {
        const GS = useStore();
        const isMobile = useIsMobile();

        const yearsExp =
            p.selectedWorkshop.workingSinceYear &&
            new Date().getFullYear() - p.selectedWorkshop.workingSinceYear;

        const specialized = isWorkshopSpecialized(p.selectedWorkshop, {
            vehicleBrand: GS.searchState.carData.vehicleBrand,
            servicesIDs: GS.searchState.selectedServicesDefinitionsIDs,
        });
        const RESPONSIVE_BREAKPOINTS = {
            desktop: {
                breakpoint: { max: 9999, min: 900 },
                items: p.selectedWorkshop.photosUrls.length >= 2 ? 2 : 1,
                partialVisibilityGutter: 16,
            },
            mobile: {
                breakpoint: { max: 900, min: 0 },
                items: 1,
            },
        };

        const specializedText = React.useMemo(() => {
            let specializedText = "";

            if (specialized.isSpecializedForUser) {
                specializedText = specialized.carBrand
                    ? transStr("Specializuojasi {carBrand} remonte", {
                          data: { carBrand: specialized.carBrand },
                          id: "XnO531VK",
                      })
                    : transStr("Specializacija: {selectedServicesTypes}", {
                          data: {
                              selectedServicesTypes:
                                  GS.searchState.selectedServicesTypes
                                      .filter((s) =>
                                          specialized.servicesIDs.includes(s.ID)
                                      )
                                      .map((s) =>
                                          transStringFunctionalFE(s.serviceName)
                                      )
                                      .join(", "),
                          },
                          id: "aCZnem4w",
                      });
            } else if (
                p.selectedWorkshop.specialized.vehicleBrands.length > 0
            ) {
                specializedText = transStr(
                    "Specializuojasi {vehicleBrands} remonte",
                    {
                        data: {
                            vehicleBrands:
                                p.selectedWorkshop.specialized.vehicleBrands[0],
                        },
                        id: "UKjZWI0Z",
                    }
                ); // dont expect to have more than 1 specialized workshop now.
            } else if (p.selectedWorkshop.specialized.servicesIDs.length > 0) {
                specializedText = transStr("Specializuotas autoservisas", {
                    id: "DVJ0NaFF",
                });
            }
            return specializedText;
        }, [
            GS.searchState.selectedServicesTypes,
            p.selectedWorkshop.specialized.servicesIDs.length,
            p.selectedWorkshop.specialized.vehicleBrands,
            specialized.carBrand,
            specialized.isSpecializedForUser,
            specialized.servicesIDs,
        ]);

        return (
            <Box mb={4}>
                {!isMobile && !GS.workshopPageState.fromBookNow && (
                    <InternalLink
                        underline="none"
                        sx={{ display: "inline-block" }}
                        to={UrlPathnameParser.paramsToPathname({
                            city: p.selectedWorkshop.city,
                        })}
                        mb={1}
                    >
                        <BackButton>
                            <TransMsg default={"Autoservisai"} id="7mhaWXWj" />
                        </BackButton>
                    </InternalLink>
                )}
                <Box
                    displayFlex
                    width={1}
                    sx={(t) => ({
                        [t.breakpoints.down("md")]: {
                            flexDirection: "column",
                        },
                    })}
                >
                    <Box
                        width={0.3}
                        bgcolor={isMobile ? "background.default" : undefined}
                        sx={(theme) => ({
                            display: "flex",
                            flexDirection: "column",
                            [theme.breakpoints.down("md")]: {
                                order: 2,
                                ml: -2,
                                mr: -2,
                                padding: 2,
                                width: "unset",
                            },
                        })}
                    >
                        {p.selectedWorkshop.isTrustedPartner && (
                            <WS.VerifiedTag>
                                <WS.VerifiedIcon src={MEDAL_IMG} />
                                <TransMsg
                                    default={"Patikimas partneris"}
                                    id="8X9vW6Mm"
                                />
                            </WS.VerifiedTag>
                        )}
                        <WS.Title component="h1">
                            {p.selectedWorkshop.name}
                        </WS.Title>
                        <WS.Rating
                            onClick={() => {
                                document
                                    .getElementById("reviews")
                                    ?.scrollIntoView({
                                        behavior: "smooth",
                                        block: "center",
                                    } as any);
                            }}
                        >
                            <Icon
                                className="icon-star-full"
                                color="#ffb600"
                                mr={0.5}
                            />
                            <WS.RatingScore $fontSize="16px">
                                {(
                                    p.selectedWorkshop.reviewRatingGmap || 5
                                ).toFixed(1)}{" "}
                                <Text
                                    span
                                    variant="inherit"
                                    ml={0.5}
                                    sx={{ textDecoration: "underline" }}
                                >
                                    {p.selectedWorkshop.orderReviews?.length ||
                                        1}{" "}
                                    <TransMsg
                                        default={"atsiliepimai"}
                                        id="bW3xvELB"
                                    />
                                </Text>
                            </WS.RatingScore>
                        </WS.Rating>
                        <S.FeatureText
                            component="h3"
                            onClick={() => {
                                document
                                    .getElementById("location")
                                    ?.scrollIntoView({
                                        behavior: "smooth",
                                        block: "center",
                                    } as any);
                            }}
                        >
                            <S.FeatureIcon className="icon-pin" />
                            {[
                                p.selectedWorkshop.address,
                                p.selectedWorkshop.microdistrict,
                                p.selectedWorkshop.city,
                            ]
                                .filter(Boolean)
                                .join(", ")}
                        </S.FeatureText>
                        {yearsExp && yearsExp > 5 ? (
                            <S.FeatureText>
                                <S.FeatureIcon className="icon-wrench-full" />
                                <TransMsg
                                    default={"{yearsExp} metų patirtis"}
                                    data={{ yearsExp }}
                                    id="GgP1NwON"
                                />
                            </S.FeatureText>
                        ) : undefined}
                        <S.FeatureText>
                            <S.FeatureIcon className="icon-price-question" />
                            {window._COUNTRY_META.currencySymbol}
                            <TransMsg
                                default={"{hourCost}/val. įkainis"}
                                data={{ hourCost: p.selectedWorkshop.hourCost }}
                                id="XGVOJx33"
                            />
                        </S.FeatureText>
                        {specializedText && (
                            <S.FeatureText>
                                <S.FeatureIcon className="icon-wrench" />
                                {specializedText}
                            </S.FeatureText>
                        )}
                        {!p.selectedWorkshop.acceptsClientsWithOwnParts && (
                            <S.FeatureText>
                                <S.FeatureIcon className="icon-gear" />
                                <TransMsg
                                    default={
                                        "Nepriima klientų su savo dalim/medžiagom"
                                    }
                                    id="CY3IBrZ6"
                                />
                            </S.FeatureText>
                        )}
                        <WarrantyBox
                            forceMobileFlow
                            sx={{
                                mt: 2,
                            }}
                        />
                    </Box>
                    <S.CarouselWrap>
                        {!GS.workshopPageState.fromBookNow && isMobile ? (
                            <InternalLink
                                zIndex={2}
                                position="absolute"
                                top={"8px"}
                                left={"8px"}
                                sx={{ display: "inline-block" }}
                                to={UrlPathnameParser.paramsToPathname({
                                    city: p.selectedWorkshop.city,
                                })}
                            >
                                <BackButton variant="contained" color="white">
                                    <TransMsg
                                        default={"Autoservisai"}
                                        id="Fq84946O"
                                    />
                                </BackButton>
                            </InternalLink>
                        ) : undefined}
                        <S.SCarousel
                            responsive={RESPONSIVE_BREAKPOINTS}
                            draggable={false}
                            showDots={p.selectedWorkshop.photosUrls.length > 2}
                        >
                            {(p.selectedWorkshop.photosUrls.length > 1
                                ? p.selectedWorkshop.photosUrls
                                : [p.selectedWorkshop.logoURL]
                            ) // just for now to show something if some workshops wouldnt have any photo. Remove this later.
                                .map((url) => {
                                    return (
                                        <S.WorkshopImg
                                            loading="lazy"
                                            key={url}
                                            src={url}
                                            alt={`Autoservisas ${p.selectedWorkshop.name}, ${p.selectedWorkshop.address}`}
                                        />
                                    );
                                })}
                        </S.SCarousel>
                    </S.CarouselWrap>
                </Box>
            </Box>
        );
    }
);

export default TopInfoSection;
