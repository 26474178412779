import { observer } from "mobx-react-lite";
import * as React from "react";
import {
    evaluateTranslatableToString,
    transStringFunctional,
} from "../../Shared/i18n/i18n";
import { I18nService } from "./I18nService";
import HostedI18nBackend from "./I18nBackend";
import { evaluateTranslatableToReact } from "./geml-evaluation";
import { useContext } from "react";
export { countries, countriesMeta } from "./data";
export * from "./languageInfo";
import sharedConfig from "@Shared/shared-config";
import {
    Translatable,
    TranslatableI18nData,
    TranslatableStringified,
} from "@Shared/i18n/i18n.types";
import { ReactI18nData } from "./fe-i18n.types";

const GlobalI18nDataElements = React.createContext<ReactI18nData>({});

export const i18nService = new I18nService(
    new HostedI18nBackend(sharedConfig.i18n.backend),
    sharedConfig.i18n.timeoutInMs
);

export const I18nLoader: React.FCC<{ globalFormatters?: ReactI18nData }> =
    observer((p): JSX.Element => {
        if (i18nService.initialized.state === "pending") {
            return <></>;
        }
        for (const k in p.globalFormatters) {
            if (k[0].toUpperCase() !== k[0]) {
                console.warn(
                    `global i18n keys should start with an upper case leter (got ${k})`
                );
            }
        }
        // todo: allow nesting of GlobalI18nDataElements.Provider
        return (
            <GlobalI18nDataElements.Provider value={p.globalFormatters || {}}>
                {p.children}
            </GlobalI18nDataElements.Provider>
        );
    });

/**
 * Renders a translation. Best way to localize messages. Example:
 *
 * ```tsx
 * <TransMsg
 * 	default={"You have {bold <{count} messages>}"}
 * 	data={{ count: 10, bold: body => <b>{body}</b> }}
 * 	id="vjy1Ilf0"
 * />;
 * ```
 */
export const TransMsg: React.FC<{
    /** The default translation. Uses GeMl syntax (see https://geml.dev). Must be a static string. */
    default: string;
    data?: ReactI18nData;
    /**
     * A static description of this translatable for the translator. Is not used at runtime. Only set a description if
     * `default` needs more context.
     */
    description?: string;
    /**
     * The id of this translation. All translatables with the same id must use the same default. Must be a static
     * string.
     */
    id: string;
}> = observer((props) => {
    const globalData = useContext(GlobalI18nDataElements);
    return evaluateTranslatableToReact(
        {
            id: props.id,
            defaultTranslation: props.default,
            data: { ...globalData, ...props.data },
        },
        i18nService.currentLocale
    );
});

/** Translates a message to a string. */
export function transStr(
    /** The default translation. Uses GeMl syntax. Must be a static string. */
    defaultTranslation: string,
    options: {
        /**
         * A static description of this translatable for the translator. Is not used at runtime. Only set a description
         * if `default` needs more context.
         */
        description?: string;

        data?: TranslatableI18nData;

        /**
         * The id of this translatable. All translatables with the same id must use the same default. Must be a static
         * string.
         */
        id: string;
    }
): string {
    return evaluateTranslatableToString(
        { id: options.id, defaultTranslation, data: options.data },
        i18nService.currentLocale
    );
}

/** Renders a translation of the given translatable. Basically same as `TransMsg` just you can easily pass down `translatable` as single prop */
export const TranslatableView: React.FC<{ translatable: Translatable }> =
    observer(({ translatable }) => {
        if (typeof translatable === "string") {
            console.warn(
                `Translatable must be an object but was '${translatable}'`
            );
            return translatable;
        }
        const globalData = useContext(GlobalI18nDataElements);
        return evaluateTranslatableToReact(
            {
                id: translatable.id,
                defaultTranslation: translatable.defaultTranslation,
                data: { ...globalData, ...translatable.data },
            },
            i18nService.currentLocale
        );
    });

/**
 * Convenience function, to process messages from back-end when you don't know if you are receiving a simple string or
 * Translatable
 */
// export function transViewElseString(msg: string): JSX.Element | string {
//     const parsedMsg = translatableOrStringFromString(msg);
//     if (isTranslatable(parsedMsg)) {
//         return <TranslatableView translatable={parsedMsg} />;
//     } else {
//         return msg;
//     }
// }

/**
 * Convenience function, to process messages from back-end and return a string.
 * Same as `transStringFunctional` just automatically injects current locale
 * */
export function transStringFunctionalFE(
    msg: string | TranslatableStringified | Translatable
): string;
export function transStringFunctionalFE(
    msg: string[] | TranslatableStringified[] | Translatable[]
): string[];
export function transStringFunctionalFE(
    msg:
        | string
        | TranslatableStringified
        | Translatable
        | string[]
        | TranslatableStringified[]
        | Translatable[]
): string | string[] {
    return msg instanceof Array
        ? msg.map((m) => transStringFunctional(m, i18nService.currentLocale))
        : transStringFunctional(msg, i18nService.currentLocale);
}
