import useIsMobile from "@FEShared/hooks/useIsMobile";
import React from "react";
import Modal from "../Modal/Modal";
import * as S from "./ModalDrawer.styled";
import { TransMsg } from "@FEShared/i18n";

/* On desktop works as a modal, on mobile works as a drawer.
 */

const ModalDrawer: React.FCC<{
    isOpen: boolean;
    onClose: () => void;
    className?: string;
    onChooseClick?: () => void;
    showChooseBtn?: boolean;
    goBack?: () => void;
    /** pass null or empty fragment if you don't want header */
    header?: JSX.Element | null;
    maxWidth?: string;
}> = (p) => {
    const isMobile = useIsMobile();

    return isMobile ? (
        // might make sense to create seperate drawer component from this, if we would re-use drawer anywhere
        <S.Drawer
            open={p.isOpen}
            swipeAreaWidth={0}
            disableSwipeToOpen={false}
            onClose={() => p.onClose()}
            onOpen={() => {}}
            anchor="bottom"
            ModalProps={{
                keepMounted: false,
            }}
            className={p.className}
            container={window.document.body}
        >
            {p.header !== undefined ? (
                p.header
            ) : (
                <S.Head>
                    <S.HeadBackButton
                        onClick={() => {
                            if (p.goBack) {
                                p.goBack();
                            } else {
                                p.onClose();
                            }
                        }}
                        circle
                    />
                    {p.onChooseClick && p.showChooseBtn && (
                        <S.ConfirmBtn onClick={p.onChooseClick}>
                            <TransMsg default={"Pasirinkti"} id="oFHlp0ad" />
                        </S.ConfirmBtn>
                    )}
                </S.Head>
            )}

            <S.DrawerContent>{p.children}</S.DrawerContent>
        </S.Drawer>
    ) : (
        <Modal
            goBack={p.goBack}
            isOpen={p.isOpen}
            onClose={p.onClose}
            className={p.className}
            header={p.header}
            maxWidth={p.maxWidth}
        >
            {p.children}
        </Modal>
    );
};

ModalDrawer.defaultProps = {
    showChooseBtn: true,
};

export default ModalDrawer;
