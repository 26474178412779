import {
    ClientPage as ClientPage,
    countryClientPageToMeta,
} from "@Shared/util/clientPagesMeta";
import {
    WorkshopPage,
    workshopPageToMeta,
} from "@Shared/util/workshopPagesMeta";
import Country from "../types/enums/Country";
import getUrlHost from "./getUrlHost";
import QUERY_PARAMS from "@Shared/consts/QUERY_PARAMS";
import { GqlCountry } from "@FEShared/graphql/generated/graphql";

function isClientPage(value: unknown): value is ClientPage {
    return Object.values(ClientPage).includes(value as ClientPage);
}

/* will build full URL and select proper domain by provided Country. e.g `https://nevaziuoja.lt/autoservisai/vilnius` */
export default function buildUrl(p: {
    page: ClientPage | WorkshopPage;
    country: Country | GqlCountry;
    pathParam?: string | number;
    queryParams?: Partial<
        Record<(typeof QUERY_PARAMS)[keyof typeof QUERY_PARAMS], string>
    >;
}): string {
    const clientPageToMeta = countryClientPageToMeta(p.country);

    const queryParams = p.queryParams
        ? Object.entries(p.queryParams)
              .map(([key, value]) => `${key}=${value}`)
              .join("&")
        : undefined;

    const pageMeta = isClientPage(p.page)
        ? clientPageToMeta[p.page]
        : workshopPageToMeta[p.page];

    const url = [
        isClientPage(p.page)
            ? getUrlHost("FE_CLIENT", p.country)
            : getUrlHost("FE_WORKSHOP", p.country),
        pageMeta.shortUrl || pageMeta.url,
        p.pathParam && `/${p.pathParam}`,
        queryParams && `?${queryParams}`,
    ]
        .filter(Boolean)
        .join("");

    return url;
}
