import useStore from "@FEClient/logic/store";
import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import useIsMobile from "../../../../../../FEShared/hooks/useIsMobile";
import ListItem, { LIST_ITEM_CLASSNAME_IDENTIFIER } from "./ListItem/ListItem";
import * as S from "./SearchSidebar.styled";
import lOrderBy from "lodash/orderBy";
import { runInAction } from "mobx";
import { Workshop } from "@FEClient/types/types";
import isGoogleBot from "@FEShared/utils/isGoogleBot";
import { SidebarOrderBy } from "./SearchSidebar.types";
import {
    calcBayesianAverage,
    calcBestBangForPriceRating,
} from "./SearchSidebar.utils";
import Typography from "@mui/material/Typography";
import ListItemSkeleton from "./ListItemSkeleton/ListItemSkeleton";
import { Skeleton } from "@mui/material";
import meanBy from "lodash/meanBy";
import isWorkshopSpecialized from "@FEClient/logic/utils/isWorkshopSpecialized";
import Text from "@FEShared/components/UI/Text/Text";
import Countdown from "react-countdown";
import Box from "@FEShared/components/UI/Box/Box";
import CHECKMARK_IMG from "./assets/checkmark.png";
import formatVehicleDescriptionFE from "@FEShared/utils/formatVehicleDescriptionFE";
import { dateFormatFull } from "@Shared/util/dateFormat";
import { WHERE_CITY } from "@Shared/consts/CITIES";
import { ServicePriceType } from "@FEShared/graphql/generated/graphql";
import { countryClientPageToMeta } from "@Shared/util/clientPagesMeta";
import Icon from "@FEShared/components/UI/Icon/Icon";
import { useSearchInputsMobileMode } from "../TopSearchBar/TopSearchBar.utils";
import { TransMsg, transStr, transStringFunctionalFE } from "@FEShared/i18n";
import { translatable } from "@Shared/i18n/i18n";
import { Translatable } from "@Shared/i18n/i18n.types";

export const countdownRenderer = ({ days, hours, minutes, seconds }) => {
    return [
        days && `${days}d`,
        hours && `${hours}val`,
        `${minutes}m`,
        `${seconds}s`,
    ]
        .filter(Boolean)
        .join(". ");
};

const SORT_ORDER_TO_TEXT: Record<SidebarOrderBy, Translatable> = {
    [SidebarOrderBy.BEST]: translatable(
        "Pagal geriausią kainos/įvertinimo santykį ir specializaciją",
        { id: "uEdtOIEH" }
    ),
    [SidebarOrderBy.PRICE_HIGHEST]: translatable("Pagal aukščiausią kainą", {
        id: "l5OItN4m",
    }),
    [SidebarOrderBy.PRICE_LOWEST]: translatable("Pagal žemiausią kainą", {
        id: "YzFrfaUD",
    }),
    [SidebarOrderBy.REVIEW_RATING]: translatable("Pagal geriausią įvertinimą", {
        id: "Ywg0LhT2",
    }),
};

const SORTING_COFS = {
    SPECIALIZED: 0.25,
    INTERNAL_RATING: 0.3,
    BEST_PRICE_FOR_RATING: 0.7,
};

const ELEMENTS_PER_PAGE = isGoogleBot() ? 999 : 18;
const LAST_ITEMS_HIDDEN = 6;

const SearchSidebar: React.FC<{
    // tbd remove this carDescrTitle prop, this is a bit of a mess now. Just do all this handling inside.
    carDescrTitle: string;
    workshops: Workshop[];
    isLoading: boolean;
}> = observer((p) => {
    const GS = useStore();
    const SPS = GS.searchPageState;
    const isMobile = useIsMobile();
    const searchSideBarRef = React.useRef<HTMLDivElement>(null);
    const LS = useLocalObservable(() => ({
        orderBy: SidebarOrderBy.BEST,
    }));
    const isTopSearchBarMobileMode = useSearchInputsMobileMode();

    React.useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, window.innerHeight * 0.4);
        }, 100);
    }, []);

    const mapFilteredServices = p.workshops.filter((s) =>
        GS.searchPageState.workshopIDsInMapBounds.includes(s.ID)
    );

    const getServicesPrice = React.useCallback(
        (workshop: Workshop): { price: number; type: ServicePriceType } => {
            if (GS.searchState.selectedServicesDefinitionsIDs.length > 0) {
                const priceNDuration =
                    GS.getSelectedServicesPriceNDurationForWorkshop(workshop);

                if (priceNDuration) {
                    return {
                        price: priceNDuration.servicesPrice.value,
                        type: priceNDuration.priceObj.type,
                    };
                } else {
                    return {
                        price: 99999,
                        type: ServicePriceType.From,
                    };
                }
            } else {
                return {
                    price: workshop.hourCost,
                    type: ServicePriceType.From,
                };
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            GS.getSelectedServicesPriceNDurationForWorkshop,
            GS.searchState.selectedServicesDefinitionsIDs,
        ]
    );

    const [avgWorkshopsRating, avgWorkshopsCount] = React.useMemo(() => {
        const avgWorkshopsRating = meanBy(
            mapFilteredServices.filter(
                (i) => i.reviewRatingGmap && i.reviewRatingGmap > 0
            ),
            (item) => item.reviewRatingGmap
        );
        const avgWorkshopsCount = meanBy(
            mapFilteredServices.filter(
                (i) => i.reviewCountGmap && i.reviewCountGmap > 0
            ),
            (item) => item.reviewCountGmap
        );
        return [avgWorkshopsRating, avgWorkshopsCount];
    }, [mapFilteredServices]);

    const maxPriceForRatingCof = React.useMemo(() => {
        const bestBangForBuckRatings = mapFilteredServices.map((workshop) => {
            return calcBestBangForPriceRating(
                workshop,
                avgWorkshopsRating,
                getServicesPrice(workshop)
            );
        });
        return Math.max(...bestBangForBuckRatings);
    }, [mapFilteredServices, avgWorkshopsRating, getServicesPrice]);

    const orderedListItems = React.useMemo(() => {
        const specializedProps = {
            vehicleBrand: GS.searchState.carData.vehicleBrand,
            servicesIDs: GS.searchState.selectedServicesDefinitionsIDs,
        };

        switch (LS.orderBy) {
            case SidebarOrderBy.BEST:
                return lOrderBy(
                    mapFilteredServices,
                    [
                        (workshop) => {
                            const isSpecialized = isWorkshopSpecialized(
                                workshop,
                                specializedProps
                            ).isSpecializedForUser;
                            const priceForRatingCof =
                                calcBestBangForPriceRating(
                                    workshop,
                                    avgWorkshopsRating,
                                    getServicesPrice(workshop)
                                );

                            const finalCof =
                                (isSpecialized
                                    ? SORTING_COFS.SPECIALIZED
                                    : 0 +
                                      (workshop.ratingScore / 100) *
                                          SORTING_COFS.INTERNAL_RATING +
                                      (priceForRatingCof /
                                          maxPriceForRatingCof) *
                                          SORTING_COFS.BEST_PRICE_FOR_RATING) *
                                (workshop.up ? 1.45 : 1);

                            // console.log(
                            //     "finalCof",
                            //     finalCof,
                            //     workshop.name,
                            //     isSpecialized,
                            //     workshop.ratingScore,
                            //     priceForRatingCof,
                            //     maxPriceForRatingCof
                            // );
                            return finalCof;
                        },
                    ],
                    ["desc"]
                );
            case SidebarOrderBy.REVIEW_RATING:
                return lOrderBy(
                    mapFilteredServices,
                    (workshop) => {
                        return calcBayesianAverage(
                            workshop,
                            avgWorkshopsRating
                        );
                    },
                    ["desc"]
                );
            case SidebarOrderBy.PRICE_LOWEST:
            case SidebarOrderBy.PRICE_HIGHEST:
                return lOrderBy(
                    mapFilteredServices,
                    [
                        (workshop) =>
                            GS.searchState.selectedServicesDefinitionsIDs
                                .length > 0
                                ? GS.getSelectedServicesPriceNDurationForWorkshop(
                                      workshop
                                  )?.servicesPrice.value || 99999
                                : workshop.hourCost,
                        (workshop) => {
                            // this second price sort is needed to sort left-over workshops, who haven't provided prices/durations for the services, but others did, so they should be at the bottom sorted by hour cost.
                            return workshop.hourCost;
                        },
                        (workshop) => {
                            return calcBayesianAverage(
                                workshop,
                                avgWorkshopsRating
                            );
                        },
                    ],
                    [
                        LS.orderBy === SidebarOrderBy.PRICE_LOWEST
                            ? "asc"
                            : "desc",
                        LS.orderBy === SidebarOrderBy.PRICE_LOWEST
                            ? "asc"
                            : "desc",
                        "desc",
                    ]
                );
            default:
                return mapFilteredServices;
        }
    }, [
        GS,
        LS.orderBy,
        mapFilteredServices,
        avgWorkshopsRating,
        getServicesPrice,
        maxPriceForRatingCof,
    ]);

    const pageListItems = orderedListItems.slice(
        (SPS.page - 1) * ELEMENTS_PER_PAGE,
        SPS.page * ELEMENTS_PER_PAGE
    );
    const pageCount = Math.ceil(orderedListItems.length / ELEMENTS_PER_PAGE);

    React.useEffect(() => {
        runInAction(() => {
            SPS.page = 1;
        });
    }, [LS, LS.orderBy, GS.searchPageState.workshopIDsInMapBounds, SPS]);

    React.useEffect(() => {
        const currPageFirstElement = (SPS.page - 1) * ELEMENTS_PER_PAGE;

        runInAction(() => {
            GS.searchPageState.sortedTopWorkshopIDs = pageListItems
                .slice(0, ELEMENTS_PER_PAGE - LAST_ITEMS_HIDDEN)
                .map((i) => i.ID);

            // on purpose use orderedListItems here instead of pageListItems, because we want primary workshop to be shown on map as "small point" (in alt flow search). TBD might not be relevant anymore.
            GS.searchPageState.visibleWorkshopIDs = orderedListItems
                .slice(currPageFirstElement, SPS.page * ELEMENTS_PER_PAGE)
                .map((i) => i.ID);

            if (
                GS.searchPageState.selectedServiceId &&
                !GS.searchPageState.visibleWorkshopIDs.includes(
                    GS.searchPageState.selectedServiceId
                )
            ) {
                GS.searchPageState.visibleWorkshopIDs.push(
                    GS.searchPageState.selectedServiceId
                );
            }
        });
    }, [
        SPS.page,
        LS.orderBy,
        pageListItems,
        GS.searchPageState,
        GS.searchState.carData.vehicleBrand,
        GS.searchState.selectedServicesDefinitionsIDs,
        p.workshops,
        orderedListItems,
    ]);

    let sidebarTitle: JSX.Element | null;
    if (GS.searchPageState.altFlow.isAlt) {
        sidebarTitle = null;
    } else {
        sidebarTitle = (
            <>
                <S.ListTitleDescription>
                    {!isMobile && (
                        <Text
                            component="div"
                            semiBold
                            variant="inherit"
                            color="black"
                            fontSize={14}
                            sx={{ display: "inline", alignItems: "center" }}
                            mb={0.5}
                        >
                            <S.CheckmarkImg src={CHECKMARK_IMG} />
                            {p.isLoading ? (
                                <Skeleton
                                    width={18}
                                    variant="rounded"
                                    sx={{
                                        display: "inline-block",
                                        mr: 0.25,
                                        verticalAlign: "text-bottom",
                                    }}
                                />
                            ) : (
                                <span>
                                    {
                                        GS.searchPageState
                                            .workshopIDsInMapBounds.length
                                    }
                                </span>
                            )}
                            <Text
                                display="inline"
                                ml={0.25}
                                semiBold
                                lineHeight="inherit"
                                fontSize={14}
                                component={
                                    isTopSearchBarMobileMode ? "h2" : "h1"
                                }
                            >
                                <TransMsg
                                    default={
                                        "Patvirtinti {carBrand} autoservisai {city}{serviceName}"
                                    }
                                    data={{
                                        carBrand:
                                            GS.searchState.carData.vehicleBrand,
                                        city: WHERE_CITY[window._COUNTRY][
                                            GS.searchState.city
                                        ],
                                        serviceName:
                                            GS.searchState
                                                .selectedServicesTransNames
                                                .length >= 1
                                                ? ` - ${
                                                      GS.searchState
                                                          .selectedServicesTransNames[0]
                                                  }${
                                                      GS.searchState
                                                          .selectedServicesTransNames
                                                          .length > 1
                                                          ? `, +${
                                                                GS.searchState
                                                                    .selectedServicesTransNames
                                                                    .length - 1
                                                            }`
                                                          : ""
                                                  }`
                                                : "",
                                    }}
                                    id="VLRmaC4U"
                                />
                            </Text>
                        </Text>
                    )}
                    <Text variant="inherit" fontSize={12}>
                        <TransMsg
                            default={
                                "Nevažiuoja patikrina visus autoservisus matomus paieškoje. Kiekvienas autoservisas yra pasiruošęs priimti naujus klientus"
                            }
                            id="aS7yBg7I"
                        />{" "}
                        <Icon
                            variant="subtitle1"
                            fontSize={12}
                            ml={0.25}
                            className="icon-info-circle"
                            component="a"
                            target="_blank"
                            href="https://nevaziuoja.lt/blog/kaip-veikia-nevaziuoja-paieska"
                        />
                    </Text>
                </S.ListTitleDescription>
            </>
        );
    }

    const scrollListItemToView = React.useCallback(() => {
        if (isMobile) {
            document
                .querySelectorAll(`.${LIST_ITEM_CLASSNAME_IDENTIFIER}`)[0]
                ?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
        } else {
            searchSideBarRef.current?.scrollTo({
                behavior: "smooth",
                top: 0,
            });
        }
    }, [isMobile]);

    const onFooterNewSearchClick = () => {
        //history.replace cause loading state, no matter that request is finished. Probably there is a bug with loading state which needs to be solved.
        window.location.replace(
            `${countryClientPageToMeta(window._COUNTRY).SEARCH.url}/vilnius`
        );
    };

    const searchSidebarContainer = (
        <S.SearchSidebarContainer ref={searchSideBarRef}>
            <S.ListWrapperHead>
                {sidebarTitle && <S.ListTitle>{sidebarTitle}</S.ListTitle>}
                {SPS.altFlow.isAlt && SPS.altFlow.order && (
                    <Box
                        width={1}
                        rounded
                        bgcolor="#281382"
                        color="white.main"
                        padding={2}
                        displayFlex
                        alignVertical="center"
                        fontSize="16px"
                        sx={(theme) => ({
                            flexDirection: "column",
                            alignItems: "center",
                        })}
                    >
                        <Box
                            sx={(theme) => ({
                                mb: 1,
                                [theme.breakpoints.down("md")]: {
                                    fontSize: 14,
                                },
                            })}
                        >
                            <TransMsg
                                default={
                                    "Siūlome autoservisus, iš kurių gavome {Text <100% patvirtinimą>}, kad:"
                                }
                                data={{
                                    Text: (body) => (
                                        <Text
                                            semiBold
                                            span
                                            sx={{ textDecoration: "underline" }}
                                        >
                                            {body}
                                        </Text>
                                    ),
                                }}
                                id="SxEfbiUo"
                            />
                            <Box pl={1}>
                                <TransMsg
                                    default={
                                        "• gali priimti {arrivalDate} ar pasiūlė artimą laiką"
                                    }
                                    data={{
                                        arrivalDate: dateFormatFull(
                                            SPS.altFlow.order.arrivalDate
                                        ),
                                    }}
                                    id="B6pxXfDi"
                                />
                            </Box>{" "}
                            <Box pl={1}>
                                <TransMsg
                                    default={
                                        "• teikia pasirinktas paslaugas: {serviceNames}"
                                    }
                                    data={{
                                        serviceNames:
                                            SPS.altFlow.order.categories
                                                .map((c) => c.serviceName)
                                                .join(", "),
                                    }}
                                    id="OZkAbFxv"
                                />
                            </Box>
                            <Box pl={1}>
                                <TransMsg
                                    default={
                                        "• Remontuoja jūsų automobilį: {vehicleDescr}"
                                    }
                                    data={{
                                        vehicleDescr:
                                            formatVehicleDescriptionFE(
                                                SPS.altFlow.order
                                            ),
                                    }}
                                    id="TIcJ7NJO"
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={(theme) => ({
                                mt: 1,
                                fontWeight: 500,
                            })}
                            rounded
                            bgcolor={"white"}
                            color="white"
                            whiteSpace={"nowrap"}
                            align="center"
                            displayFlex
                            vertical
                            flexDirection="column"
                        >
                            <div>
                                <TransMsg
                                    default={"Liko laiko pasirinkti:"}
                                    id="1IZkTZGL"
                                />
                            </div>
                            <div>
                                <Countdown
                                    date={
                                        SPS.altFlow.order.customerPickTimeoutAt
                                    }
                                    renderer={countdownRenderer}
                                />
                            </div>
                        </Box>
                    </Box>
                )}
            </S.ListWrapperHead>
            <S.ListWrapper>
                <S.TopWorkshops.Wrapper>
                    <div>
                        <Box displayFlex>
                            <S.TopWorkshops.Icon />
                            <S.TopWorkshops.Title>
                                <TransMsg
                                    default={"TOP Autoservisai"}
                                    id="YTbArVGF"
                                />
                            </S.TopWorkshops.Title>
                        </Box>

                        <Typography
                            variant="subtitle2"
                            whiteSpace={"nowrap"}
                            mr={1}
                        >
                            {transStringFunctionalFE(
                                SORT_ORDER_TO_TEXT[LS.orderBy]
                            )}
                        </Typography>
                    </div>
                    {!GS.searchPageState.altFlow.isAlt &&
                        p.workshops.length > 1 && (
                            <S.DropdownWrapper>
                                <S.OrderDropdown
                                    leftIconClass="icon-transfer"
                                    options={[
                                        {
                                            value: SidebarOrderBy.BEST,
                                            text: transStr("Rekomenduojami", {
                                                id: "XGMeu5Ph",
                                            }),
                                        },
                                        {
                                            value: SidebarOrderBy.REVIEW_RATING,
                                            text: transStr(
                                                "Geriausiai įvertinti",
                                                { id: "fQz8zs3O" }
                                            ),
                                        },
                                        {
                                            value: SidebarOrderBy.PRICE_LOWEST,
                                            text: transStr("Žemiausia kaina", {
                                                id: "KSFUfA9l",
                                            }),
                                        },
                                        {
                                            value: SidebarOrderBy.PRICE_HIGHEST,
                                            text: transStr(
                                                "Aukščiausia kaina",
                                                { id: "WNQg2Atu" }
                                            ),
                                        },
                                    ]}
                                    value={LS.orderBy}
                                    onChange={(val) =>
                                        runInAction(() => {
                                            LS.orderBy = val.target
                                                .value as SidebarOrderBy;
                                        })
                                    }
                                />
                            </S.DropdownWrapper>
                        )}
                </S.TopWorkshops.Wrapper>
                {p.isLoading ? (
                    <>
                        <ListItemSkeleton />
                        <ListItemSkeleton />
                        <Skeleton
                            height={19}
                            width={"70%"}
                            sx={{ mb: 2, mt: 3 }}
                        />
                        <ListItemSkeleton />
                        <ListItemSkeleton />
                        <ListItemSkeleton />
                        <ListItemSkeleton />
                    </>
                ) : pageListItems.length > 0 ? (
                    <>
                        {pageListItems.map((service, index) => {
                            if (index < 2 && SPS.page === 1) {
                                return (
                                    <>
                                        <ListItem
                                            workshop={service}
                                            key={service.ID}
                                            topWorkshop
                                        />
                                        {index === 1 && !SPS.altFlow.isAlt && (
                                            <S.OtherWorkshops.Wrapper>
                                                <S.OtherWorkshops.Icon />
                                                <TransMsg
                                                    default={
                                                        "Visi autoservisai"
                                                    }
                                                    id="diM3fm3i"
                                                />
                                            </S.OtherWorkshops.Wrapper>
                                        )}
                                    </>
                                );
                            } else {
                                return (
                                    <ListItem
                                        workshop={service}
                                        key={service.ID}
                                    />
                                );
                            }
                        })}
                        {pageCount > 1 && (
                            <S.PaginationWrapper>
                                <S.Pagination
                                    page={SPS.page}
                                    onChange={(_e, newPage) => {
                                        if (newPage === SPS.page) return;
                                        runInAction(() => {
                                            SPS.page = newPage;
                                        });
                                        setTimeout(() => {
                                            scrollListItemToView();
                                        }, 0);
                                    }}
                                    size={isMobile ? "small" : "medium"}
                                    count={pageCount}
                                />
                            </S.PaginationWrapper>
                        )}
                    </>
                ) : (
                    <>
                        <div>
                            <TransMsg
                                default={
                                    "Šioje vietoje neradome nei vieno autoserviso. Pabandykite pakeisti vietą žemėlapyje arba paredaguoti pasirinktą laiką."
                                }
                                id="2x8viQNU"
                            />
                        </div>
                    </>
                )}
                {/* <S.ListTitleDescription mt={2}>
                    <>
                        {GS.searchState.selectedServicesNames.length > 0 && (
                            <div>
                                {
                                    GS.searchPageState.workshopIDsInMapBounds
                                        .length
                                }{" "}
                                autoservisai {WHERE_CITY[GS.searchState.city]}{" "}
                                teikia paslaugas:{" "}
                                {GS.searchState.selectedServicesNames.join(
                                    ", "
                                )}
                            </div>
                        )}
                        {GS.searchState.carDataArr.length > 0 && (
                            <S.ListTitleDescription>
                                Remontuoja: {p.carDescrTitle}
                            </S.ListTitleDescription>
                        )}
                    </>
                </S.ListTitleDescription> */}
            </S.ListWrapper>
            {SPS.altFlow.isAlt && (
                <S.ListWrapperFooter>
                    <S.ListWrapperFooterText>
                        <TransMsg
                            default={"Netinka alternatyvos?"}
                            id="35cC0Y1G"
                        />
                    </S.ListWrapperFooterText>
                    <S.ListWrapperFooterButton
                        color="greyish"
                        onClick={onFooterNewSearchClick}
                    >
                        <TransMsg default={"Nauja paieška"} id="n11tQoD7" />
                    </S.ListWrapperFooterButton>
                </S.ListWrapperFooter>
            )}
        </S.SearchSidebarContainer>
    );

    return isMobile ? (
        <S.DraggableWrapper>
            <S.MobileHeader onClick={scrollListItemToView}>
                <S.MobileHeaderDash />
                <S.ClosedDashText>
                    <S.CheckmarkImg src={CHECKMARK_IMG} />
                    {p.isLoading ? (
                        <Skeleton
                            width={20}
                            height={17}
                            sx={{
                                display: "inline-block",
                            }}
                            variant="rounded"
                        />
                    ) : (
                        GS.searchPageState.workshopIDsInMapBounds.length
                    )}{" "}
                    <TransMsg
                        default={"patvirtinti autoservisai"}
                        id="aKJMA7pZ"
                    />
                </S.ClosedDashText>
            </S.MobileHeader>
            {searchSidebarContainer}
        </S.DraggableWrapper>
    ) : (
        searchSidebarContainer
    );
});

export default SearchSidebar;
