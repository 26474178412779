import translatableCustomIDToTransString from "@FEShared/utils/translatableCustomIDToTranslatable";
import { TranslatableCustomID_PopularServices } from "@Shared/types/enums/TranslatableCustomID";

/* This should be only called inside react component, to make sure that translatables loading is awaited*/
const getPopularSearchOptions = () => [
    translatableCustomIDToTransString(
        TranslatableCustomID_PopularServices.OIL_CHANGE
    ),
    translatableCustomIDToTransString(
        TranslatableCustomID_PopularServices.SUSPENSION_REPAIR
    ),
    translatableCustomIDToTransString(
        TranslatableCustomID_PopularServices.UNKNOWN_PROBLEM
    ),
    translatableCustomIDToTransString(
        TranslatableCustomID_PopularServices.FRONT_BRAKE_PADS_REPLACEMENT
    ),
    translatableCustomIDToTransString(
        TranslatableCustomID_PopularServices.REAR_BRAKE_PADS_REPLACEMENT
    ),
    translatableCustomIDToTransString(
        TranslatableCustomID_PopularServices.TECHNICAL_INSPECTION
    ),
    translatableCustomIDToTransString(
        TranslatableCustomID_PopularServices.CONDITIONER_REPAIR
    ),
    translatableCustomIDToTransString(
        TranslatableCustomID_PopularServices.CONDITIONER_REFILL_DONT_KNOW_FREON_TYPE
    ),
    translatableCustomIDToTransString(
        TranslatableCustomID_PopularServices.AUTOMATIC_GEARBOX_OIL_CHANGE
    ),
    translatableCustomIDToTransString(
        TranslatableCustomID_PopularServices.LIGHTS_REPAIR
    ),
    translatableCustomIDToTransString(
        TranslatableCustomID_PopularServices.ENGINE_DIAGNOSTICS
    ),
    translatableCustomIDToTransString(
        TranslatableCustomID_PopularServices.ENGINE_CHAIN_REPLACEMENT
    ),
];
export default getPopularSearchOptions;
