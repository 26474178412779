import React from "react";
import * as S from "./Footer.styled";
import {
    ClientPage,
    countryClientPageToMeta,
} from "@Shared/util/clientPagesMeta";
import { UrlPathnameParser } from "@FEClient/views/pages/Search/Search.logic";
import useStore from "@FEClient/logic/store";
import { observer } from "mobx-react-lite";
import FECLIENT_BRANDS_SEO_PAGES from "@Shared/consts/FECLIENT_BRANDS_SEO_PAGES";
import lOrderBy from "lodash/orderBy";
import { TransMsg, transStringFunctionalFE } from "@FEShared/i18n";
import CITIES, { WHERE_CITY } from "@Shared/consts/CITIES";
import Country from "@Shared/types/enums/Country";
import { MOST_POPULAR_SERVICES_LANDINGS } from "@Shared/consts/FECLIENT_ALL_SERVICES_SEO_PAGES";

const LINKS_TO_SHOW = 15;

const Footer: React.FC<{}> = observer((_p) => {
    const GS = useStore();

    const servicesSeoData = Object.entries(
        MOST_POPULAR_SERVICES_LANDINGS(window._COUNTRY)
    )
        .filter(([url, seoData]) => seoData.footer)
        .slice(0, LINKS_TO_SHOW);

    const brandsSeoData = lOrderBy(
        Object.entries(
            FECLIENT_BRANDS_SEO_PAGES(transStringFunctionalFE, window._COUNTRY)
        ),
        ([_brand, seoData]) => seoData.sortOrder,
        "desc"
    ).slice(0, LINKS_TO_SHOW);

    return (
        <S.FooterContainer>
            <S.FooterContentContainer>
                <S.SocialMediaRow>
                    <S.IconLink
                        target="_blank"
                        aria-label="nevaziuoja-facebook"
                        href="https://facebook.com/nevaziuoja.lt"
                    >
                        <S.SocialMediaIcon className="icon-facebook" />
                    </S.IconLink>
                    <S.IconLink
                        target="_blank"
                        aria-label="nevaziuoja-instagram"
                        href="https://www.instagram.com/nevaziuoja.lt/"
                    >
                        <S.SocialMediaIcon className="icon-instagram" />
                    </S.IconLink>
                </S.SocialMediaRow>
                <S.FooterLogo className="icon-full-logo" />
                <S.FooterLinksWrapper>
                    <S.ColumnWrapper>
                        <S.ColumnTitle>
                            <TransMsg
                                default={
                                    "Automobilio remontas visoje {countryWhere}"
                                }
                                data={{
                                    countryWhere:
                                        window._COUNTRY_META.countryWhere,
                                }}
                                id="KxZvNsOE"
                            />
                        </S.ColumnTitle>
                        {CITIES[window._COUNTRY].map((c) => (
                            <S.ImportantLink
                                key={c}
                                href={UrlPathnameParser.paramsToPathname({
                                    city: c,
                                })}
                            >
                                <TransMsg
                                    default={"Autoservisai {cityWhere}"}
                                    id="y9cYlWvH"
                                    data={{
                                        cityWhere:
                                            WHERE_CITY[window._COUNTRY][c],
                                    }}
                                />
                            </S.ImportantLink>
                        ))}
                    </S.ColumnWrapper>
                    <S.ColumnWrapper>
                        <S.ColumnTitle>
                            <TransMsg
                                default={
                                    "Populiarios automobilių remonto paslaugos"
                                }
                                id="j3H9kovr"
                            />
                        </S.ColumnTitle>
                        {servicesSeoData.map(([url, seoData]) => {
                            return (
                                <S.ImportantLink href={url} key={url}>
                                    {seoData.seoServiceName}
                                </S.ImportantLink>
                            );
                        })}
                    </S.ColumnWrapper>
                    <S.ColumnWrapper>
                        <S.ColumnTitle>
                            <TransMsg
                                default={"Populiarių markių servisai"}
                                id="GuqAT98T"
                            />
                        </S.ColumnTitle>
                        {brandsSeoData.map(([url, seoData]) => {
                            return (
                                <S.ImportantLink href={url} key={url}>
                                    {seoData.seoServiceName}
                                </S.ImportantLink>
                            );
                        })}
                    </S.ColumnWrapper>
                </S.FooterLinksWrapper>
                <S.FooterLinksWrapper mt={2}>
                    <S.ColumnWrapper>
                        <S.ColumnTitle>
                            <TransMsg
                                default={"Kitos nuorodos"}
                                id="iMLqghOk"
                            />
                        </S.ColumnTitle>
                        {window._COUNTRY === Country.LT && (
                            <S.ExternalLink href={"https://nevaziuoja.lt/blog"}>
                                <TransMsg default={"Blog'as"} id="NeVAF6q6" />
                            </S.ExternalLink>
                        )}

                        <S.UnimportantLink
                            href={
                                countryClientPageToMeta(window._COUNTRY)
                                    .WARRANTY.url
                            }
                        >
                            <TransMsg
                                default={"Techninė garantija"}
                                id="HPEjMvlE"
                            />
                        </S.UnimportantLink>
                        <S.UnimportantLink
                            href={
                                countryClientPageToMeta(window._COUNTRY)
                                    .PARTNERS.url
                            }
                        >
                            <TransMsg default={"Partneriai"} id="jnWw56yk" />
                        </S.UnimportantLink>
                        <S.UnimportantLink
                            href={
                                countryClientPageToMeta(window._COUNTRY)
                                    .ABOUT_US.url
                            }
                        >
                            <TransMsg default={"Apie Mus"} id="VVd2mjCp" />
                        </S.UnimportantLink>
                        <S.UnimportantLink
                            href={
                                countryClientPageToMeta(window._COUNTRY).SITEMAP
                                    .url
                            }
                        >
                            <TransMsg
                                default={"Svetainės struktūra"}
                                id="dkqUXhHO"
                            />
                        </S.UnimportantLink>
                        <S.UnimportantLink
                            href={
                                countryClientPageToMeta(window._COUNTRY)
                                    .SEO_SERVICES.url
                            }
                        >
                            <TransMsg
                                default={"Automobilių serviso paslaugos"}
                                id="moYxsNUj"
                            />
                        </S.UnimportantLink>
                        <S.UnimportantLink
                            href={
                                countryClientPageToMeta(window._COUNTRY)
                                    .PRIVACY_POLICY.url
                            }
                        >
                            <TransMsg
                                default={"Privatumo politika"}
                                id="0xZgSB4N"
                            />
                        </S.UnimportantLink>
                        <S.UnimportantLink
                            href={
                                countryClientPageToMeta(window._COUNTRY)
                                    .TERMS_OF_SERVICE.url
                            }
                        >
                            <TransMsg
                                default={"Paslaugų teikimo sąlygos"}
                                id="uXI9RTd4"
                            />
                        </S.UnimportantLink>
                    </S.ColumnWrapper>
                    <S.ColumnWrapper></S.ColumnWrapper>
                    <S.ColumnWrapper>
                        <S.ColumnTitle>
                            <TransMsg default={"Kontaktai"} id="YB0F3tYk" />
                        </S.ColumnTitle>
                        {window._COUNTRY === Country.LT && (
                            <S.ContactRow>
                                <S.ContactIcon className="icon-card-info" />
                                MB Nevažiuoja, i.k.: 306066889
                            </S.ContactRow>
                        )}

                        <S.ContactRow>
                            <S.ContactIcon className="icon-email" />
                            <a
                                color="white.main"
                                href={`mailto:${window._COUNTRY_META.clientEmail}`}
                            >
                                {window._COUNTRY_META.clientEmail}
                            </a>
                        </S.ContactRow>
                        {GS.activePage !== ClientPage.WORKSHOP && (
                            <S.ContactRow>
                                <S.ContactIcon className="icon-phone" />
                                <a
                                    color="white.main"
                                    href={`tel:${window._COUNTRY_META.phoneNumber}`}
                                >
                                    {window._COUNTRY_META.phoneNumber}
                                </a>
                            </S.ContactRow>
                        )}
                    </S.ColumnWrapper>
                    {/* <S.ColumnWrapper>
                        <LanguageSelector i18nService={i18nService} />
                    </S.ColumnWrapper> */}
                </S.FooterLinksWrapper>
            </S.FooterContentContainer>
        </S.FooterContainer>
    );
});

export default Footer;
