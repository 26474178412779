import {
    DONT_KNOW_MODEL,
    DONT_KNOW_YEAR,
    OTHER_MODEL_OR_YEAR,
    OTHER_BRAND,
} from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete.consts";
import { translatable } from "@Shared/i18n/i18n";
import lMapValues from "lodash/mapValues";

/* Put all really common translatables, into here, so that translators wouldn't have to translate the same translatable 10 times. */
const COMMON_TRANSLATABLES = lMapValues({
    PARTS: translatable("Dalys", {
        id: "q42fPvZY",
        description: "COMMON_TRANSLATABLE",
    }),
    SERVICES: translatable("Paslaugos", {
        id: "04wBvFRr",
        description: "COMMON_TRANSLATABLE",
    }),
    BACK: translatable("Atgal", {
        id: "LSVzq5rx",
        description: "COMMON_TRANSLATABLE",
    }),
    CANCEL: translatable("Atšaukti", {
        id: "QOnfy3Ui",
        description: "COMMON_TRANSLATABLE",
    }),
    CONFIRM: translatable("Patvirtinti", {
        id: "bQoFjbMp",
        description: "COMMON_TRANSLATABLE",
    }),
    MARK_ALL: translatable("Pažymėti visus", {
        id: "W6c24of2",
        description: "COMMON_TRANSLATABLE",
    }),
    CONTINUE: translatable("Tęsti", {
        id: "66L7Ey73",
        description: "COMMON_TRANSLATABLE",
    }),
    SAVE: translatable("Išsaugoti", {
        id: "8L7w2LiB",
        description: "COMMON_TRANSLATABLE",
    }),
    HOUR_SHORT: translatable("val.", {
        id: "fyvb0Y2o",
        description: "COMMON_TRANSLATABLE",
    }),
    monday: translatable("pirmadienis", {
        id: "N6a2IlZp",
        description: "COMMON_TRANSLATABLE",
    }),
    tuesday: translatable("antradienis", {
        id: "mbScei6B",
        description: "COMMON_TRANSLATABLE",
    }),
    wednesday: translatable("trečiadienis", {
        id: "ZGuKeKbi",
        description: "COMMON_TRANSLATABLE",
    }),
    thursday: translatable("ketvirtadienis", {
        id: "fC7eA4Ba",
        description: "COMMON_TRANSLATABLE",
    }),
    friday: translatable("penktadienis", {
        id: "o7R92XlY",
        description: "COMMON_TRANSLATABLE",
    }),
    saturday: translatable("šeštadienis", {
        id: "dB14Dvg4",
        description: "COMMON_TRANSLATABLE",
    }),
    sunday: translatable("sekmadienis", {
        id: "0z3EECxY",
        description: "COMMON_TRANSLATABLE",
    }),
    [DONT_KNOW_MODEL]: translatable("Nežinau modelio", {
        id: "RrsGkB20",
        description: "COMMON_TRANSLATABLE",
    }),
    [DONT_KNOW_YEAR]: translatable("Nežinau metų", {
        id: "tHwYOq1L",
        description: "COMMON_TRANSLATABLE",
    }),
    [OTHER_MODEL_OR_YEAR]: translatable("-kita-", {
        id: "zu1CdTRm",
        description: "COMMON_TRANSLATABLE",
    }),
    [OTHER_BRAND]: translatable("-Kita", {
        id: "3Et5TKEX",
        description: "COMMON_TRANSLATABLE",
    }),
});
export default COMMON_TRANSLATABLES;
