import React from "react";
import Box from "@FEShared/components/UI/Box/Box";
import { Amenity } from "@FEShared/graphql/generated/graphql";
import Icon from "@FEShared/components/UI/Icon/Icon";
import Text from "@FEShared/components/UI/Text/Text";
import lOrderBy from "lodash/orderBy";
import { transStr } from "@FEShared/i18n";

const AmenityRow: React.FC<{
    leftText?: string;
    amenities: Amenity[];
    enabledAmenities: Amenity[];
    iconClass: string;
}> = (p) => {
    const AMENITY_TO_TEXT: Record<Amenity, string> = {
        [Amenity.LanguageEn]: transStr("anglų", { id: "aO7SVP89" }),
        [Amenity.LanguageLt]: transStr("lietuvių", { id: "rgPj722W" }),
        [Amenity.LanguagePl]: transStr("lenkų", { id: "FnnYS506" }),
        [Amenity.LanguageRu]: transStr("rusų", { id: "3ALCCUuz" }),
        [Amenity.LanguageFr]: transStr("prancūzų", { id: "z3lGblpz" }),
        [Amenity.PaymentCash]: transStr("grynais", { id: "x6GUJMRr" }),
        [Amenity.PaymentCard]: transStr("kortele", { id: "OBsKppB8" }),
        [Amenity.PaymentBank]: transStr("banku", { id: "ESkqB2au" }),
        [Amenity.ReplacementVehicle]: transStr("Pakaitinis automobilis", {
            id: "SpkCCwVV",
        }),
        [Amenity.VehicleReturnService]: transStr("Automobilio pristatymas", {
            id: "z3lGblpz",
        }),
        [Amenity.VehicleDropOff]: transStr("Galima palikti automobilį 24/7", {
            id: "5HW20xSa",
        }),
        [Amenity.WaitingArea]: transStr("Laukiamasis", { id: "FFwkAq2z" }),
        [Amenity.Wifi]: transStr("Wi-Fi", { id: "THRU1XlM" }),
        [Amenity.RestaurantNearby]: transStr("Šalia kavinė", {
            id: "XlGmoArt",
        }),
    };

    // show enabled first;
    const orderedAmenities = lOrderBy(
        p.amenities,
        [(a) => p.enabledAmenities.includes(a)],
        ["desc"]
    );

    const hasAtleastOneEnabled = p.amenities.some((a) =>
        p.enabledAmenities.includes(a)
    );

    return (
        <Box mb={1}>
            <Icon
                width={30}
                className={p.iconClass}
                fontSize={20}
                mr={1}
                center
                display={"inline-block"}
                sx={{
                    opacity: hasAtleastOneEnabled ? 1 : 0.4,
                }}
            />
            {p.leftText && (
                <Text
                    span
                    sx={{
                        opacity: hasAtleastOneEnabled ? 1 : 0.4,
                    }}
                >
                    {p.leftText}{" "}
                </Text>
            )}
            {orderedAmenities.map((a, index) => (
                <Text
                    span
                    key={a}
                    sx={{ opacity: p.enabledAmenities.includes(a) ? 1 : 0.4 }}
                >
                    {AMENITY_TO_TEXT[a]}
                    {index !== p.amenities.length - 1 && ", "}
                </Text>
            ))}
        </Box>
    );
};

export default AmenityRow;
