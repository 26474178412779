import React from "react";
import * as S from "./Logo.styled";
import { observer } from "mobx-react-lite";
import Country from "@Shared/types/enums/Country";

// maybe add white prop if will be reused everywhere
const Logo = observer<{ className?: string; white?: boolean }>((p) => {
    return (
        <S.LogoWrapper className={p.className} $white={p.white}>
            <S.LogoMark className="icon-logo-symbol"></S.LogoMark>
            {window._COUNTRY === Country.LT ? (
                <S.LogoText className="icon-text-logo"></S.LogoText>
            ) : (
                "RepairPunk"
            )}
        </S.LogoWrapper>
    );
    // return <S.Logo className="icon-full-logo" />;
});

export default Logo;
