import React from "react";
import Text from "@FEShared/components/UI/Text/Text";
import Box from "@FEShared/components/UI/Box/Box";
import Button from "@FEShared/components/UI/Button/Button";
import ButtonsMenu from "@FEShared/components/UI/ButtonsMenu/ButtonsMenu";
import useStore from "@FEClient/logic/store";
import HEART_BROKEN from "./assets/heart-broken.png";
import Img from "@FEShared/components/UI/Img/Img";
import { ModalStep } from "../ReservationCancelModal.types";
import { TransMsg, transStr } from "@FEShared/i18n";

export const PROC = "10%";

const PromoOfferStep: React.FC<{
    nextStep: (step: ModalStep) => void;
    onClose: () => void;
    isLoading?: ModalStep;
}> = (p) => {
    const GS = useStore();

    const order = GS.reservationSuccessPageState.completedOrder;

    if (!order)
        return (
            <div>
                <TransMsg default={"Kraunama..."} id="QNHgK2SK" />
            </div>
        );

    return (
        <div>
            <Text mb={2} semiBold center fontSize={28}>
                <TransMsg default={"Tai ne tu, tai mes..."} id="tJ5QGMms" />
            </Text>
            <Box textAlign="center">
                <Img
                    src={HEART_BROKEN}
                    mt={3}
                    mb={3}
                    ml="auto"
                    mr="auto"
                    height={225}
                    sx={{ textAlign: "center" }}
                />
            </Box>
            <Text center>
                <TransMsg
                    default={
                        "Apmaudu, bet nenorime visko užbaigti čia… 🤧 Neatšauk vizito ar persiregistruok (kitu laiku ar į kitą autoservisą) ir {Text1 <gauk {PROC} nuolaidą 💸>}"
                    }
                    data={{
                        PROC,
                        Text1: (body) => (
                            <Text span semiBold>
                                {body}
                            </Text>
                        ),
                    }}
                    id="ezOv3Xnl"
                />
            </Text>
            <Box
                mt={4}
                displayFlex
                sx={(theme) => ({
                    [theme.breakpoints.down("md")]: {
                        flexDirection: "column",
                    },
                })}
            >
                <Button
                    sx={(theme) => ({
                        mr: 2,
                        [theme.breakpoints.down("md")]: {
                            mr: 0,
                            mb: 2,
                            flexDirection: "column",
                        },
                    })}
                    fullWidth
                    disabled={Boolean(p.isLoading)}
                    isLoading={p.isLoading === "3_NO_CANCEL"}
                    onClick={async () => {
                        p.nextStep("3_NO_CANCEL");
                    }}
                >
                    <TransMsg
                        default={"Neatšaukti ir aktyvuoti {PROC} nuolaidą"}
                        data={{ PROC }}
                        id="DmJHi2w5"
                    />
                </Button>
                <ButtonsMenu
                    paperProps={{
                        sx: {
                            boxShadow: 3,
                        },
                    }}
                    btnProps={{ color: "greyish", fullWidth: true }}
                    menuButtonText={transStr("Atšaukti/Persiregistruoti", {
                        id: "s7Y90Z8T",
                    })}
                    options={[
                        {
                            disabled: Boolean(p.isLoading),
                            isLoading: p.isLoading === "3_CHANGE_DATE",
                            value: transStr(
                                "Keisti vizito laiką ({PROC} nuolaida)",
                                { data: { PROC }, id: "Z8te3EAm" }
                            ),
                            title: transStr(
                                "Keisti vizito laiką ({PROC} nuolaidą)",
                                { data: { PROC }, id: "GEMT767Z" }
                            ),
                            onClick: async () => {
                                p.nextStep("3_CHANGE_DATE");
                            },
                        },
                        {
                            disabled: Boolean(p.isLoading),
                            isLoading: p.isLoading === "3_RE_REG",
                            value: transStr(
                                "Nauja registracija ({PROC} nuolaidą)",
                                { data: { PROC }, id: "eEeROKpn" }
                            ),
                            title: transStr(
                                "Nauja registracija ({PROC} nuolaidą)",
                                { data: { PROC }, id: "NBWWdiBB" }
                            ),
                            onClick: async () => {
                                p.nextStep("3_RE_REG");
                            },
                        },
                        {
                            disabled: Boolean(p.isLoading),
                            isLoading: p.isLoading === "3_CANCEL",
                            value: transStr("Atšaukti rezervaciją", {
                                id: "9pUDd47z",
                            }),
                            title: transStr("Atšaukti rezervaciją", {
                                id: "2zv4BcaN",
                            }),
                            onClick: async () => {
                                p.nextStep("3_CANCEL");
                            },
                        },
                    ]}
                />
            </Box>
        </div>
    );
};

export default PromoOfferStep;
