import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import * as S from "./DatePicker.styled";
import ltLocale from "date-fns/locale/lt";
import caLocale from "date-fns/locale/en-CA";
import dateFormat, {
    dateObjToDate,
    dateObjToString,
} from "@Shared/util/dateFormat";
import showToast, { TOAST_CLASSNAME } from "@FEShared/utils/showToast";
import { filterTimes, TIME_SLOTS } from "./DatePicker.utils";
import {
    BlockedTimeslot,
    Calendar,
    EmployeeTimeblocks,
} from "@FEShared/graphql/generated/graphql";
import { DateObj } from "@Shared/types/types";
import {
    DATE_PICKER_CLASSNAME_IDENTIFIER,
    MAX_DATE_1_YEAR_FROM_NOW,
    displayStatic,
    views,
} from "./DatePicker.consts";
import { BoxProps } from "@mui/material/Box";
import BusinessDateHandler, {
    WantedService,
} from "@Shared/util/BusinessDateHandler";
import { TimeSlot } from "./DatePicker.types";
import { DateTime } from "luxon";
import Box from "../Box/Box";
import Text from "../Text/Text";
import { FormControlLabel, Checkbox, CircularProgress } from "@mui/material";
import lDebounce from "lodash/debounce";
import { TransMsg, transStr } from "@FEShared/i18n";
import pickByCountry from "@Shared/util/pickByCountry";
import Country from "@Shared/types/enums/Country";

const debounced = lDebounce((callbackFn: () => void) => {
    callbackFn();
}, 500);

export type DatePickerP = {
    togglePageScroll?: (isScrollEnabled: boolean) => void; // tbd maybe move this to theme if possible. Should be possible?
    dateObj: DateObj;
    onDateChange: (dateObj: Partial<DateObj>) => void;
    calendar?: Calendar;
    city?: string;
    earliestAvailableDate?: Date;
    forceInputError?: boolean;
    onClose?: () => void;
    placeholder?: string;
    errorsEnabled?: boolean;
    notClearable?: boolean;
    disablePast?: boolean;
    disablePortal?: boolean;
    fnsRef?: React.RefObject<{ open: () => void }>;
    noTimeSelector?: boolean;
    sx?: BoxProps["sx"];
    blockedTimeslots?: BlockedTimeslot[];
    wantedServices?: WantedService[];
    fixedOpen?: boolean;
    hideValueInput?: boolean;
    setEarliestMins?: boolean;
    employees?: EmployeeTimeblocks[];
    immediateValue?: boolean;
    immediateValChange?: (immediate: boolean) => void;
    showImmediateCheckbox?: boolean;
};
// TBD - DatePicker still uses ExpansivePopper (legacy component, that should be refactored to use Popover/Modal or similar)
const DatePicker = observer<DatePickerP>((p) => {
    const datePickerRef = React.useRef<HTMLDivElement>(null);
    const datePickerWrapperRef = React.useRef<HTMLDivElement>(null);
    const timesCacheRef = React.useRef(new Map<string, TimeSlot[]>());
    const shouldDisableDateCacheRef = React.useRef(new Map<string, boolean>());
    const LS = useLocalObservable(() => ({
        anchorEl: null as null | HTMLElement,
        onClose: () => {
            if (p.fixedOpen) return;
            p.onClose && p.onClose();
            LS.anchorEl = null;
        },
        isPending: false,
    }));
    const formattedTimeString = dateObjToString(p.dateObj);
    const { onClose } = LS;
    const { onDateChange, immediateValChange } = p;

    // it prob would be better if it was just provided from a prop
    const bizDateHandler = React.useMemo(() => {
        if (p.calendar && !p.city) {
            console.error(
                `City not found for datePicker when calendar was provided`
            );
        }

        const bizCalendar = p.calendar
            ? new BusinessDateHandler({
                  city: p.city || window._COUNTRY_META.defaultCity,
                  workHoursCalendar: p.calendar,
                  blockedTimeslots: p.blockedTimeslots?.map((slot) => ({
                      // this is not very nice, need to fix GQL parsing of dates , because its returned as string now(if possible at all)
                      ...slot,
                      endDate: new Date(slot.endDate),
                      startDate: new Date(slot.startDate),
                  })),
                  wantedServices: p.wantedServices,
                  employees: p.employees,
              })
            : undefined;

        if (bizCalendar) {
            timesCacheRef.current.clear();
            shouldDisableDateCacheRef.current.clear();
        }

        return bizCalendar;
    }, [p.calendar, p.blockedTimeslots, p.wantedServices, p.employees, p.city]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (bizDateHandler) {
            if (
                p.dateObj.HH_MM &&
                p.dateObj.YYYY_MM_DD &&
                p.wantedServices &&
                p.wantedServices.length > 0 &&
                !bizDateHandler.isAvailableForServices(
                    DateTime.fromJSDate(dateObjToDate(p.dateObj)),
                    {
                        immediateReservation: p.immediateValue,
                    }
                )
            ) {
                setToEarliestAvlTime();
            }
        }
    }, [bizDateHandler]); //eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (p.wantedServices && p.wantedServices.length > 0) {
            runInAction(() => {
                LS.isPending = true;
            });
            debounced(() => {
                runInAction(() => {
                    LS.isPending = false;
                });
            });
        }
    }, [LS, bizDateHandler, p.immediateValue]); //eslint-disable-line react-hooks/exhaustive-deps

    const isError = React.useMemo(() => {
        return (p.errorsEnabled && !p.dateObj.YYYY_MM_DD) || !p.dateObj.HH_MM;
    }, [p.errorsEnabled, p.dateObj.YYYY_MM_DD, p.dateObj.HH_MM]);

    React.useImperativeHandle(
        p.fnsRef,
        () => {
            return {
                open: () => {
                    runInAction(() => {
                        LS.anchorEl = datePickerWrapperRef.current;
                    });
                },
            };
        },
        [LS]
    );

    React.useEffect(() => {
        if (
            p.earliestAvailableDate &&
            dateObjToDate(p.dateObj) < p.earliestAvailableDate
        ) {
            p.onDateChange({
                HH_MM: undefined,
                YYYY_MM_DD: undefined,
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (p.fixedOpen) {
            runInAction(() => {
                LS.anchorEl = datePickerWrapperRef.current;
            });
        }
    }, [LS, p.fixedOpen]);

    const today_YYYY_MM_DD =
        p.dateObj.YYYY_MM_DD ||
        p.earliestAvailableDate?.toLocaleDateString(
            window._COUNTRY_META.locale
        ) ||
        new Date().toLocaleDateString(window._COUNTRY_META.locale);

    const handleClickOutside = React.useCallback(
        (event: MouseEvent) => {
            const eventTarget = event.target as HTMLElement;
            if (
                eventTarget.closest(`.${TOAST_CLASSNAME}`) ||
                datePickerRef.current?.contains(eventTarget) ||
                eventTarget.closest(`.${DATE_PICKER_CLASSNAME_IDENTIFIER}`)
            ) {
                return;
            }
            LS.onClose();
        },
        [LS]
    );

    React.useEffect(() => {
        if (LS.anchorEl) {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [handleClickOutside, LS.anchorEl]);

    const getTimeslots = React.useCallback(
        (
            YYYY_MM_DD_ARG = today_YYYY_MM_DD,
            earliestAvailableDate = p.earliestAvailableDate,
            findOne = false
        ): TimeSlot[] => {
            if (bizDateHandler) {
                const cacheKey = [
                    YYYY_MM_DD_ARG,
                    earliestAvailableDate,
                    p.immediateValue ? "IMMEDIATE" : "NON_IMMEDIATE",
                ].join("__");

                const cachedRes = timesCacheRef.current.get(cacheKey);
                if (cachedRes) {
                    return cachedRes;
                }

                const filtered = filterTimes({
                    bizDateHandler,
                    YYYY_MM_DD: YYYY_MM_DD_ARG,
                    earliestAvailableDate,
                    clientWillWait: p.immediateValue,
                    findOne,
                });
                if (!findOne) {
                    timesCacheRef.current.set(cacheKey, filtered);
                }
                return filtered;
            } else if (earliestAvailableDate) {
                return filterTimes({
                    YYYY_MM_DD: YYYY_MM_DD_ARG,
                    earliestAvailableDate,
                    findOne,
                });
            } else {
                const timeSlots = [] as TimeSlot[];

                for (const t of TIME_SLOTS) {
                    timeSlots.push({
                        time: t,
                        enabled: true,
                    });
                    if (findOne && timeSlots.length > 0) break;
                }
                return timeSlots;
            }
        },
        [
            today_YYYY_MM_DD,
            p.earliestAvailableDate,
            bizDateHandler,
            p.immediateValue,
        ]
    );

    const filteredTimeslots = React.useMemo(() => {
        const filteredTimes = getTimeslots();
        return filteredTimes;
    }, [getTimeslots]);

    const shouldDisableDateCb = React.useCallback(
        (date: Date) => {
            const shouldDisableCached = shouldDisableDateCacheRef.current.get(
                date.toISOString()
            );

            if (shouldDisableCached !== undefined) {
                return shouldDisableCached;
            }

            let res = false;
            const d = new Date(date);

            if (!date) {
                return true;
            } else {
                const thisDayYearMonthDay = d.toLocaleDateString(
                    window._COUNTRY_META.locale
                );

                const filteredTimes = getTimeslots(
                    thisDayYearMonthDay,
                    undefined,
                    true
                );

                const shouldDisable = !filteredTimes.some(
                    (timeslot) => timeslot.enabled
                );

                res = shouldDisable;
            }

            return res;
        },
        [getTimeslots]
    );

    const returnNullCb = React.useCallback(() => {
        return <></>;
    }, []);

    const onChangeCb = React.useCallback(
        (date) => {
            if (!date) return;

            onDateChange({
                YYYY_MM_DD: dateFormat(date),
            });
        },
        [onDateChange]
    );

    const setToEarliestAvlTime = React.useCallback(() => {
        const avlTimeslots = filteredTimeslots.filter(
            (timeslot) => timeslot.enabled
        );
        if (avlTimeslots.length > 0) {
            onDateChange({
                YYYY_MM_DD: today_YYYY_MM_DD,
                HH_MM: p.setEarliestMins ? avlTimeslots[0].time : undefined,
            });
        } else {
            const earliestAvailableDate = new Date(
                p.earliestAvailableDate &&
                p.earliestAvailableDate > new Date(today_YYYY_MM_DD)
                    ? p.earliestAvailableDate
                    : today_YYYY_MM_DD
            );

            let i = 0;
            while (i < 7) {
                const plusDay = i === 0 ? 0 : 1;
                earliestAvailableDate.setDate(
                    earliestAvailableDate.getDate() + plusDay
                );
                const newEarliestDateTimeslots = getTimeslots(
                    dateFormat(earliestAvailableDate),
                    p.earliestAvailableDate
                ).filter((timeslot) => timeslot.enabled);
                if (newEarliestDateTimeslots.length > 0) {
                    onDateChange({
                        YYYY_MM_DD: dateFormat(earliestAvailableDate),
                        HH_MM: p.setEarliestMins
                            ? newEarliestDateTimeslots[0].time
                            : undefined,
                    });
                    break;
                }
                i++;
            }
        }
    }, [
        filteredTimeslots,
        getTimeslots,
        today_YYYY_MM_DD,
        onDateChange,
        p.earliestAvailableDate,
        p.setEarliestMins,
    ]);

    React.useEffect(() => {
        if (p.fixedOpen) {
            setToEarliestAvlTime();
        }
    }, [p.earliestAvailableDate]); //eslint-disable-line react-hooks/exhaustive-deps

    const onContainerClick = React.useCallback(() => {
        if (p.fixedOpen) return;

        if (p.dateObj.YYYY_MM_DD) {
            const avlTimeslots = filteredTimeslots.filter(
                (timeslot) => timeslot.enabled
            );
            if (avlTimeslots.length > 0) return; // do nothing if there are avl timeslots
        }

        setToEarliestAvlTime();
    }, [
        setToEarliestAvlTime,
        p.fixedOpen,
        filteredTimeslots,
        p.dateObj.YYYY_MM_DD,
    ]);

    React.useEffect(() => {
        if (p.showImmediateCheckbox === false && immediateValChange) {
            immediateValChange(false);
        }
    }, [p.showImmediateCheckbox, immediateValChange]);

    const comp = React.useMemo(() => {
        return (
            <S.PopperChildrenWrapper $noTimeSelector={p.noTimeSelector}>
                {LS.isPending && (
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        bgcolor="rgba(255,255,255, 0.5)"
                        width={1}
                        height={1}
                        zIndex={10}
                    >
                        <CircularProgress
                            sx={{
                                top: "calc(50% - 40px)",
                                left: "calc(50% - 40px)",
                                transform: "translate(-50%, -50%)",
                                position: "absolute",
                            }}
                        />
                    </Box>
                )}
                <S.SelectWrapper>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={pickByCountry(window._COUNTRY, {
                            [Country.LT]: ltLocale,
                            [Country.CA]: caLocale,
                        })}
                    >
                        <StaticDatePicker
                            minDate={p.earliestAvailableDate}
                            maxDate={MAX_DATE_1_YEAR_FROM_NOW}
                            views={views}
                            renderInput={returnNullCb}
                            displayStaticWrapperAs={displayStatic}
                            shouldDisableDate={shouldDisableDateCb}
                            disablePast={p.disablePast}
                            value={p.dateObj.YYYY_MM_DD}
                            onChange={onChangeCb}
                        />
                    </LocalizationProvider>
                    {p.showImmediateCheckbox && (
                        <Box pl={1}>
                            <FormControlLabel
                                sx={{ mt: 1 }}
                                label={
                                    <Text fontSize={14} sx={{ opacity: 1 }}>
                                        <TransMsg
                                            default={
                                                "Norėsiu laukti, kol darbai bus atlikti ir iškarto atsiimti automobilį"
                                            }
                                            id="TUBq5y0u"
                                        />
                                    </Text>
                                }
                                control={
                                    <Checkbox
                                        checked={p.immediateValue}
                                        onChange={(e) => {
                                            immediateValChange?.(
                                                e.target.checked
                                            );
                                        }}
                                    />
                                }
                            />
                        </Box>
                    )}
                </S.SelectWrapper>
                {!p.noTimeSelector && (
                    <S.TimeSelectWrapper>
                        <S.ListWrapper>
                            <S.TimesListContainer>
                                {filteredTimeslots.map((timeslot) => (
                                    <S.ListItem
                                        $active={
                                            p.dateObj.HH_MM === timeslot.time
                                        }
                                        $disabled={!timeslot.enabled}
                                        onClick={() => {
                                            if (!timeslot.enabled) return;

                                            onDateChange({
                                                HH_MM: timeslot.time,
                                            });
                                            onClose();
                                        }}
                                        key={timeslot.time}
                                    >
                                        {timeslot.time}
                                    </S.ListItem>
                                ))}
                            </S.TimesListContainer>
                        </S.ListWrapper>
                    </S.TimeSelectWrapper>
                )}
            </S.PopperChildrenWrapper>
        );
    }, [
        onDateChange,
        p.disablePast,
        p.earliestAvailableDate,
        filteredTimeslots,
        shouldDisableDateCb,
        returnNullCb,
        onChangeCb,
        p.noTimeSelector,
        onClose,
        LS.isPending,
        p.dateObj,
        p.immediateValue,
        p.showImmediateCheckbox,
        immediateValChange,
    ]);

    return (
        <S.DatePickerContainer
            $fixedOpen={p.fixedOpen}
            sx={p.sx}
            className={DATE_PICKER_CLASSNAME_IDENTIFIER}
            ref={datePickerRef}
        >
            <S.DatePickerInputWrapper
                ref={datePickerWrapperRef}
                onClick={() => {
                    onContainerClick();
                    runInAction(() => {
                        LS.anchorEl = datePickerWrapperRef.current;
                    });
                }}
            >
                <S.DatePickerInput
                    $hide={p.hideValueInput}
                    $active={!!LS.anchorEl}
                    leftIconClass="icon-clock"
                    placeholder={
                        p.placeholder ||
                        transStr("Data ir laikas", { id: "yyJmyigp" })
                    }
                    value={formattedTimeString}
                    forceError={p.forceInputError}
                    error={isError}
                    helperText={
                        p.errorsEnabled && isError
                            ? transStr("Pasirinkite laiką", { id: "SSiS5Wtn" })
                            : undefined
                    }
                    rightIcon={
                        !p.notClearable && formattedTimeString ? (
                            <S.CloseCrossIconButton
                                aria-label="clear date picker"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    runInAction(() => {
                                        p.onDateChange({
                                            YYYY_MM_DD: undefined,
                                            HH_MM: undefined,
                                        });
                                        p.onClose && p.onClose();
                                    });
                                }}
                                edge="end"
                            >
                                <i className="icon-cross" />
                            </S.CloseCrossIconButton>
                        ) : undefined
                    }
                />
            </S.DatePickerInputWrapper>
            <S.DateExpansivePopper
                className={DATE_PICKER_CLASSNAME_IDENTIFIER}
                togglePageScroll={p.togglePageScroll}
                onCloseClick={() => {
                    LS.onClose();
                }}
                canFinalizeChoice={() => {
                    if (p.errorsEnabled && !p.dateObj.HH_MM) {
                        showToast.warn(
                            transStr("Pasirinkite laiką", { id: "3qc64rON" })
                        );
                        return false;
                    }
                    return true;
                }}
                popperProps={{
                    open: !!LS.anchorEl,
                    anchorEl: LS.anchorEl,
                    disablePortal: p.disablePortal,
                    children: comp,
                }}
            />
        </S.DatePickerContainer>
    );
});

export default DatePicker;
