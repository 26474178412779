import getServiceDurationMins from "../../Shared/util/getServiceDurationMins";
import getServicePriceObj from "./getServicePriceObj";
import {
    PriceObj,
    ServiceDataForWorkshopPriceNDuration,
} from "@Shared/types/types";

// always use `getServicesPriceNDuration` instead of this function. Leave this to be used only by the `getServicesPriceNDuration` itself.
export default function getServicePriceNDuration(
    service: ServiceDataForWorkshopPriceNDuration,
    hourCost: number
): {
    priceObj: PriceObj;
    durationMins: number;
} {
    const priceObj = getServicePriceObj(service, hourCost);
    const durationMins = getServiceDurationMins(service);

    return {
        // if `getServicePrice` doesnt return anyhing, but durationMins does, just set the price according to the durationMins
        priceObj,
        durationMins,
    };
}
