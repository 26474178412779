import React from "react";
import * as S from "./BecomePartner.styled";
import * as IFS from "../../commonComps/InfoPage/InfoPage.styled";
import { ABOVE_WORKSHOPS_COUNT } from "@FEClient/consts/consts";
import Text from "@FEShared/components/UI/Text/Text";
import Button from "@FEShared/components/UI/Button/Button";
import WorkshopLogos from "./WorkshopLogos/WorkshopLogos";
import Box from "@FEShared/components/UI/Box/Box";
import ContentContainer from "@FEClient/views/commonComps/ContentContainer/ContentContainer";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import { useMediaQuery } from "@mui/material";
import MOBILE_LANDING_IMG from "./assets-new/mobile-landing.png";
import YELLOW_COLOR_SPLASH_IMG from "./assets-new/desktop-yellow.webp";
import RED_COLOR_SPLASH from "./assets-new/desktop-red.webp";
import BLUE_COLOR_SPLASH from "./assets-new/desktop-blue.webp";
import MOBILE_RED_IMG from "./assets-new/mobile-red.webp";
import MOBILE_BLUE_IMG from "./assets-new/mobile-blue.webp";
import MOBILE_YELLOW_IMG from "./assets-new/mobile-yellow.webp";
import CIRCLE_IMG from "./assets-new/top-blue-splash.png";
import SPLASH_IMG from "./assets-new/devices.webp";
import prodRealUsersOnly from "@FEClient/logic/utils/prodRealUsersOnly";
import showToast from "@FEShared/utils/showToast";
import PartnerRecomendationCards from "@FEClient/views/commonComps/PartnerRecomendationCards/PartnerRecomendationCards";
import { TransMsg, transStr } from "@FEShared/i18n";
import pickByLangCode from "@Shared/util/pickByLangCode";
import LangCode from "@Shared/types/enums/LangCode";

const SUCCESS_REDIRECT_QUERY_PARAM = "?success";

const BecomePartner: React.FC = () => {
    // const theme = useTheme();
    const formRef = React.useRef<HTMLDivElement>(null);
    const onBtnClick = () => {
        formRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
        } as any);
    };
    const isMobile = useIsMobile();
    const isBelowBreakpoint = useMediaQuery(
        `(max-width:${S.TOP_LANDING_BREAKPOINT}px)`
    );

    React.useEffect(() => {
        if (window.location.search.includes(SUCCESS_REDIRECT_QUERY_PARAM)) {
            showToast.success(
                transStr(
                    "Jūsų duomenis sėkmingai gavome. Vienos darbo dienos bėgyje su jumis susisieks {domain} atstovas.",
                    {
                        id: "00SL4lWN",
                        data: {
                            domain: window._COUNTRY_META.domain,
                        },
                    }
                )
            );
            prodRealUsersOnly(() => {
                window.gtag?.("event", "become_partner");
                window.fbq?.("track", "become_partner");
                window.fbq?.("track", "SubmitApplication");
                window.hj?.("event", "become_partner");
            });
        } else {
            prodRealUsersOnly(() => {
                window.gtag?.("event", "become_partner_landing");
                window.fbq?.("track", "become_partner_landing");
                window.fbq?.("track", "ViewContent");
                window.hj?.("event", "become_partner_landing");
            });
        }
    }, []);

    return (
        <S.BecomePartnerContainer>
            <S.TopBanner>
                <ContentContainer>
                    <S.Left>
                        <S.Title>
                            <TransMsg
                                default={"Nemokama autoserviso programa"}
                                id="vsFXEupL"
                            />
                        </S.Title>
                        <S.Descr>
                            <TransMsg
                                default={
                                    "Klientai mygtuko paspaudimu. Automatizuok savo verslą, taupyk laiką ir pinigus."
                                }
                                id="dnbt67is"
                            />
                        </S.Descr>
                        {isBelowBreakpoint && (
                            <>
                                <S.MobileLandingImg src={MOBILE_LANDING_IMG} />
                            </>
                        )}
                        <S.FeaturesWrapper>
                            <S.FeatureBox>
                                <S.FeatureTitle>24/7</S.FeatureTitle>
                                <S.FeatureDescr>
                                    <TransMsg
                                        default={"Registracijos internetu"}
                                        id="je1brwf3"
                                    />
                                </S.FeatureDescr>
                            </S.FeatureBox>
                            <S.FeatureBox>
                                <S.FeatureTitle>100%</S.FeatureTitle>
                                <S.FeatureDescr>
                                    <TransMsg
                                        default={
                                            "Surenkami visų klientų atsiliepimai"
                                        }
                                        id="1WHoPFuT"
                                    />
                                </S.FeatureDescr>
                            </S.FeatureBox>
                            <S.FeatureBox>
                                <S.FeatureTitle>65%</S.FeatureTitle>
                                <S.FeatureDescr>
                                    <TransMsg
                                        default={"Mažiau klientų nepasirodymų"}
                                        id="2JldYILt"
                                    />
                                </S.FeatureDescr>
                            </S.FeatureBox>
                        </S.FeaturesWrapper>
                        <S.BecomePartnerBtn
                            color="tertiary"
                            onClick={onBtnClick}
                        >
                            <TransMsg
                                default={"Tapk partneriu nemokamai"}
                                id="1K5OOMNw"
                            />
                        </S.BecomePartnerBtn>
                    </S.Left>
                </ContentContainer>
                {!isBelowBreakpoint && (
                    <>
                        <S.CircleImg src={CIRCLE_IMG} style={{ zIndex: 0 }} />
                        <S.RightImg src={SPLASH_IMG} />
                    </>
                )}
            </S.TopBanner>
            <ContentContainer
                sx={(theme) => ({
                    pt: "64px",
                    pb: "64px",
                    [theme.breakpoints.down("md")]: {
                        pt: "48px",
                        pb: "48px",
                    },
                })}
            >
                <PartnerRecomendationCards />
            </ContentContainer>
            <IFS.GreySection
                style={{ marginTop: 0, paddingTop: 48, paddingBottom: 48 }}
            >
                <S.SectionTitle style={{ marginTop: 0, fontSize: 24 }}>
                    <TransMsg
                        default={
                            "Mumis pasitiki jau daugiau nei {Text <{ABOVE_WORKSHOPS_COUNT}+ autoservisų>} Lietuvoje"
                        }
                        data={{
                            ABOVE_WORKSHOPS_COUNT,
                            Text: (body) => (
                                <Text
                                    color="primary"
                                    component="span"
                                    fontSize="inherit"
                                    fontWeight="inherit"
                                >
                                    {body}
                                </Text>
                            ),
                        }}
                        id="f4U3RKnz"
                    />
                </S.SectionTitle>
                <WorkshopLogos />
                <Box mt={4}>
                    <Button
                        color="tertiary"
                        onClick={onBtnClick}
                        sx={{ margin: "0 auto", maxWidth: "273px", width: 1 }}
                    >
                        <TransMsg
                            default={"Tapk partneriu nemokamai"}
                            id="Rd5i9jUB"
                        />
                    </Button>
                </Box>
            </IFS.GreySection>
            <S.VideoSection>
                <S.VideoTitle>
                    <TransMsg
                        default={"Kliento kelionė į Tavo autoservisą:"}
                        id="VRBSYhLB"
                    />
                </S.VideoTitle>
                <S.VideoIframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/YE0Wu90fUCo"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                />
            </S.VideoSection>
            <S.ColorSection $color="#EEA600">
                <ContentContainer
                    sx={(theme) => ({
                        [theme.breakpoints.down("md")]: {
                            pt: "32px",
                        },
                    })}
                >
                    <S.SectionContentWrapper $type="left">
                        <S.SectionTitle>
                            <TransMsg
                                default={
                                    "{SectionTitleEmphasis <Užsakymai>} vieno mygtuko paspaudimu"
                                }
                                data={{
                                    SectionTitleEmphasis: (body) => (
                                        <S.SectionTitleEmphasis>
                                            {body}
                                        </S.SectionTitleEmphasis>
                                    ),
                                }}
                                id="zNMaQT0i"
                            />
                        </S.SectionTitle>
                        {isMobile && (
                            <>
                                <S.PercentageBubble>
                                    <TransMsg
                                        default={
                                            "46% registracijų į autoservisus vyksta po darbo valandų*"
                                        }
                                        id="bJV5ouSx"
                                    />
                                    <Text
                                        mt={1}
                                        fontSize={14}
                                        color={"rgba(255,255,255,0.75)"}
                                    >
                                        <TransMsg
                                            default={
                                                "*remiantis mūsų užsakymų statistika"
                                            }
                                            id="fA6iSL5v"
                                        />
                                    </Text>
                                </S.PercentageBubble>
                                <S.ColorSectionMobileImg
                                    src={MOBILE_YELLOW_IMG}
                                />
                            </>
                        )}
                        <S.SectionDescr>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                <TransMsg
                                    default={
                                        "Nemokami automatiniai SMS priminimai klientams"
                                    }
                                    id="YiZkBv7r"
                                />
                            </S.SectionFeature>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                <TransMsg
                                    default={
                                        "Galimybė registruotis į autoservisą 24/7 internetu"
                                    }
                                    id="giQEHYKu"
                                />
                            </S.SectionFeature>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                <TransMsg
                                    default={
                                        "Nauji klientai mygtuko paspaudimu"
                                    }
                                    id="u4p2E8M7"
                                />
                            </S.SectionFeature>
                            <Button onClick={onBtnClick}>
                                <TransMsg
                                    default={"Tapk partneriu nemokamai"}
                                    id="R7cJRxac"
                                />
                            </Button>
                        </S.SectionDescr>
                    </S.SectionContentWrapper>
                </ContentContainer>
                {!isMobile && (
                    <S.ColorSplash
                        $multiplier={0.3}
                        $type="right"
                        $bgImgUrl={YELLOW_COLOR_SPLASH_IMG}
                    />
                )}
            </S.ColorSection>
            <S.ColorSection $color="#FF7678">
                <ContentContainer>
                    <S.SectionContentWrapper $type="right">
                        <S.SectionTitle>
                            <TransMsg
                                default={
                                    "Modernus {S.SectionTitleEmphasis <autoserviso puslapis>}"
                                }
                                data={{
                                    SectionTitleEmphasis: (body) => (
                                        <S.SectionTitleEmphasis>
                                            {body}
                                        </S.SectionTitleEmphasis>
                                    ),
                                }}
                                id="VFjIS8TV"
                            />
                        </S.SectionTitle>
                        {isMobile && (
                            <S.ColorSectionMobileImg src={MOBILE_RED_IMG} />
                        )}
                        <S.SectionDescr>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                <TransMsg
                                    default={
                                        "Automatiškai surenkami atsiliepimai iš 100% klientų"
                                    }
                                    id="5JJudKwZ"
                                />
                            </S.SectionFeature>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                <TransMsg
                                    default={"Patvirtinti atsiliepimai"}
                                    id="QHeicyER"
                                />
                            </S.SectionFeature>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                <TransMsg
                                    default={
                                        "Paprastai keičiama puslapio informacija"
                                    }
                                    id="zsM4v1dN"
                                />
                            </S.SectionFeature>
                            <Button onClick={onBtnClick}>
                                <TransMsg
                                    default={"Tapk partneriu nemokamai"}
                                    id="kfT2EEvY"
                                />
                            </Button>
                        </S.SectionDescr>
                    </S.SectionContentWrapper>
                </ContentContainer>

                {!isMobile && (
                    <S.ColorSplash
                        $type="left"
                        $bgImgUrl={RED_COLOR_SPLASH}
                        $multiplier={0.65}
                    />
                )}
            </S.ColorSection>
            <S.ColorSection $color="#5E39FE">
                {/* <S.GreySectionImg /> */}
                <ContentContainer>
                    <S.SectionContentWrapper $type="left">
                        <S.SectionTitle>
                            <TransMsg
                                default={
                                    "Išmani autoserviso {S.SectionTitleEmphasis <valdymo sistema>}"
                                }
                                data={{
                                    SectionTitleEmphasis: (body) => (
                                        <S.SectionTitleEmphasis>
                                            {body}
                                        </S.SectionTitleEmphasis>
                                    ),
                                }}
                                id="INZlubEy"
                            />
                        </S.SectionTitle>
                        {isMobile && (
                            <S.ColorSectionMobileImg src={MOBILE_BLUE_IMG} />
                        )}
                        <S.SectionDescr>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                <TransMsg
                                    default={"Skaitmeninis kalendorius"}
                                    id="bkDmcKQx"
                                />
                            </S.SectionFeature>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                <TransMsg
                                    default={
                                        "Sąskaitos, užsakymų istorija, užsakymo paskyros, atvykimo formos"
                                    }
                                    id="4EX2dKCC"
                                />
                            </S.SectionFeature>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                <TransMsg
                                    default={"Dalių sandėlis"}
                                    id="IBBtU5C1"
                                />
                            </S.SectionFeature>
                            <Button onClick={onBtnClick}>
                                <TransMsg
                                    default={"Tapk partneriu nemokamai"}
                                    id="hg4jrNuR"
                                />
                            </Button>
                        </S.SectionDescr>
                    </S.SectionContentWrapper>
                </ContentContainer>

                {!isMobile && (
                    <S.ColorSplash
                        $type="right"
                        // $multiplier={isBelowBreakpoint ? 0.4 : 0.2}
                        $multiplier={isBelowBreakpoint ? 0.4 : 0.2}
                        $bgImgUrl={BLUE_COLOR_SPLASH}
                    />
                )}
            </S.ColorSection>
            <S.BottomSection>
                <S.FormWrapper ref={formRef}>
                    <S.FormTitle>
                        <TransMsg
                            default={
                                "Nori {S.EmphasisText <pritraukti daugiau klientų>} ir modernizuoti savo verslą? Registruokis ir tapk partneriu {S.EmphasisText <nemokamai.>}"
                            }
                            data={{
                                EmphasisText: (body) => (
                                    <S.EmphasisText>{body}</S.EmphasisText>
                                ),
                            }}
                            id="AO62QNBK"
                        />
                    </S.FormTitle>
                    <S.FormDescr>
                        <TransMsg
                            default={
                                "Įvesk savo informaciją ir su tavimi per 2d.d. susisieks mūsų komandos narys."
                            }
                            id="YyuZg5Rs"
                        />
                    </S.FormDescr>
                    <S.FormIframeWrapper>
                        <S.FormIframe
                            src={pickByLangCode(window._COUNTRY_META.langCode, {
                                [LangCode.LT]:
                                    "https://tally.so/r/wzjYM8?transparentBackground=1",
                                [LangCode.EN]:
                                    "https://tally.so/r/3xy0bG?transparentBackground=1",
                            })}
                            scrolling="no"
                        />
                    </S.FormIframeWrapper>
                </S.FormWrapper>
            </S.BottomSection>
        </S.BecomePartnerContainer>
    );
};

export default BecomePartner;
