export enum TranslatableCustomID_PartCategories {
    "MAINTENANCE" = "PC_MAINTENANCE",
    "ELECTRICITY" = "PC_ELECTRICITY",
    "EXHAUST_SYSTEM" = "PC_EXHAUST_SYSTEM",
    "CONDITIONER" = "PC_CONDITIONER",
    "WHEELS" = "PC_WHEELS",
    "INTERIOR" = "PC_INTERIOR",
    "ENGINE" = "PC_ENGINE",
    "BRAKES" = "PC_BRAKES",
    "GLASS" = "PC_GLASS",
    "BODY" = "PC_BODY",
    "SUSPENSION" = "PC_SUSPENSION",
    "DETAILING" = "PC_DETAILING",
    "GEARBOX" = "PC_GEARBOX",
    "INSPECTION" = "PC_INSPECTION",
    "STEERING" = "PC_STEERING",
    "HEADLIGHTS" = "PC_HEADLIGHTS",
    "POPULAR" = "PC_POPULAR",
    "OTHER" = "PC_OTHER",
}

export enum TranslatableCustomID_PopularServices {
    "OIL_CHANGE" = "PS_OIL_CHANGE",
    "SUSPENSION_REPAIR" = "PS_SUSPENSION_REPAIR",
    "UNKNOWN_PROBLEM" = "PS_UNKNOWN_PROBLEM",
    "FRONT_BRAKE_PADS_REPLACEMENT" = "PS_FRONT_BRAKE_PADS_REPLACEMENT",
    "REAR_BRAKE_PADS_REPLACEMENT" = "PS_REAR_BRAKE_PADS_REPLACEMENT",
    "TECHNICAL_INSPECTION" = "PS_TECHNICAL_INSPECTION",
    "CONDITIONER_REPAIR" = "PS_CONDITIONER_REPAIR",
    "CONDITIONER_REFILL_DONT_KNOW_FREON_TYPE" = "PS_CONDITIONER_REFILL_DONT_KNOW_FREON_TYPE",
    "AUTOMATIC_GEARBOX_OIL_CHANGE" = "PS_AUTOMATIC_GEARBOX_OIL_CHANGE",
    "LIGHTS_REPAIR" = "PS_LIGHTS_REPAIR",
    "ENGINE_DIAGNOSTICS" = "PS_ENGINE_DIAGNOSTICS",
    "ENGINE_CHAIN_REPLACEMENT" = "PS_ENGINE_CHAIN_REPLACEMENT",
}

export enum TranslatableCustomID_SubCategories {
    "OIL_CHANGE_AND_FILTERS" = "SC_OIL_CHANGE_AND_FILTERS",
    "TIMING_BELT_AND_CHAIN" = "SC_TIMING_BELT_AND_CHAIN",
    "DIAGNOSTICS" = "SC_DIAGNOSTICS",
    "ELECTRICAL_REPAIR_AUTO_ELECTRICIAN" = "SC_ELECTRICAL_REPAIR_AUTO_ELECTRICIAN",
    "PARK_ASSIST" = "SC_PARK_ASSIST",
    "CAR_ALARM_KEYS_LOCKS" = "SC_CAR_ALARM_KEYS_LOCKS",
    "AUDIO_VIDEO_SYSTEMS" = "SC_AUDIO_VIDEO_SYSTEMS",
    "GPS_SYSTEM" = "SC_GPS_SYSTEM",
    "COMFORT_SYSTEMS" = "SC_COMFORT_SYSTEMS",
    "CAMERAS" = "SC_CAMERAS",
    "EXHAUST_REPAIR" = "SC_EXHAUST_REPAIR",
    "EXHAUST_DIAGNOSTICS" = "SC_EXHAUST_DIAGNOSTICS",
    "EMISSIONS_TESTS" = "SC_EMISSIONS_TESTS",
    "AC_RECHARGE" = "SC_AC_RECHARGE",
    "HVAC_REPAIR" = "SC_HVAC_REPAIR",
    "COATING_POLISHING_BLASTING" = "SC_COATING_POLISHING_BLASTING",
    "PAINTING" = "SC_PAINTING",
    "BODY_REPAIR" = "SC_BODY_REPAIR",
    "CHASSIS_STRAIGHTENING" = "SC_CHASSIS_STRAIGHTENING",
    "OTHER" = "SC_OTHER",
    "CLUTCH" = "SC_CLUTCH",
    "TRANSMISSION_FLUID_CHANGE" = "SC_TRANSMISSION_FLUID_CHANGE",
    "TRANSMISSION_REPAIR" = "SC_TRANSMISSION_REPAIR",
    "GEARBOX_REPAIR" = "SC_GEARBOX_REPAIR",
    "TIRE_CHANGE" = "SC_TIRE_CHANGE",
    "RIMS" = "SC_RIMS",
    "AIRBAG_SRS" = "SC_AIRBAG_SRS",
    "CAR_SEATS" = "SC_CAR_SEATS",
    "UPHOLSTERY" = "SC_UPHOLSTERY",
    "BRAKE_REPAIR" = "SC_BRAKE_REPAIR",
    "REPAIR" = "SC_REPAIR",
    "REPLACEMENT" = "SC_REPLACEMENT",
    "TINTING" = "SC_TINTING",
    "SUSPENSION_REPAIR" = "SC_SUSPENSION_REPAIR",
    "SHOCKS" = "SC_SHOCKS",
    "ENGINE_REPAIR" = "SC_ENGINE_REPAIR",
    "FUEL_SYSTEM" = "SC_FUEL_SYSTEM",
    "FUEL_INJECTORS" = "SC_FUEL_INJECTORS",
    "TURBO" = "SC_TURBO",
    "COOLING_SYSTEM" = "SC_COOLING_SYSTEM",
    "SPARK_PLUGS" = "SC_SPARK_PLUGS",
    "LPG" = "SC_LPG",
    "SUPERCHARGER" = "SC_SUPERCHARGER",
    "FUEL_TANK" = "SC_FUEL_TANK",
    "INTERIOR_DETAILING" = "SC_INTERIOR_DETAILING",
    "EXTERIOR_DETAILING" = "SC_EXTERIOR_DETAILING",
    "SMOKINESS" = "SC_SMOKINESS",
    "RADIO_EQUIPMENT" = "SC_RADIO_EQUIPMENT",
}
