import { SeoPageData } from "@FEShared/types/common";
import Country from "@Shared/types/enums/Country";
import { countryClientPageToMeta } from "@Shared/util/clientPagesMeta";

const clientPageToMeta = countryClientPageToMeta(window._COUNTRY);

export default function genDynamicHomeMeta(p: { seoPageData?: SeoPageData }): {
    title: string;
    descr: string;
} {
    if (window._COUNTRY === Country.LT) {
        return {
            title: p.seoPageData
                ? `${p.seoPageData.seoServiceName} - Kainos, 24/7 Registracija, 100% Garantija`
                : clientPageToMeta.HOME.title,
            descr: p.seoPageData
                ? `${p.seoPageData.seoServiceName} - Kainos, 24/7 Registracija, 100% Garantija. Lygink autoservisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus`
                : clientPageToMeta.HOME.description,
        };
    } else {
        return {
            title: p.seoPageData
                ? `${p.seoPageData.seoServiceName} - Prices, Book 24/7, 100% Warranty`
                : clientPageToMeta.HOME.title,
            descr: p.seoPageData
                ? `${p.seoPageData.seoServiceName} - Prices, Book 24/7, 100% Warranty. Compare mechanics in Toronto & Montreal by price, availability, verified reviews`
                : clientPageToMeta.HOME.description,
        };
    }
}
