import urlifyString from "@FEShared/utils/urlifyString";
import lReduce from "lodash/reduce";
import FECLIENT_DISTRICT_SEO_PAGES from "@Shared/consts/FECLIENT_DISTRICT_SEO_PAGES";
import FECLIENT_BRANDS_SEO_PAGES from "@Shared/consts/FECLIENT_BRANDS_SEO_PAGES";
import FECLIENT_ALL_SERVICES_SEO_PAGES, {
    MOST_POPULAR_SERVICES_LANDINGS,
} from "@Shared/consts/FECLIENT_ALL_SERVICES_SEO_PAGES";
import { CountryPageMeta, PageMeta } from "@Shared/types/types";
import QUERY_PARAMS from "@Shared/consts/QUERY_PARAMS";
import Country from "@Shared/types/enums/Country";
import lMapValues from "lodash/mapValues";
import { GqlCountry } from "@FEShared/graphql/generated/graphql";
import { Translatable } from "@Shared/i18n/i18n.types";

// The name of the enum should match the name of the page file in CamelCase
export enum ClientPage {
    HOME = "HOME",
    PARTNERS = "PARTNERS",
    SEARCH = "SEARCH",
    /** Page where client can choose an alternative service to his initially selected service */
    ALTERNATIVES_SEARCH = "ALTERNATIVES_SEARCH",
    /**Page where client can review service reviews, work hours */
    WORKSHOP = "WORKSHOP",
    /** Same as RESERVATION_CONFIRMATION just user is redirected to this page after ALTERNATIVES_SEARCH */
    ALTERNATIVE_RESERVATION_CONFIRMATION = "ALTERNATIVE_RESERVATION_CONFIRMATION",
    /** Page where client needs to do final confirmation of all of his selections */
    RESERVATION_CONFIRM = "RESERVATION_CONFIRM",
    /** Page where client sees that his reservation is successful */
    RESERVATION_SUCCESS = "RESERVATION_SUCCESS",
    INTERNAL_ORDER_REVIEW = "INTERNAL_ORDER_REVIEW",
    ABOUT_US = "ABOUT_US",
    PRIVACY_POLICY = "PRIVACY_POLICY",
    TERMS_OF_SERVICE = "TERMS_OF_SERVICE",
    SEO_SERVICES = "SEO_SERVICES",
    BECOME_PARTNER = "BECOME_PARTNER",
    /* The page that describes all our warranty rules and information. */
    WARRANTY = "WARRANTY",
    /* This page is only intended to serve a redirect to workshops */
    CLIENT_ONLY = "CLIENT_ONLY",
    // the only difference between WARRANTY_N_REVIEW And this, is just the title of the page for better conversion of warranty acitvation/review leaving.
    WARRANTY_ACTIVATION = "WARRANTY_ACTIVATION",
    WARRANTY_N_REVIEW = "WARRANTY_N_REVIEW",
    /* SEO Landing page for specific service */
    SEO_PAGES = "SEO_PAGES",
    SITEMAP = "SITEMAP",
    ADMIN_PROMO = "ADMIN_PROMO",
}

// recommendations: 60 chars for title, 160 for description.
// FUTURE IDEA: Can hold header/footer options inside this object;
const clientPageToMeta: {
    [k in ClientPage]: PageMeta;
} = {
    HOME: {
        indexable: true,
        url: "/",
        title: {
            [Country.CA]:
                "Book TOP Mechanics Near You 24/7 | Prices, 100% Warranty",
            [Country.LT]: "Registruokis į autoservisą internetu 24/7",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews",
            [Country.LT]:
                "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus",
        },
    },
    SEO_PAGES: {
        indexable: false,
        url: "/",
        genI18nPath: (translate, country) =>
            Object.keys(MOST_POPULAR_SERVICES_LANDINGS(country))
                .concat(
                    Object.keys(FECLIENT_DISTRICT_SEO_PAGES(translate, country))
                )
                .concat(
                    Object.keys(FECLIENT_BRANDS_SEO_PAGES(translate, country))
                )
                .concat(
                    Object.keys(
                        FECLIENT_ALL_SERVICES_SEO_PAGES(translate, country)
                    )
                ),
        title: {
            [Country.CA]:
                "Book TOP Mechanics Near You 24/7 | Prices, 100% Warranty",
            [Country.LT]: "Registruokis į autoservisą internetu 24/7",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "24/7 registracija. Darbų kainos. 100% Garantija. Lygink servisus aplink tave, skaityk verifikuotus atsiliepimus ir užsiregistruok 24/7.",
        },
    },
    SEARCH: {
        indexable: false,
        url: {
            [Country.CA]: "/mechanics-near-me",
            [Country.LT]: "/autoservisai",
        },
        path: {
            [Country.CA]: "/mechanics-near-me/:params?",
            [Country.LT]: "/autoservisai/:params?",
        },
        title: {
            [Country.CA]: "TOP 20 Mechanics | Prices, Book 24/7, 100% Warranty",
            [Country.LT]: "TOP 20 Autoservisai | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        },
        notExactMatch: true,
    },
    WORKSHOP: {
        indexable: false,
        url: { [Country.CA]: "/mechanic", [Country.LT]: "/autoservisas" },
        path: {
            [Country.CA]: "/mechanic/:workshopName",
            [Country.LT]: "/autoservisas/:workshopName",
        },
        title: {
            [Country.CA]: "Book auto repair mechanic near you 24/7",
            [Country.LT]:
                "Išsirink autoservisą patogiai, greitai, paprastai | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        },
        notSticky: true,
    },
    RESERVATION_CONFIRM: {
        indexable: false,
        url: {
            [Country.CA]: "/booking-confirmation",
            [Country.LT]: "/rezervacijos-patvirtinimas",
        },
        title: {
            [Country.CA]: "Booking confirmation",
            [Country.LT]:
                "Užsirašyk į autoservisą internetu 24/7 | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        },
        hideNavBar: true,
        unclickableLogo: true,
        notSticky: true,
    },
    RESERVATION_SUCCESS: {
        indexable: false,
        url: {
            [Country.CA]: "/booking-successful",
            [Country.LT]: "/rezervacija-sekminga",
        },
        shortUrl: "/r",
        path: {
            [Country.CA]: [`/booking-successful/:orderId`, `/r/:orderId`],
            [Country.LT]: [`/rezervacija-sekminga/:orderId`, `/r/:orderId`],
        },
        title: {
            [Country.CA]: "Booking successful",
            [Country.LT]:
                "Užsirašyk į autoservisą internetu 24/7 | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        },
    },
    ALTERNATIVES_SEARCH: {
        indexable: false,
        url: { [Country.CA]: "/p", [Country.LT]: "/p" },
        path: {
            [Country.CA]: "/p/:orderId",
            [Country.LT]: "/p/:orderId",
        },
        title: {
            [Country.CA]: "Alternative auto repair shops",
            [Country.LT]:
                "Užsirašyk į autoservisą internetu 24/7 | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        },
        hideNavBar: true,
    },
    ALTERNATIVE_RESERVATION_CONFIRMATION: {
        indexable: false,
        url: {
            [Country.CA]: "/new-workshop-confirmation",
            [Country.LT]: "/naujo-autoserviso-patvirtinimas",
        },
        title: {
            [Country.CA]: "New workshop confirmation",
            [Country.LT]: "Naujo autoserviso patvirtinimas | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        },
        hideNavBar: true,
    },
    ABOUT_US: {
        indexable: true,
        url: { [Country.CA]: "/about-us", [Country.LT]: "/apie-mus" },
        title: {
            [Country.CA]: "About us",
            [Country.LT]: "Apie mus | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Norime sutaupyti jūsų laiką ir pinigus atliekant automobilio remonto darbus ar aptarnavimus. Apie nevaziuoja.lt",
        },
    },
    PRIVACY_POLICY: {
        indexable: false,
        url: {
            [Country.CA]: "/privacy-policy",
            [Country.LT]: "/privatumo-politika",
        },
        title: {
            [Country.CA]: "Privacy policy",
            [Country.LT]: "Privatumo politika | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]: "Slapukų ir privatumo politika.",
        },
    },
    TERMS_OF_SERVICE: {
        indexable: false,
        url: {
            [Country.CA]: "/terms-of-service",
            [Country.LT]: "/paslaugu-teikimo-salygos",
        },
        title: {
            [Country.CA]: "Terms of Service | repairpunk.com",
            [Country.LT]: "Paslaugų teikimo sąlygos | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Paslaugų teikimo sąlygos ir kita teisinė informacija.",
        },
    },
    SEO_SERVICES: {
        indexable: true,
        url: {
            [Country.CA]: "/auto-mechanic-services",
            [Country.LT]: "/automobiliu-serviso-paslaugos",
        },
        title: {
            [Country.CA]: "All auto mechanic & maintenance services",
            [Country.LT]:
                "Visos automobilių remonto ir aptarnavimų paslaugos | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        },
    },
    /** Used for internal orders */
    INTERNAL_ORDER_REVIEW: {
        indexable: false,
        url: { [Country.CA]: "/review", [Country.LT]: "/atsiliepimas" },
        path: {
            [Country.CA]: ["/review/:orderId", "/at/:orderId"],
            [Country.LT]: ["/atsiliepimas/:orderId", "/at/:orderId"],
        },
        title: {
            [Country.CA]: "Review | repairpunk.com",
            [Country.LT]: "Atsiliepimas | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        },
        shortUrl: "/at",
        hideNavBar: true,
    },
    BECOME_PARTNER: {
        indexable: true,
        url: { [Country.CA]: "/become-partner", [Country.LT]: "/partneriai" },
        title: {
            [Country.CA]: "Free auto repair shop software | New clients",
            [Country.LT]: "Nemokama autoserviso programa | Nauji klientai",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Nauji klientai mygtuko paspaudimu, autoserviso puslapis, sąskaitų išrašymas, SMS priminimai, užsakymų formavimas ir dar daugiau!",
        },
    },
    WARRANTY: {
        indexable: true,
        url: {
            [Country.CA]: "/auto-mechanic-warranty",
            [Country.LT]: "/remonto-garantija",
        },
        title: {
            [Country.CA]: "Auto Mechanic Repair Warranty | repairpunk.com",
            [Country.LT]: "Automobilių remonto garantija | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        },
    },
    CLIENT_ONLY: {
        indexable: false,
        url: { [Country.CA]: "/clients-only", [Country.LT]: "/tik-klientui" },
        title: {
            [Country.CA]: "Clients only | repairpunk.com",
            [Country.LT]: "Tik klientui | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        },
    },
    WARRANTY_N_REVIEW: {
        indexable: false,
        url: {
            [Country.CA]: "/warranty-and-review",
            [Country.LT]: "/garantija-atsiliepimas",
        },
        path: {
            [Country.CA]: ["/warranty-and-review/:orderId", "/g/:orderId"],
            [Country.LT]: ["/garantija-atsiliepimas/:orderId", "/g/:orderId"],
        },
        title: {
            [Country.CA]: "Warranty activate | repairpunk.com",
            [Country.LT]: "Garantijos aktyvacija | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        },
        shortUrl: "/g",
    },
    WARRANTY_ACTIVATION: {
        indexable: false,
        url: {
            [Country.CA]: "/activate-warranty",
            [Country.LT]: "/garantijos-aktyvacija",
        },
        path: {
            [Country.CA]: ["/activate-warranty/:orderId", "/a/:orderId"],
            [Country.LT]: ["/garantijos-aktyvacija/:orderId", "/a/:orderId"],
        },
        title: {
            [Country.CA]: "Activate warranty | repairpunk.com",
            [Country.LT]: "Garantijos aktyvacija | Nevaziuoja.lt",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]:
                "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        },
        shortUrl: "/a",
    },
    SITEMAP: {
        indexable: true,
        url: { [Country.CA]: "/sitemap", [Country.LT]: "/svetaines-struktura" },
        title: {
            [Country.CA]:
                "Best mechanics & auto repair shops sitemap | repairpunk.com",
            [Country.LT]: "Svetainės struktūra | Nevažiuoja",
        },
        description: {
            [Country.CA]:
                "Sitemap of Canada's best mechanics and auto repair shops near you.",
            [Country.LT]: "Puslapio struktūra",
        },
    },
    PARTNERS: {
        indexable: true,
        url: {
            [Country.CA]: "/auto-repair-partners",
            [Country.LT]: "/partneriai-autoservisai",
        },
        title: {
            [Country.CA]: "Auto Repair Partners",
            [Country.LT]: "Partneriai autoservisai | Nevažiuoja",
        },
        description: {
            [Country.CA]:
                "Repairs prices, 24/7 Booking, 100% Warranty. Compare auto repair shops & mechanics near you in Toronto & Montreal by price, availability, verified reviews.",
            [Country.LT]: "partneriai - autoservisai",
        },
    },
    ADMIN_PROMO: {
        hideNavBar: true,
        indexable: false,
        url: "/internal/admin/promo",
        title: {
            [Country.CA]: "/",
            [Country.LT]: "Admin nuolaidos generatorius | Nevažiuoja",
        },
        description: { [Country.CA]: "/", [Country.LT]: "admin panel" },
    },
};

/* Returns pages meta for provided country. If adjusting this fn args/return type make sure to check `overrides.d.ts` */
export function countryClientPageToMeta(country: Country | GqlCountry): {
    [k in ClientPage]: CountryPageMeta;
} {
    const countryPagesMeta = lMapValues(clientPageToMeta, (meta) => {
        return {
            ...meta,
            url: typeof meta.url === "object" ? meta.url[country] : meta.url,
            title: meta.title[country],
            description: meta.description[country],
            path: meta.path ? meta.path[country] : undefined,
        };
    });
    return countryPagesMeta;
}

export const pageRedirects = [
    {
        fromPath: "/r/kondicionieriu-remontas",
        toPath: `/autoservisai/vilnius/kondicionieriu-remontas-2_8`,
    },
] as { fromPath: string; toPath: string }[];

/* Returns a reverse object of {[url: string]: Page]} to easily find which page it is by URL */
export const clientUrlToPage = (
    country: Country,
    translate?: (translatable: Translatable) => string
) => {
    const res = lReduce(
        clientPageToMeta,
        (res, meta, pageEnum) => {
            const path =
                typeof meta.path === "object" ? meta.path[country] : meta.path;
            const revObj = {} as { [url: string]: ClientPage };

            if (path) {
                if (Array.isArray(path)) {
                    path.forEach((p) => {
                        revObj[p] = pageEnum as ClientPage;
                    });
                } else {
                    revObj[path] = pageEnum as ClientPage;
                }
            } else if (meta.genI18nPath) {
                const paths = meta.genI18nPath(
                    translate || ((t) => t.defaultTranslation),
                    country
                ) as string[];

                paths.forEach((p) => {
                    revObj[p] = pageEnum as ClientPage;
                });
            } else {
                const url =
                    typeof meta.url === "object" ? meta.url[country] : meta.url;
                revObj[url] = pageEnum as ClientPage;
            }
            return {
                ...res,
                ...revObj,
            };
        },
        {}
    ) as { [url: string]: ClientPage };
    return res;
};

export function genWorkshopPagePathParam(p: {
    ID: number;
    name: string;
}): string {
    return `${urlifyString(p.name)}-${p.ID}`;
}

// TBD: all places should use buildUrl instad
export function genWorkshopPathname(p: {
    ID: number;
    name: string;
    country: Country | GqlCountry;
}): string {
    const clientPageMeta = countryClientPageToMeta(p.country);

    // TBD might make this more SEO friendly by doing name+microdistrict combo instead of name+ID
    return `${clientPageMeta.WORKSHOP.url}/${genWorkshopPagePathParam(p)}`;
}

/** @ID - orderID */
export function genOrderSuccessPathname(p: {
    ID: number;
    userAccessToken: string;
    country: Country | GqlCountry;
}): string {
    const clientPageMeta = countryClientPageToMeta(p.country);

    return `${clientPageMeta.RESERVATION_SUCCESS.url}/${p.ID}?${QUERY_PARAMS.USER_ORDER_TOKEN}=${p.userAccessToken}`;
}
