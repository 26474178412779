import ConfirmationTitle from "@FEClient/views/commonComps/ConfirmationTitle/ConfirmationTitle";
import React from "react";
import * as S from "./ReservationSuccess.styled";
import * as CS from "../../commonComps/ConfirmationDetailsBox/ConfirmationDetailsBox.styled";
import useIsMobile from "../../../../../FEShared/hooks/useIsMobile";
import ConfirmationSidePanel from "@FEClient/views/commonComps/ConfirmationSidePanel/ConfirmationSidePanel";
import { observer, useLocalObservable } from "mobx-react-lite";
import ConfirmationRowItem from "@FEClient/views/commonComps/ConfirmationRowItem/ConfirmationRowItem";
import useStore, { history } from "@FEClient/logic/store";
import { useParams } from "react-router-dom";
import { countryClientPageToMeta } from "@Shared/util/clientPagesMeta";
import { runInAction } from "mobx";
import { dateToObj } from "@Shared/util/dateFormat";
import FollowSocialButtons from "@FEShared/components/UI/FollowSocialButtons/FollowSocialButtons";
import prodRealUsersOnly from "@FEClient/logic/utils/prodRealUsersOnly";
import Button from "@FEShared/components/UI/Button/Button";
import {
    OrderStatus,
    useOrderQuery,
} from "@FEShared/graphql/generated/graphql";
import gqlResHandler from "@FEShared/utils/gqlResHandler";
import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import useToken from "@FEClient/logic/hooks/useToken/useToken";
import useShowLoadingScreenForQuery from "@FEShared/hooks/useShowLoadingScreen";
import { carDataObjToArr } from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete.utils";
import mergeOrder from "@Shared/util/mergeOrder";
import isNullableValue from "@Shared/util/isNullableValue";
import Icon from "@FEShared/components/UI/Icon/Icon";
import QUERY_PARAMS from "@Shared/consts/QUERY_PARAMS";
import sha256 from "@FEShared/utils/sha256";
import ReservationCancelModal from "./ReservationCancelModal/ReservationCancelModal";
import { TransMsg, transStr } from "@FEShared/i18n";
import intlPhoneNumber from "@Shared/util/intlPhoneNumber";

const ReservationSuccess: React.FC<{}> = observer(() => {
    const SMS_AND_EMAIL_TEXT = transStr(
        "Tavo vizitui rezervuotas meistras. Apie apsilankymą tave informuosime SMS bei el. pašto žinutėmis!",
        { id: "bOM0uJAU" }
    );

    const token = useToken();
    const GS = useStore();
    const isMobile = useIsMobile();
    const params = useParams<{ orderId: string }>();
    const LS = useLocalObservable(() => ({
        cancelModalOpen: false,
    }));

    const [getOrderRes, getOrderReq] = useOrderQuery({
        variables: {
            accessToken: token,
            id: +params.orderId,
        },
        pause: true,
    });
    const completedOrder = GS.reservationSuccessPageState.completedOrder;
    const selectedWorkshop = GS.reservationSuccessPageState.selectedWorkshop;
    useShowLoadingScreenForQuery(
        getOrderRes,
        GS,
        () => !completedOrder || !selectedWorkshop
    );

    React.useEffect(() => {
        if (!selectedWorkshop || !completedOrder) {
            return console.error(
                "No selectedWorkshop or completedOrder in reservation success page!"
            );
        }

        prodRealUsersOnly(async () => {
            if (GS.searchPageState.altFlow.order) {
                window.gtag?.("event", "reservation_success_alt");
                window.hj?.("event", "reservation_success_alt");
                window.fbq?.("trackCustom", "reservation_success_alt");
            } else {
                const email = completedOrder.email?.toLowerCase().trim();

                const phone = completedOrder.phoneNumber
                    ? intlPhoneNumber(completedOrder.phoneNumber)
                    : undefined;

                const city = completedOrder.city
                    .toLowerCase()
                    .trim()
                    .replace(/ /g, "");

                const priceNDuration =
                    GS.getSelectedServicesPriceNDurationForWorkshop(
                        selectedWorkshop
                    );
                window.gtag?.("event", "reservation_success", {
                    value: (priceNDuration?.servicesPrice.value || 0) * 0.19,
                    transaction_id: completedOrder.ID,
                    currency: window._COUNTRY_META.currencyCode,
                });
                window.gtag?.("event", "reservation_success", {
                    send_to: "AW-11026165722/nNc7CM3exocZENrf14kp",
                    value: (priceNDuration?.servicesPrice.value || 0) * 0.19,
                    transaction_id: completedOrder.ID,
                    currency: window._COUNTRY_META.currencyCode,
                });
                window.fbq?.("track", "Purchase", {
                    value: (priceNDuration?.servicesPrice.value || 0) * 0.19,
                    currency: window._COUNTRY_META.currencyCode,
                    em: email ? await sha256(email) : undefined,
                    ph: phone ? await sha256(phone) : undefined,
                    city: await sha256(city),
                });
                window.hj?.("event", "reservation_success");
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        runInAction(() => {
            // reset primarySearchResults so if user clicks back on page, he will be redirected back to home.
            GS.searchPageState.primarySearchResults = undefined;
        });
    }, [GS.searchPageState]);

    React.useEffect(() => {
        if (!params.orderId) {
            history.push(countryClientPageToMeta(window._COUNTRY).HOME.url);
            return;
        }

        if (!completedOrder || !selectedWorkshop) {
            getOrderReq();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (completedOrder && selectedWorkshop) return;
        gqlResHandler(getOrderRes, (data) => {
            const workshop =
                data.order.acceptedService ||
                data.order.invitations.find((i) => i.primary)?.service;
            if (!workshop) {
                return console.error(
                    `Didn't find workshop in reservation succ page! OrderID: ${data.order.ID}`
                );
            }
            runInAction(() => {
                const mergedOrder = mergeOrder(data.order);
                GS.reservationSuccessPageState.completedOrder = mergedOrder;
                GS.reservationSuccessPageState.selectedWorkshop = workshop;
            });
        });
    }, [
        getOrderRes,
        GS.reservationSuccessPageState,
        completedOrder,
        selectedWorkshop,
    ]);

    const dateObj = completedOrder
        ? dateToObj(new Date(completedOrder.arrivalDate))
        : undefined;

    const clientInfo = React.useMemo(() => {
        if (!completedOrder) return;

        return [
            {
                id: 1,
                label: transStr("Telefono numeris", { id: "dqqjQ0Op" }),
                value: completedOrder.phoneNumber,
                icon: "icon-phone",
            },
            {
                id: 2,
                label: transStr("El. paštas", { id: "ydCDoTc8" }),
                value: completedOrder.email,
                icon: "icon-email",
            },
            completedOrder.customerComment && {
                id: 3,
                label: transStr("Komentaras", { id: "88rgmyQS" }),
                value: completedOrder.customerComment,
                icon: "icon-board",
            },
        ].filter(Boolean) as {
            id: number;
            label: string;
            value: string;
            icon: string;
        }[];
    }, [completedOrder]);

    const changeReservationDate = React.useCallback(() => {
        if (!completedOrder) return;

        runInAction(() => {
            if (completedOrder.createdByWorkshop) {
                GS.workshopPageState.fromBookNow = true;
            }

            GS.reservationConfirmationPageState.date.obj = dateToObj(
                completedOrder.arrivalDate
            );
            GS.searchState.carDataArr = carDataObjToArr(completedOrder);
            GS.searchState.selectedServicesDefinitionsIDs =
                completedOrder.categories.map((s) => s.ID);
            GS.reservationConfirmationPageState = {
                ...GS.reservationConfirmationPageState,
                comment: completedOrder.customerComment || "",
                emailInputVal: completedOrder.email || "",
                phoneInputVal: completedOrder.phoneNumber || "",
                selectedWorkshop: selectedWorkshop,
                rebookingFromOrderID: completedOrder.ID,
            };
        });
        history.push(
            countryClientPageToMeta(window._COUNTRY).RESERVATION_CONFIRM.url
        );
    }, [GS, completedOrder, selectedWorkshop]);

    if (!completedOrder || !selectedWorkshop || !clientInfo || !dateObj) {
        return (
            <div>
                <TransMsg default={"Kraunama..."} id="qOBFldzV" />
            </div>
        );
    }

    return (
        <S.SuccessContainer>
            <ReservationCancelModal
                changeReservationDate={changeReservationDate}
                isOpen={LS.cancelModalOpen}
                onClose={() => {
                    runInAction(() => {
                        LS.cancelModalOpen = false;
                    });
                }}
            />
            <S.LeftPanel>
                {!isMobile && (
                    <ConfirmationTitle
                        showWarranty={false}
                        color="#41b46f"
                        title={transStr("Rezervacija patvirtinta", {
                            id: "3RHT4TNR",
                        })}
                        iconClassName="icon-checkmark-circle"
                        subtitle={SMS_AND_EMAIL_TEXT}
                    />
                )}
                <CS.ConfirmationDetailsBox>
                    {clientInfo.map((infoRow) => (
                        <ConfirmationRowItem
                            key={infoRow.id}
                            id={infoRow.id}
                            value={infoRow.value ?? ""}
                            label={infoRow.label}
                            iconClassName={infoRow.icon}
                        />
                    ))}
                </CS.ConfirmationDetailsBox>
                <Box mt="32px" bgcolor="#ffffff" padding={3} rounded>
                    <Text semiBold mb={1}>
                        <TransMsg default={"🔒 Garantija"} id="aeaW9luq" />
                    </Text>
                    {completedOrder.createdByWorkshop ? (
                        <>
                            <Text variant="subtitle1">
                                <TransMsg
                                    default={
                                        "Nevažiuoja garantija užsakymui yra taikoma tik kai rezervacija yra atliekama tiesiogiai per nevažiuoja platformą."
                                    }
                                    id="rel2sYPr"
                                />
                            </Text>
                            <Button
                                sx={{ mb: 2, mt: 2 }}
                                fullWidth
                                disabled
                                color="error"
                                leftIconClass="icon-lock"
                            >
                                <TransMsg
                                    default={
                                        "Nevažiuoja garantija užsakymui netaikoma"
                                    }
                                    id="GPrAtoth"
                                />
                            </Button>
                        </>
                    ) : (
                        <>
                            <Text variant="subtitle1">
                                <TransMsg
                                    default={
                                        "Po sėkmingai atliktų darbų {Text <gausite čekį ir SMS/Email pranešimus garantijos aktyvavimui.>}"
                                    }
                                    data={{
                                        Text: (body) => (
                                            <Text
                                                span
                                                semiBold
                                                variant="inherit"
                                            >
                                                {body}
                                            </Text>
                                        ),
                                    }}
                                    id="JiV615OA"
                                />
                            </Text>
                            {completedOrder.status === "completed" &&
                            !isNullableValue(completedOrder.goodPrice) &&
                            !isNullableValue(
                                completedOrder.servicesCompletion
                            ) ? (
                                <Text
                                    color="success.main"
                                    semiBold
                                    fontSize={16}
                                    mt={1}
                                >
                                    <Icon
                                        className="icon-checkmark-circle"
                                        mr={0.5}
                                    />
                                    <TransMsg
                                        default={
                                            "Garantija sėkmingai aktyvuota"
                                        }
                                        id="iW9qRBeR"
                                    />
                                </Text>
                            ) : (
                                <Button
                                    sx={{ mb: 2, mt: 2 }}
                                    fullWidth
                                    disabled={
                                        completedOrder.status !== "completed"
                                    }
                                    color="success"
                                    onClick={() => {
                                        history.push(
                                            `${
                                                countryClientPageToMeta(
                                                    window._COUNTRY
                                                ).WARRANTY_ACTIVATION.url
                                            }/${completedOrder.ID}?t=${token}`
                                        );
                                    }}
                                    leftIconClass="icon-lock"
                                >
                                    {completedOrder.status === "completed"
                                        ? transStr("Aktyvuoti garantija", {
                                              id: "kKWpeo7X",
                                          })
                                        : transStr(
                                              "Aktyvuokite garantiją po sėkmingai atliktų darbų",
                                              { id: "mT6iaLu5" }
                                          )}
                                </Button>
                            )}
                        </>
                    )}
                </Box>
                <Box mt="32px" bgcolor="#ffffff" padding={3} rounded>
                    <Text semiBold mb={1}>
                        <TransMsg default={"⭐ Atsiliepimas"} id="XCwaAUSV" />
                    </Text>
                    <Text variant="subtitle1">
                        <TransMsg
                            default={"Po sėkmingai atliktų darbų,"}
                            id="VT4Aez8J"
                        />{" "}
                        <Text span semiBold variant="inherit">
                            <TransMsg
                                default={
                                    "gausite SMS/Email pranešimus palikti atsiliepimui."
                                }
                                id="gIZ0fWhh"
                            />
                        </Text>{" "}
                        <TransMsg
                            default={
                                "Autoservisai dažnai yra nedideli, nepriklausomi verslai, todėl tavo pasidalinimas patirtimi jiems reiškia labai daug ❤️"
                            }
                            id="3GQC0QNq"
                        />
                    </Text>
                    <Button
                        sx={{ mb: 2, mt: 2 }}
                        fullWidth
                        disabled={completedOrder.status !== "completed"}
                        color="tertiary"
                        onClick={() => {
                            const url = `${
                                completedOrder.createdByWorkshop
                                    ? countryClientPageToMeta(window._COUNTRY)
                                          .INTERNAL_ORDER_REVIEW.url
                                    : countryClientPageToMeta(window._COUNTRY)
                                          .WARRANTY_N_REVIEW.url
                            }/${completedOrder.ID}?${
                                QUERY_PARAMS.USER_ORDER_TOKEN
                            }=${completedOrder.userAccessToken}`;

                            history.push(url);
                        }}
                        leftIconClass="icon-star-full"
                    >
                        {completedOrder.status === "completed"
                            ? transStr("Palikti atsiliepimą", {
                                  id: "rUsz0Icv",
                              })
                            : transStr(
                                  "Palikite atsiliepimą po sėkmingai atliktų darbų",
                                  { id: "cyJFuoja" }
                              )}
                    </Button>
                </Box>
                <Box mt="32px" bgcolor="#ffffff" padding={3} rounded>
                    <Text semiBold mb={1}>
                        <TransMsg
                            default={"✏️ Rezervacijos valdymas"}
                            id="cF8YHoML"
                        />
                    </Text>
                    <Text variant="subtitle1">
                        <TransMsg
                            default={
                                "Autoservisai dažnai yra nedideli verslai, kurie planuojasi savo darbus ir {Text <jūsų vizitui rezervuoja keltą, meistrą ir kitus įrankius.>} Kiekvienas klientas jiems yra labai svarbus. Prašome redaguoti rezervaciją tik išimtinais atvejais."
                            }
                            data={{
                                Text: (body) => (
                                    <Text span variant="inherit" semiBold>
                                        {body}
                                    </Text>
                                ),
                            }}
                            id="n8DJycGe"
                        />
                    </Text>
                    <Button
                        sx={{ mb: 2, mt: 2 }}
                        fullWidth
                        color="greyish"
                        onClick={changeReservationDate}
                    >
                        <TransMsg
                            default={"Keisti rezervacijos laiką"}
                            id="rCqOPllh"
                        />
                    </Button>
                    <Box mt={5}>
                        <Text variant="subtitle1">
                            <TransMsg
                                default={
                                    "Jeigu turite galimybe, prašome pakeisti rezervacijos laiką, o ne ją atšaukti. Atšaukus rezervaciją likus mažiau nei 24val. iki vizito ar neatvykus į rezervaciją, autoservisas pasilieka teisę {Text <nepriimti tavęs ateities registracijoms>}"
                                }
                                data={{
                                    Text: (body) => (
                                        <Text span semiBold variant="inherit">
                                            {body}
                                        </Text>
                                    ),
                                }}
                                id="qRCJEPCx"
                            />
                        </Text>
                        <Button
                            sx={{
                                textDecoration: "underline",
                                fontSize: 12,
                                mt: 1,
                                color: "rgba(0,0,0,0.6)",
                            }}
                            disabled={[
                                OrderStatus.Completed,
                                OrderStatus.ClientCancelled,
                                OrderStatus.WorkshopCancelled,
                            ].includes(completedOrder.status)}
                            color="greyish"
                            variant="text"
                            onClick={async () => {
                                runInAction(() => {
                                    LS.cancelModalOpen = true;
                                });
                            }}
                        >
                            <TransMsg
                                default={"Atšaukti rezervaciją"}
                                id="a2UUJ0qF"
                            />
                        </Button>
                        {[
                            OrderStatus.Completed,
                            OrderStatus.ClientCancelled,
                            OrderStatus.WorkshopCancelled,
                        ].includes(completedOrder.status) && (
                            <Text variant="subtitle2" mt={1}>
                                <TransMsg
                                    default={
                                        "Užsakymo atšaukti negalima, nes jis yra atšauktas arba užbaigtas."
                                    }
                                    id="3Qc8NbGo"
                                />
                            </Text>
                        )}
                    </Box>
                </Box>
                <Box mt="64px">
                    <FollowSocialButtons />
                </Box>
            </S.LeftPanel>
            <ConfirmationSidePanel
                createdByWorkshop={completedOrder.createdByWorkshop}
                confirmationTitle={
                    isMobile ? (
                        <ConfirmationTitle
                            showWarranty={false}
                            color="#41b46f"
                            title={transStr("Rezervacija patvirtinta", {
                                id: "7DPqLDf7",
                            })}
                            iconClassName="icon-checkmark-circle"
                            subtitle={SMS_AND_EMAIL_TEXT}
                        />
                    ) : undefined
                }
                carData={completedOrder}
                dateObj={dateObj}
                selectedWorkshop={selectedWorkshop}
            />
        </S.SuccessContainer>
    );
});

export default ReservationSuccess;
