/* will return void if unidentified sms format */
export default function intlPhoneNumber(phoneNr: string): string | void {
    let intlNumber: string;
    if (phoneNr.startsWith("86")) {
        intlNumber = phoneNr.replace("86", "+3706");
    } else if (phoneNr.startsWith("0")) {
        intlNumber = phoneNr.replace("0", "+370");
    } else if (phoneNr.startsWith("+3706")) {
        intlNumber = phoneNr;
    } else if (phoneNr.startsWith("+1")) {
        intlNumber = phoneNr;
    } else {
        console.error(`Phone number unknown format: ${phoneNr}`);
        return;
    }

    return intlNumber.trim();
}
