import {
    CITY_TO_URLIFIED_MAP,
    URLIFIED_CAR_BRANDS_MAP,
    URLIFIED_CITIES_MAP,
    BRAND_TO_URLIFIED_MAP,
    ALL_SERVICES_NAME_FILLER,
} from "./Search.consts";
import { countryClientPageToMeta } from "@Shared/util/clientPagesMeta";
import capFirst from "@Shared/util/capFirst";
import mapKeyBy from "@Shared/util/mapKeyBy";
import Country from "@Shared/types/enums/Country";
import urlifyString from "@FEShared/utils/urlifyString";
import { SERVICES_TRANSLATABLES } from "@Shared/consts/SHARED_TRANSLATABLES";
import { transStringFunctionalFE } from "@FEShared/i18n";

const countryClientPageMeta = countryClientPageToMeta(window._COUNTRY);

const servicesMapByID = mapKeyBy(SERVICES_TRANSLATABLES, (s) => s.ID);

interface ParsedSearchParams {
    city: string;
    carBrand?: string;
    serviceDefinitionID?: string;
}

export class UrlPathnameParser {
    private static urlifyBrand(brand?: string): string {
        if (!brand) return "";
        const urlifiedBrand = BRAND_TO_URLIFIED_MAP.get(brand);
        if (!urlifiedBrand) {
            console.error(`Couldn't urlify brand: ${brand}`);
            return "";
        }
        return urlifiedBrand;
    }

    private static unurlifyBrand(brand?: string): string {
        if (!brand) return "";

        const unurlifiedBrand = URLIFIED_CAR_BRANDS_MAP.get(
            brand.toLowerCase()
        );
        if (!unurlifiedBrand) {
            console.error(`Couldn't unurlify brand: ${brand}`);
            return "";
        }
        return unurlifiedBrand;
    }

    public static urlifyServiceName(p: {
        serviceDefinitionID?: string;
        /* We use a `ALL_SERVICES_NAME_FILLER` when saved search URL should be searching for all services and not some specific service. But this is only relevant, when there is a car brand in the URL. */
        useAllServicesFiller?: boolean;
        country: Country;
    }): string {
        if (!p.serviceDefinitionID) {
            return p.useAllServicesFiller
                ? ALL_SERVICES_NAME_FILLER[p.country]
                : "";
        }

        const translatedServiceName = this.serviceIDToName(
            p.serviceDefinitionID
        );

        const urlifiedService = urlifyString(translatedServiceName);

        return [urlifiedService, p.serviceDefinitionID]
            .filter(Boolean)
            .join("-");
    }

    public static serviceIDToName(serviceID: string): string {
        const service = servicesMapByID.get(serviceID);
        if (!service) {
            console.error(
                `serviceIDToName Couldn't find service from ID: ${serviceID}`
            );
            return "";
        }
        return transStringFunctionalFE(service.serviceName);
    }

    public static urlifyCity(city: string): string {
        const urlifiedCity = CITY_TO_URLIFIED_MAP.get(city);
        if (!urlifiedCity) {
            console.error(
                `Couldn't urlify city: ${city}. urlified: ${urlifiedCity}`
            );

            return window._COUNTRY_META.defaultCity;
        }
        return urlifiedCity;
    }

    public static unurlifyCity(urlifiedCity: string): string {
        const unurlifiedCity = URLIFIED_CITIES_MAP.get(urlifiedCity);
        if (!unurlifiedCity) {
            console.error(
                `Couldn't unurlify city: ${urlifiedCity}. Unurlified: ${unurlifiedCity}`
            );

            return window._COUNTRY_META.defaultCity;
        }
        return unurlifiedCity;
    }

    /**
     * Query format: `/autoservisai/vilnius/<service_name>/<car_brand>` E.G. `/autoservisai/vilnius/padangu-montavimas/bmw`
     * Filler for all services <service_name> is `visos-paslaugos`
     */
    public static pathnameToParams(pathname: string): ParsedSearchParams {
        /* If incorrect path received, redirect to most correct path possible from received parsable URL props. */
        let redirect = false;

        const dataPart = pathname
            .replace(`${countryClientPageMeta.SEARCH.url}/`, "")
            .replace(`${countryClientPageMeta.SEARCH.url}`, "");

        if (!dataPart) {
            console.warn(`Couldn't parse pathname to params: ${pathname}`);
            return {
                city: window._COUNTRY_META.defaultCity,
            };
        }
        // vilnius/padangu-montavimas/bmw
        const [city, serviceUrlPart, carBrand] = dataPart
            .split("/")
            .filter(Boolean);

        let serviceDefinitionID: string | undefined;
        if (serviceUrlPart) {
            if (serviceUrlPart.includes("_")) {
                serviceDefinitionID = serviceUrlPart.split("-").pop();
            } else {
                redirect = true;
            }
        }

        const finalParsedData: ParsedSearchParams = {
            city: capFirst(this.unurlifyCity(city)),
            carBrand: carBrand ? this.unurlifyBrand(carBrand) : undefined,
            serviceDefinitionID,
        };

        if (redirect) {
            const newUrl = this.paramsToPathname({
                ...finalParsedData,
            });
            window.location.replace(newUrl);
        }

        return finalParsedData;
    }

    /*
     * TBD: in the future so that SitemapGenerator should use this. But for this, this will have to be refactored into Shared logic, since this uses some pure FE functions.
     */
    public static paramsToPathname(p: ParsedSearchParams): string {
        const startingPath = countryClientPageMeta.SEARCH.url;
        const serviceID = p.serviceDefinitionID;

        const fullBuiltPathname = [
            startingPath,
            this.urlifyCity(p.city),
            this.urlifyServiceName({
                country: window._COUNTRY,
                serviceDefinitionID: serviceID,
                useAllServicesFiller: !!p.carBrand,
            }),
            p.carBrand && this.urlifyBrand(p.carBrand),
        ]
            .filter(Boolean)
            .join("/")
            .toLowerCase();

        return fullBuiltPathname;
    }
}
