import React from "react";
import * as S from "./Workshop.styled";
import { observer } from "mobx-react-lite";
import ContentContainer from "../../commonComps/ContentContainer/ContentContainer";
import "react-multi-carousel/lib/styles.css";
import { Redirect, useParams } from "react-router-dom";
import {
    genWorkshopPathname,
    countryClientPageToMeta,
    ClientPage,
} from "@Shared/util/clientPagesMeta";
import {
    DisableReason,
    useGetServiceQuery,
} from "@FEShared/graphql/generated/graphql";
import _groupBy from "lodash/groupBy";
import ReviewsSection from "./ReviewsSection/ReviewsSection";
import AboutWorkshopSection from "./AboutWorkshopSection/AboutWorkshopSection";
import ServicesSection from "./ServicesSection/ServicesSection";
import TopInfoSection from "./TopInfoSection/TopInfoSection";
import StickyBottomBar from "./StickyBottomBar/StickyBottomBar";
import { runInAction } from "mobx";
import useStore from "@FEClient/logic/store";
import { Helmet } from "react-helmet";
import QUERY_PARAMS from "@Shared/consts/QUERY_PARAMS";
import useShowLoadingScreen from "@FEShared/hooks/useShowLoadingScreen";
import Box from "@FEShared/components/UI/Box/Box";
import capFirst from "@Shared/util/capFirst";
import showToast from "@FEShared/utils/showToast";
import ReservationBox from "./ServicesSection/ReservationBox/ReservationBox";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import MobileTimePickerSection from "./MobileTimePickerSection/MobileTimePickerSection";
import FAQSection, { formQuestions } from "./FAQSection/FAQSection";
import PriceSection from "./PriceSection/PriceSection";
import latinize from "latinize";
import AmenitiesSection from "./AmenitiesSection/AmenitiesSection";
import normalizeJsonLDString from "@FEShared/utils/normalizeJsonLDString";
import { TransMsg, transStr } from "@FEShared/i18n";
import { getDynamicWorkshopMeta } from "./Workshop.utils";
import buildUrl from "@Shared/util/buildUrl";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";

const countryPageMeta = countryClientPageToMeta(window._COUNTRY);

const Workshop = observer(() => {
    console.log("Workshop.tsx render");
    const { workshopName } = useParams<{ workshopName: string }>();
    const GS = useStore();
    const isMobile = useIsMobile();

    const workshopID = +workshopName.split("-").slice(-1);
    if (!workshopID || !Number.isInteger(workshopID)) {
        return (
            <Redirect
                to={
                    countryClientPageToMeta(window._COUNTRY).HOME.url[
                        window._COUNTRY
                    ]
                }
            />
        );
    }

    const [serviceQuery] = useGetServiceQuery({
        variables: {
            id: workshopID,
            getEarliestAvailableTimeParams: {
                serviceID: workshopID,
            },
            vehicle:
                GS.searchState.carData.vehicleBrand &&
                GS.searchState.carData.vehicleModel &&
                GS.searchState.carData.vehicleYear
                    ? {
                          brand: GS.searchState.carData.vehicleBrand,
                          model: GS.searchState.carData.vehicleModel,
                          year: +GS.searchState.carData.vehicleYear,
                      }
                    : undefined,
        },
    });
    useShowLoadingScreen(serviceQuery, GS, () => {
        if (serviceQuery.data?.service) return false;
        return true;
    });

    const selectedWorkshop = serviceQuery?.data?.service;

    React.useEffect(() => {
        return () => {
            GS.workshopPageState.resetState();
        };
    }, []);

    React.useEffect(() => {
        runInAction(() => {
            GS.workshopPageState.fromBookNow = window.location.href.includes(
                `?${QUERY_PARAMS.BOOK_NOW}`
            );
        });
    }, [GS.workshopPageState]);

    React.useEffect(() => {
        runInAction(() => {
            GS.workshopPageState.isLoading = serviceQuery.fetching;
        });
    }, [serviceQuery.fetching, GS.workshopPageState]);

    React.useEffect(() => {
        if (selectedWorkshop) {
            runInAction(() => {
                GS.reservationConfirmationPageState.selectedWorkshop =
                    selectedWorkshop;
            });
        }
    }, [selectedWorkshop, GS.reservationConfirmationPageState]);

    React.useEffect(() => {
        const time =
            serviceQuery?.data?.getEarliestAvailableTime.earliestAvailableTime;
        if (time) {
            runInAction(() => {
                GS.workshopPageState.earliestAvailableTime = new Date(time);
            });
        }
    }, [
        serviceQuery?.data?.getEarliestAvailableTime.earliestAvailableTime,
        GS.workshopPageState,
    ]);

    if (
        (!serviceQuery.operation || serviceQuery.fetching) &&
        !selectedWorkshop
    ) {
        console.log("return");
        return (
            <div>
                <TransMsg default={"Kraunama..."} id="9BDHrML8" />
            </div>
        );
    }
    console.log("after loading");

    if (!selectedWorkshop) {
        console.log("no workshop return");
        return (
            <Redirect to={countryClientPageToMeta(window._COUNTRY).HOME.url} />
        );
    }

    if (
        !window.location.href.includes(QUERY_PARAMS.IGNORE_REDIRECT) &&
        selectedWorkshop.disabled &&
        selectedWorkshop.disableReason &&
        [
            DisableReason.Churn,
            DisableReason.Fake,
            DisableReason.Terminated,
        ].includes(selectedWorkshop.disableReason)
    ) {
        showToast.warn(
            transStr(
                "Šis autoservisas šiuo metu nepriima naujų klientų, todėl nukreipėme jus į autoservisų paiešką",
                { id: "V4FtwHMZ" }
            )
        );
        return (
            <Redirect
                to={`${
                    countryClientPageToMeta(window._COUNTRY).SEARCH.url
                }/${selectedWorkshop.city.toLowerCase()}`}
            />
        );
    }

    const titleLoc = [selectedWorkshop.city, selectedWorkshop.microdistrict]
        .filter(Boolean)
        .join(", ");

    const dynamicPageMeta = getDynamicWorkshopMeta({
        country: window._COUNTRY,
        locationText: titleLoc,
        workshopName: selectedWorkshop.name,
    });

    return (
        // Possible future improvement (if relevant): ContentContainer and ContentWrapper should be merged and selectedWorkshop maybe moved into GS, because theres quite some prop drilling now
        // TBD: Will need to change `Autoservisas` title to something different for other type of workshops.
        <Box bgcolor="#ffffff" pt="16px" pb="64px">
            <ContentContainer>
                <S.WSPageGlobalStyles />
                <Helmet>
                    <title>{dynamicPageMeta.title}</title>
                    <meta name="description" content={dynamicPageMeta.descr} />
                    <link
                        rel="canonical"
                        href={`${
                            window._COUNTRY_META.baseUrl
                        }${genWorkshopPathname({
                            ID: selectedWorkshop.ID,
                            name: selectedWorkshop.name,
                            country: window._COUNTRY,
                        })}`}
                    />
                    <script type="application/ld+json">
                        {`
                            {
                                "@context": "http://schema.org",
                                "@type": "BreadcrumbList",
                                "numberOfItems": 3,
                                "itemListElement": [
                                  {
                                    "@type": "ListItem",
                                    "position": 1,
                                    "item": {
                                      "@type": "WebPage",
                                      "@id": "${countryPageMeta.SEARCH.url}",
                                      "name": "${
                                          dynamicPageMeta.workshopKeyword
                                      }"
                                    }
                                  },
                                  {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "item": {
                                      "@type": "WebPage",
                                      "@id": "${buildUrl({
                                          country: window._COUNTRY,
                                          page: ClientPage.SEARCH,
                                          pathParam: selectedWorkshop.city,
                                      })}",
                                      "name": "${capFirst(
                                          latinize(selectedWorkshop.city)
                                      )}"
                                    }
                                  },
                                  {
                                    "@type": "ListItem",
                                    "position": 3,
                                    "item": {
                                      "@type": "WebPage",
                                      "@id": "${
                                          window._COUNTRY_META.baseUrl
                                      }${genWorkshopPathname({
                            ...selectedWorkshop,
                            country: window._COUNTRY,
                        })}",
                                      "name": "${selectedWorkshop.name}",
                                      "image": "${
                                          selectedWorkshop.logoURL.includes(
                                              "cloudfront"
                                          )
                                              ? selectedWorkshop.logoURL
                                              : `${window._COUNTRY_META.baseUrl}${selectedWorkshop.logoURL}`
                                      }"
                                    }
                                  }
                                ]
                              }
                        `}
                    </script>
                    <script type="application/ld+json">
                        {`
                            {
                                "@context": "https://schema.org",
                                "@type": "AutoRepair",
                                "@id": "${
                                    window._COUNTRY_META.baseUrl
                                }${genWorkshopPathname({
                            ID: selectedWorkshop.ID,
                            name: selectedWorkshop.name,
                            country: window._COUNTRY,
                        })}",
                                "name": "${normalizeJsonLDString(
                                    selectedWorkshop.name
                                )}",
                                "image": "${
                                    selectedWorkshop.logoURL.includes(
                                        "cloudfront"
                                    )
                                        ? selectedWorkshop.logoURL
                                        : `https://${window._COUNTRY_META.domain}` +
                                          selectedWorkshop.logoURL
                                }",
                                "description": "${normalizeJsonLDString(
                                    selectedWorkshop.description
                                )}",
                                "priceRange": "${
                                    window._COUNTRY_META.currencySymbol
                                }${
                            selectedWorkshop.hourCost
                        }/${transCommonTranslatable("HOUR_SHORT")}",
                                "areaServed": "${selectedWorkshop.city}",
                                "address": {
                                  "@type": "PostalAddress",
                                  "addressCountry": "LT",
                                  "addressLocality": "${selectedWorkshop.city}",
                                  "streetAddress": "${[
                                      selectedWorkshop.address,
                                      selectedWorkshop.microdistrict,
                                  ]
                                      .filter(Boolean)
                                      .join(", ")}"
                                },
                                "aggregateRating": {
                                  "@type": "AggregateRating",
                                  "bestRating": 5,
                                  "worstRating": 1,
                                  "ratingValue": ${
                                      selectedWorkshop.reviewRatingGmap || 5
                                  },
                                  "reviewCount": ${
                                      selectedWorkshop.reviewCountGmap || 1
                                  } 
                                },
                                "geo": {
                                  "@type": "GeoCoordinates",
                                  "longitude": ${selectedWorkshop.posX.toFixed(
                                      7
                                  )},
                                  "latitude": ${selectedWorkshop.posY.toFixed(
                                      7
                                  )}
                                },
                                "openingHoursSpecification": [
                                    ${Object.entries(
                                        selectedWorkshop.workHoursCalendar
                                    )
                                        .map(([weekday, times]) => {
                                            if (
                                                typeof times !== "object" ||
                                                !times
                                            )
                                                return;

                                            // prettier-ignore
                                            return `
                                            {
                                                "@type": "OpeningHoursSpecification",
                                                "dayOfWeek": "https://schema.org/${capFirst(weekday)}",
                                                "opens": "${times.open.hour.toString().padStart(2,"0")}:${times.open.minute.toString().padStart(2, "0")}",
                                                "closes": "${times.close.hour.toString().padStart(2,"0")}:${times.close.minute.toString().padStart(2, "0")}"
                                            }
                                        `;
                                        })
                                        .filter(Boolean)
                                        .join(",")}
                                ],
                                "review": [
                                    ${selectedWorkshop.orderReviews
                                        .map(
                                            (r) => `
                                    {
                                        "@context": "http://schema.org",
                                        "@type": "Review",
                                        "author": {
                                            "@type": "Person",
                                            "name": "${normalizeJsonLDString(
                                                r.authorName,
                                                99
                                            )}"
                                        },
                                        "datePublished": "${new Date(
                                            r.createdAt
                                        ).toISOString()}",
                                        "reviewBody": "${normalizeJsonLDString(
                                            r.text
                                        )}",
                                        "reviewRating": {
                                            "@type": "Rating",
                                            "bestRating": 5,
                                            "worstRating": 1,
                                            "ratingValue": ${r.rating}
                                        }
                                    }`
                                        )
                                        .filter(Boolean)
                                        .join(",")}
                                ]
                                // prettier-ignore-end
                            }
                        `}
                    </script>
                    <script type="application/ld+json">
                        {`
                            {
                                "@context": "https://schema.org",
                                "type": "FAQPage",
                                "mainEntity": [
                                    ${formQuestions({
                                        fixedCarBrands:
                                            selectedWorkshop.servicedBrands,
                                    })
                                        .map((q) => {
                                            return `
                                        {
                                            "@type": "Question",
                                            "name": "${normalizeJsonLDString(
                                                q.question
                                            )}",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "${normalizeJsonLDString(
                                                    q.answer
                                                )}"
                                            }
                                        }`;
                                        })
                                        .join(",")}
                                ]
                            }
                        `}
                    </script>
                </Helmet>
                <Box>
                    <S.ContentWrapper>
                        <TopInfoSection selectedWorkshop={selectedWorkshop} />
                        <Box position="relative" displayFlex>
                            <Box
                                sx={
                                    isMobile
                                        ? {
                                              width: 1,
                                          }
                                        : { width: 0.7, mr: 5 }
                                }
                            >
                                {isMobile && (
                                    <MobileTimePickerSection
                                        selectedWorkshop={selectedWorkshop}
                                    />
                                )}
                                <AmenitiesSection
                                    selectedWorkshop={selectedWorkshop}
                                />
                                <PriceSection />
                                <ServicesSection
                                    selectedWorkshop={selectedWorkshop}
                                />
                                <ReviewsSection
                                    selectedWorkshop={selectedWorkshop}
                                />
                                <AboutWorkshopSection
                                    selectedWorkshop={selectedWorkshop}
                                />
                                <FAQSection
                                    selectedWorkshop={selectedWorkshop}
                                />
                            </Box>
                            {!isMobile && (
                                <ReservationBox
                                    selectedWorkshop={selectedWorkshop}
                                />
                            )}
                        </Box>
                        <StickyBottomBar selectedWorkshop={selectedWorkshop} />
                    </S.ContentWrapper>
                </Box>
            </ContentContainer>
        </Box>
    );
});
export default Workshop;
