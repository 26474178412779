import React from "react";
import Text from "@FEShared/components/UI/Text/Text";
import Box from "@FEShared/components/UI/Box/Box";
import Button from "@FEShared/components/UI/Button/Button";
import ButtonsMenu from "@FEShared/components/UI/ButtonsMenu/ButtonsMenu";
import useStore from "@FEClient/logic/store";
import HEART_BROKEN from "./assets/heart-broken.png";
import Img from "@FEShared/components/UI/Img/Img";
import { ModalStep } from "../ReservationCancelModal.types";
import { TransMsg, transStr } from "@FEShared/i18n";

export const PROC = "10%";

const OrderCancelConfirmStep: React.FC<{
    onClose: () => void;
    isLoading?: ModalStep;
    reReg: () => void;
    changeReservationDate: () => void;
    cancelOrder: (showNotif?: boolean) => Promise<void>;
}> = (p) => {
    const GS = useStore();

    const order = GS.reservationSuccessPageState.completedOrder;

    if (!order) return <div>Kraunama...</div>;

    return (
        <div>
            <Text mb={2} semiBold fontSize={28} center>
                <TransMsg
                    default={"Ar tikrai norite atšaukti rezervaciją?"}
                    id="1JI04Q9p"
                />
            </Text>
            <Box textAlign="center">
                <Img
                    src={HEART_BROKEN}
                    mt={3}
                    mb={3}
                    ml="auto"
                    mr="auto"
                    height={225}
                    sx={{ textAlign: "center" }}
                />
            </Box>
            <Text center>
                <TransMsg
                    default={
                        "Autoservisai dažnai yra nedideli verslai, kurie planuojasi savo darbus ir jūsų vizitui rezervuoja keltą, meistrą ir kitus įrankius. Kiekvienas klientas jiems yra labai svarbus. Jeigu galite - iškarto rezervuokite naują laiką ar atlikite naują registraciją (jei dar to nepadarėte)"
                    }
                    id="DpEBfuif"
                />
            </Text>
            <Box
                mt={4}
                displayFlex
                sx={(theme) => ({
                    [theme.breakpoints.down("md")]: {
                        flexDirection: "column",
                    },
                })}
            >
                <Button
                    sx={(theme) => ({
                        mr: 2,
                        [theme.breakpoints.down("md")]: {
                            mr: 0,
                            mb: 2,
                            flexDirection: "column",
                        },
                    })}
                    fullWidth
                    disabled={Boolean(p.isLoading)}
                    onClick={async () => {
                        p.changeReservationDate();
                    }}
                >
                    <TransMsg default={"Keisti vizito laiką"} id="iEjIBues" />
                </Button>
                <ButtonsMenu
                    paperProps={{
                        sx: {
                            boxShadow: 3,
                        },
                    }}
                    btnProps={{
                        color: "greyish",
                        fullWidth: true,
                        isLoading: Boolean(p.isLoading),
                    }}
                    menuButtonText={transStr("Persiregistruoti/Atšaukti", {
                        id: "sb5M9RHC",
                    })}
                    options={[
                        {
                            disabled: Boolean(p.isLoading),
                            isLoading: Boolean(p.isLoading),
                            value: `Nauja registracija`,

                            title: transStr("Nauja registracija", {
                                id: "tto7pxAf",
                            }),
                            onClick: async () => {
                                await p.cancelOrder(true);
                                p.reReg();
                            },
                        },
                        {
                            disabled: Boolean(p.isLoading),
                            isLoading: Boolean(p.isLoading),
                            value: "Atšaukti rezervaciją",

                            title: transStr("Atšaukti rezervaciją", {
                                id: "YKPw17f7",
                            }),
                            onClick: () => {
                                p.cancelOrder(true);
                            },
                        },
                    ]}
                />
            </Box>
        </div>
    );
};

export default OrderCancelConfirmStep;
