import Country from "@Shared/types/enums/Country";
import CountryState from "@Shared/types/enums/CountryState";
import { LiteralValuesFromArray } from "@Shared/types/utils";

const CITIES = {
    [Country.LT]: [
        "Vilnius",
        "Kaunas",
        "Klaipėda",
        "Panevėžys",
        "Šiauliai",
        "Alytus",
    ],
    [Country.CA]: ["Toronto", "Montreal", "Vilnius"],
} as const;

export type Cities = typeof CITIES;

export default CITIES;

export type City = Cities[keyof Cities][number];

// use cityToCountry instead
export const WHERE_CITY: {
    [Country in keyof Cities]: {
        [cityName in LiteralValuesFromArray<Cities[Country]>]: string;
    };
} = {
    [Country.LT]: {
        Vilnius: "Vilniuje",
        Kaunas: "Kaune",
        Klaipėda: "Klaipėdoje",
        Panevėžys: "Panevėžyje",
        Šiauliai: "Šiauliuose",
        Alytus: "Alytuje",
    },
    [Country.CA]: {
        Toronto: "Toronto",
        Montreal: "Montreal",
        Vilnius: "Vilniuje",
    },
};

/*
 * After we add one more english speaking country, we will need to either:
 * 1. Add Country name into ALL_CITIES values, so that all values would be unique
 * 2. Adjust the mechanism for ALL_CITIES, to allow non-unique values, but I think #1 is better
 */
export const ALL_CITIES = {
    [Country.LT]: "Visi miestai",
    [Country.CA]: "All cities",
} as const;

export const CITIES_WITH_ALL = {
    [Country.LT]: [...CITIES.LT, ALL_CITIES.LT],
    [Country.CA]: [...CITIES.CA],
};

export type CityWithAll =
    (typeof CITIES_WITH_ALL)[keyof typeof CITIES_WITH_ALL][number];

export const CITY_TO_COUNTRY = Object.entries(CITIES_WITH_ALL).reduce(
    (acc, pair) => {
        const [country, cities] = pair;
        cities.forEach((city) => {
            acc[city] = country as Country;
        });
        return acc;
    },
    {} as Record<string /* city */, Country | undefined>
);

export const CITY_TO_STATE_MAP: Record<City, CountryState | undefined> = {
    ["Vilnius"]: undefined,
    ["Kaunas"]: undefined,
    ["Klaipėda"]: undefined,
    ["Panevėžys"]: undefined,
    ["Šiauliai"]: undefined,
    ["Alytus"]: undefined,
    ["Toronto"]: CountryState.ONTARIO,
    ["Montreal"]: CountryState.QUEBEC,
};

export const CITY_TO_LOCALE_MAP: Record<
    City,
    {
        country: Country;
        countryState?: CountryState;
    }
> = {
    ["Vilnius"]: { country: Country.LT, countryState: undefined },
    ["Kaunas"]: { country: Country.LT, countryState: undefined },
    ["Klaipėda"]: { country: Country.LT, countryState: undefined },
    ["Panevėžys"]: { country: Country.LT, countryState: undefined },
    ["Šiauliai"]: { country: Country.LT, countryState: undefined },
    ["Alytus"]: { country: Country.LT, countryState: undefined },
    ["Toronto"]: { country: Country.CA, countryState: CountryState.ONTARIO },
    ["Montreal"]: { country: Country.CA, countryState: CountryState.QUEBEC },
};
