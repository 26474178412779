import React from "react";
import * as S from "./WarrantyContent.styled";

const WarrantyContent_EN = () => {
    return (
        <div>
            <p>
                All repairs via any{" "}
                <a href="https://nevaziuoja.lt">nevaziuoja.lt</a> auto shop
                partner come with a repair warranty. The warranty covers 90 days
                or 5000 kilometres, whichever comes first, or for such greater
                minimum as may be prescribed.
            </p>
            <p>
                Parts are covered by the manufacturer&apos;s warranty. All
                defects covered by the warranty will be repaired free of charge,
                and faulty components will be replaced.
            </p>

            <h2>Technical Advocate</h2>
            <p>
                Each <a href="https://nevaziuoja.lt">nevaziuoja.lt</a> customer
                is represented by a technical advocate in case of a conflict
                with an auto shop. The purpose of this service is to objectively
                assess the repairs carried out by the garage when the client has
                complaints about the quality of the work that cannot be resolved
                individually with the shop.
            </p>
            <p>
                Assessing vehicle damage requires knowledge and experience,
                which is why we work only with professional, independent
                licensed mechanics in this field. We are here to provide quality
                car repair services and to help you with any problems that may
                arise.
            </p>

            <h2>Nevaziuoja.lt Quality Guarantee</h2>
            <p>
                At <a href="https://nevaziuoja.lt">Nevaziuoja.lt</a>, we&apos;ve
                partnered with top-performing car repair specialists based on
                customer feedback. We thoroughly investigate every customer
                complaint to ensure that only the highest-quality service
                providers are featured on our platform.
            </p>
            <p>
                Our warranty terms and conditions comply with Canadian laws,
                including:
                <ul>
                    <li>
                        Consumer Protection Act 2002 S.O. 2002 c. 30 Sched. A
                    </li>
                    <li>Repair and Storage Liens Act R.S.O. 1990 c. R.25</li>
                    <li>Motor Vehicle Repair Act R.S.O. 1990 c. M.43</li>
                    <li>Ontario Regulation 17/05</li>
                </ul>
            </p>

            <h2>Warranty Conditions</h2>
            <p>
                To maintain warranty coverage, the client must follow the
                vehicle&apos;s operating guidelines. The warranted vehicle or
                its parts must be used properly and not damaged due to improper
                use, such as operating the vehicle or its components in a way
                not intended by the manufacturer, mechanical damage,
                overheating, or operating without sufficient fluids or
                lubricants.
            </p>
            <p>
                The client must adhere to the service provider&apos;s
                instructions, including timely replacement of parts and
                performing all necessary actions or procedures as specified in
                the technical processes.
            </p>

            <h2>Exemptions</h2>
            <p>
                The warranty on parts and labour does <strong>not</strong> apply
                to:
                <ul>
                    <li>Fluids, filters, lights, tires, or batteries</li>
                    <li>
                        Any part that was not warranted by the manufacturer of
                        the vehicle when the vehicle was sold as new
                    </li>
                    <li>
                        Any part or the labour required to install it under
                        another warranty that provides equal or greater coverage
                        (in both time and distance) than the warranty imposed by
                        the CPA
                    </li>
                </ul>
            </p>
        </div>
    );
};

export default WarrantyContent_EN;
