import React from "react";
import { InputP } from "../Input/Input";
import { Fzf, byStartAsc } from "fzf";
import latinize from "latinize";
import showToast from "@FEShared/utils/showToast";
import getPopularSearchOptions from "@FEShared/utils/getPopularSearchOptions";
import AutocompleteWithModal from "../AutocompleteWithModal/AutocompleteWithModal";
import { observer, useLocalObservable } from "mobx-react-lite";
import * as S from "./ServicesAutocomplete.styled";
import { runInAction } from "mobx";
import lGroupBy from "lodash/groupBy";
import lMapValues from "lodash/mapValues";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import lOrderBy from "lodash/orderBy";
import { CATEGORIES_ORDER } from "@Shared/consts/commonConsts";
import { PART_CATEG_TO_IMG_URL } from "./ServicesAutocomplete.consts";
import { Box, CircularProgress } from "@mui/material";
import mapKeyBy from "@Shared/util/mapKeyBy";
import Text from "@FEShared/components/UI/Text/Text";
import { MergedServiceOrPackage } from "@Shared/util/mergeServicesWithPackages";
import lUniq from "lodash/uniq";
import Icon from "../Icon/Icon";
import getSubcategSortOrder from "@FEShared/utils/getSubcategSortOrder";
import Button from "../Button/Button";
import Img from "../Img/Img";
import { transStringFunctionalFE } from "@FEShared/i18n";
import { ServiceAutocompleteOption } from "./ServicesAutocomplete.types";
import translatableCustomIDToTransString from "@FEShared/utils/translatableCustomIDToTranslatable";
import {
    TranslatableCustomID_PartCategories,
    TranslatableCustomID_PopularServices,
    TranslatableCustomID_SubCategories,
} from "@Shared/types/enums/TranslatableCustomID";
import getTransNameToTranslatableMap from "@FEShared/utils/getTransNameToTranslatableMap";
import isEnumValue from "@Shared/util/isEnumValue";
import { TransMsg, transStr } from "@FEShared/i18n";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";

const translatables = () => ({
    CHOSEN: transStr("Pasirinkta", { id: "Rj24lZNH" }),
});

const ServicesAutocomplete: React.FC<{
    services: MergedServiceOrPackage[];
    onChange: (val: string[]) => void;
    value: string[];
    className?: string;
    forceInputErr?: boolean;
    onClose?: () => void;
    errorsEnabled?: boolean;
    placeholder?: string;
    onOtherServiceAdd?: () => void;
    fnsRef?: React.RefObject<{
        close: () => void;
    }>;
}> = observer((p) => {
    const { onChange, onClose, onOtherServiceAdd } = p;
    const isMobile = useIsMobile();

    const {
        otherCategTransName,
        popularCategTransName,
        unknownProblemTransName,
        popularSearchOpts,
        transNameMaps,
    } = React.useMemo(() => {
        return {
            otherCategTransName: translatableCustomIDToTransString(
                TranslatableCustomID_PartCategories.OTHER
            ),
            popularCategTransName: translatableCustomIDToTransString(
                TranslatableCustomID_PartCategories.POPULAR
            ),
            unknownProblemTransName: translatableCustomIDToTransString(
                TranslatableCustomID_PopularServices.UNKNOWN_PROBLEM
            ),
            popularSearchOpts: getPopularSearchOptions(),
            transNameMaps: getTransNameToTranslatableMap(),
        };
    }, []);

    const translatedServices: ServiceAutocompleteOption[] =
        React.useMemo(() => {
            return p.services.map((s) => {
                return {
                    ...s,
                    serviceName: transStringFunctionalFE(s.serviceName),
                    searchAliases: s.searchAliases
                        ? Array.isArray(s.searchAliases)
                            ? transStringFunctionalFE(s.searchAliases).join(
                                  ", "
                              )
                            : transStringFunctionalFE(s.searchAliases)
                        : undefined,
                    partCategory: transStringFunctionalFE(s.partCategory),
                    recommendationText: s.recommendationText
                        ? transStringFunctionalFE(s.recommendationText)
                        : undefined,
                    additionalTexts: s.additionalTexts
                        ? transStringFunctionalFE(s.additionalTexts)
                        : undefined,
                    categorization: s.categorization.map((c) => ({
                        ...c,
                        partCategory: transStringFunctionalFE(c.partCategory),
                        subCategory: c.subCategory
                            ? transStringFunctionalFE(c.subCategory)
                            : undefined,
                    })),
                };
            });
        }, [p.services]);

    const LS = useLocalObservable(() => ({
        /**
         * wasOpenedOnce is handled here instead of the input, because on mobile, the input is disabled and it wont
         * toggle its own "wasOpenedOnce"
         * */
        wasOpenedOnce: false,
        activePartCategory: undefined as undefined | string,
        activeSubCategory: undefined as undefined | string,
        searchVal: "",
        isOpen: false,
        goBack() {
            if (LS.activeSubCategory) {
                LS.activeSubCategory = undefined;
            } else if (LS.activePartCategory) {
                LS.activePartCategory = undefined;
                LS.activeSubCategory = undefined;
            } else {
                this.close();
            }
        },
        open() {
            LS.activePartCategory = isMobile
                ? undefined
                : translatableCustomIDToTransString(
                      TranslatableCustomID_PartCategories.POPULAR
                  );
            LS.activeSubCategory = undefined;
            LS.wasOpenedOnce = true;
            LS.isOpen = true;
        },
        close() {
            p.onClose && p.onClose();
            LS.isOpen = false;
            LS.activePartCategory = isMobile
                ? undefined
                : translatableCustomIDToTransString(
                      TranslatableCustomID_PartCategories.POPULAR
                  );
            LS.activeSubCategory = undefined;
            LS.modal.toggle(false);
        },
        modal: {
            isOpen: false,
            showChooseBtn: true,
            toggle(isOpen: boolean) {
                LS.wasOpenedOnce = true;
                if (isOpen) {
                    LS.isOpen = true;
                    LS.modal.isOpen = isOpen;
                } else {
                    /**
                     * This is needed to prevent scrolling to the bottom of the page on iOS devices. Because if input
                     * is focused on mobile, then keyboard is active and that forces scrolling to the bottom of the page.
                     */
                    (
                        window.document
                            ?.activeElement as HTMLInputElement | null
                    )?.blur();

                    p.onClose && p.onClose();
                    LS.modal.isOpen = false;
                    LS.isOpen = false;
                }
            },
        },
    }));
    const { close } = LS;

    const kServicesByName = React.useMemo(
        () => mapKeyBy(translatedServices, (s) => s.serviceName),
        [translatedServices]
    );

    React.useImperativeHandle(
        p.fnsRef,
        () => ({
            close: LS.close,
        }),
        [LS]
    );

    React.useEffect(() => {
        runInAction(() => {
            if (isMobile && LS.activePartCategory === otherCategTransName) {
                LS.modal.showChooseBtn = false;
            } else {
                LS.modal.showChooseBtn = true;
            }
        });
    }, [isMobile, LS.activePartCategory, LS.modal, otherCategTransName]);

    // this should be made to be a map (and all dynamic objs, but for now - w.e)
    const servicesGroupedByPartCateg:
        | Record<string, ServiceAutocompleteOption[] | undefined>
        | undefined = React.useMemo(() => {
        const servicesNames = translatedServices.map((s) => s.serviceName);

        const groupedSortedServices = lMapValues(
            lGroupBy(translatedServices, (s) => s.partCategory),
            (services) =>
                // this orderBy is possible useless since there is 2nd ordering happening below
                lOrderBy(
                    services,
                    [(s) => !!s.package, (s) => s.sortOrder],
                    ["desc", "desc"]
                )
        );

        const popularSearchOptsFiltered = popularSearchOpts
            .filter((serviceName) => servicesNames.includes(serviceName))
            .map((serviceName) => {
                const service = kServicesByName.get(serviceName);
                if (!service) {
                    return console.error(
                        `service: ${serviceName} not found in autocomplete`
                    );
                }
                return service;
            })
            .filter(Boolean) as ServiceAutocompleteOption[];

        const sortedPopularOpts = lOrderBy(
            popularSearchOptsFiltered,
            [
                (s) => !!s.package,
                (s) => {
                    s.package
                        ? s.sortOrder
                        : popularSearchOpts.length -
                          popularSearchOpts.indexOf(s.serviceName); // revert index to be just like sortOrder
                },
            ],
            ["desc", "desc"]
        );

        if (isMobile) {
            // there is no "POPULAR_SERVICES" category on mobile. We just show it immediately at the top.
            delete groupedSortedServices[popularCategTransName];
        }
        return {
            ...groupedSortedServices,
            ...(!isMobile && {
                [popularCategTransName]: sortedPopularOpts,
            }),
            ...(p.onOtherServiceAdd && {
                // add this so there would be a category for other services
                [translatableCustomIDToTransString(
                    TranslatableCustomID_PartCategories.OTHER
                )]: [],
            }),
        };
    }, [
        translatedServices,
        isMobile,
        p.onOtherServiceAdd,
        kServicesByName,
        popularCategTransName,
        popularSearchOpts,
    ]);

    const categorySubcategories: string[] = React.useMemo(() => {
        if (LS.activePartCategory) {
            const servicesWithSubcategs = translatedServices.filter(
                (s) =>
                    s.partCategory === LS.activePartCategory ||
                    s.categorization.some(
                        (otherPartCateg) =>
                            otherPartCateg.partCategory ===
                                LS.activePartCategory &&
                            otherPartCateg.subCategory
                    )
            );

            const subCategs = servicesWithSubcategs.flatMap((s) =>
                s.categorization
                    .filter((c) => c.partCategory === LS.activePartCategory)
                    .map((opc) => opc.subCategory)
            ) as string[];

            return lUniq(subCategs);
        } else {
            return [];
        }
    }, [LS.activePartCategory, translatedServices]);

    const orderedCategPairs = React.useMemo(
        () =>
            lOrderBy(
                Object.entries(servicesGroupedByPartCateg),
                ([categName, _services]) => {
                    return CATEGORIES_ORDER.indexOf(
                        categName as (typeof CATEGORIES_ORDER)[number]
                    );
                },
                ["asc"]
            ),
        [servicesGroupedByPartCateg]
    );

    const groupedOptions: Record<string, string[]> | undefined =
        React.useMemo(() => {
            const grouped: Record<string, string[]> = {};
            if (LS.searchVal) {
                const optionNames = translatedServices.map(
                    (o) => o.serviceName
                );
                grouped[
                    transStr("Paslaugos atitinkančios paiešką", {
                        id: "3iA4laKx",
                    })
                ] = optionNames;
            } else if (LS.activeSubCategory && LS.activePartCategory) {
                const subCategServices = translatedServices.filter((s) =>
                    s.categorization.some(
                        (otherPartCateg) =>
                            otherPartCateg.partCategory ===
                                LS.activePartCategory &&
                            otherPartCateg.subCategory === LS.activeSubCategory
                    )
                );
                const sortedSubCategServices = lOrderBy(
                    subCategServices,
                    (s) => {
                        const categorization = s.categorization.find(
                            (c) =>
                                c.partCategory === LS.activePartCategory &&
                                c.subCategory === LS.activeSubCategory
                        );
                        return categorization?.sortOrder || 1;
                    },
                    ["desc"]
                );

                return {
                    [LS.activeSubCategory]: sortedSubCategServices.map(
                        (s) => s.serviceName
                    ),
                };
            } else if (LS.activePartCategory) {
                const categoryServices =
                    servicesGroupedByPartCateg[LS.activePartCategory];
                if (!categoryServices) return;

                const categoryServicesNamesWithoutSubCategs = categoryServices
                    .filter(
                        (s) =>
                            s.categorization.length === 0 ||
                            s.categorization.every(
                                (opc) =>
                                    opc.partCategory !== LS.activePartCategory
                            )
                    )
                    .map((s) => s.serviceName);

                let servicesNSubcategoriesNames = [
                    ...categorySubcategories,
                    ...categoryServicesNamesWithoutSubCategs,
                ];
                servicesNSubcategoriesNames = lOrderBy(
                    servicesNSubcategoriesNames,
                    (name) => {
                        const serviceOrPackage = kServicesByName.get(name);
                        if (LS.activePartCategory === popularCategTransName) {
                            return (
                                popularSearchOpts.length -
                                popularSearchOpts.indexOf(name)
                            ); // revert index to be just like sortOrder
                        } else if (
                            LS.activePartCategory &&
                            categorySubcategories.includes(name)
                        ) {
                            console.log("before getTransNameToTranslatableMap");

                            const partCategoryTranslatable =
                                transNameMaps.partCategory.get(
                                    LS.activePartCategory
                                );
                            const subCategoryTranslatable =
                                transNameMaps.subCategory.get(name);

                            if (
                                !isEnumValue(
                                    TranslatableCustomID_PartCategories,
                                    partCategoryTranslatable?.customID
                                ) ||
                                !isEnumValue(
                                    TranslatableCustomID_SubCategories,
                                    subCategoryTranslatable?.customID
                                )
                            ) {
                                console.error(
                                    `Didnt find partCategTranslatable or subCategTranslatable for partCateg: ${LS.activePartCategory} and subCateg: ${name}`
                                );
                                return 1;
                            }

                            const subcategoryOrder = getSubcategSortOrder({
                                partCategory: partCategoryTranslatable.customID,
                                subCategory: subCategoryTranslatable.customID,
                            });

                            return subcategoryOrder;
                        } else if (serviceOrPackage) {
                            // packages always up top
                            return serviceOrPackage.package
                                ? 999999
                                : serviceOrPackage.sortOrder;
                        } else {
                            return 1;
                        }
                    },
                    ["desc"]
                );

                if (LS.activePartCategory !== popularCategTransName) {
                    servicesNSubcategoriesNames =
                        servicesNSubcategoriesNames.filter(
                            (serviceName) =>
                                serviceName !== unknownProblemTransName
                        );

                    servicesNSubcategoriesNames.push(unknownProblemTransName);
                }

                grouped[LS.activePartCategory] = servicesNSubcategoriesNames;
            } else {
                // default case,
                if (isMobile) {
                    const optionNames = translatedServices.map(
                        (o) => o.serviceName
                    );
                    grouped[
                        transStr("Populiariausios paslaugos", {
                            id: "4xvTv07F",
                        })
                    ] = popularSearchOpts
                        .filter((popularOpt) =>
                            optionNames.includes(popularOpt)
                        )
                        .slice(0, 7);
                    grouped[transStr("Kategorijos", { id: "FTUjZS6K" })] =
                        orderedCategPairs.map(
                            ([partCateg, _services]) => partCateg
                        );
                }
            }
            return grouped;
        }, [
            isMobile,
            LS.searchVal,
            translatedServices,
            LS.activePartCategory,
            LS.activeSubCategory,
            orderedCategPairs,
            servicesGroupedByPartCateg,
            kServicesByName,
            categorySubcategories,
            popularCategTransName,
            unknownProblemTransName,
            popularSearchOpts,
            transNameMaps,
        ]);

    const filteredOptions = React.useMemo(() => {
        let finalOpts: string[] | undefined;
        if (LS.searchVal) {
            const fzfSearchOptions = translatedServices.map((s) => ({
                searchName: latinize(`${s.serviceName}, ${s.searchAliases}`),
                displayName: s.serviceName,
            }));
            const fzf = new Fzf(fzfSearchOptions, {
                selector: (s) => s.searchName,
                casing: "case-insensitive",
                tiebreakers: [byStartAsc],
            }); // could be optimized to not recreate Fzf everytime.
            const fzfOpts = fzf.find(latinize(LS.searchVal));
            finalOpts = fzfOpts.map((fzfOpt) => fzfOpt.item.displayName.trim()); // add trim as a temp solution for multi package services. with categorization can be removed (we add a space at the end of the package name to avoid duplication, very hacky - but works for now. Should be replaced by usage of `categorization`)
            finalOpts = finalOpts.filter(
                (opt) => opt !== unknownProblemTransName
            );
            finalOpts.push(unknownProblemTransName);
            finalOpts = lUniq(finalOpts.slice(0, 10)); // as temp solution for multi package services. with categorization can be removed
        } else {
            if (!groupedOptions) return [];
            const allOptionsSortedByCateg = [
                ...Object.values(groupedOptions).flat(),
            ];
            finalOpts = allOptionsSortedByCateg;
        }

        return finalOpts;
    }, [
        LS.searchVal,
        groupedOptions,
        translatedServices,
        unknownProblemTransName,
    ]);

    const canFinalizeChoice = React.useCallback(() => {
        if (!p.errorsEnabled) return true;
        if (p.value.length > 0) return true;
        showToast.warn(
            transStr("Pasirinkite bent vieną paslaugą", { id: "6xy7fkCA" })
        );
        return false;
    }, [p.value, p.errorsEnabled]);

    const groupBy: (option: string) => string = React.useCallback(
        (option) => {
            if (!groupedOptions) return "";

            const pairFound = Object.entries(groupedOptions).find(
                ([_groupName, groupArr]) => groupArr?.includes(option)
            );
            if (!pairFound) {
                console.warn("not found", option, groupedOptions);
                return "";
            }
            const [groupName, _groupArr] = pairFound;
            return groupName;
        },
        [groupedOptions]
    );

    const clearSubcateg = React.useCallback(() => {
        runInAction(() => {
            LS.activeSubCategory = undefined;
        });
    }, [LS]);

    const isError = React.useMemo(() => {
        return !!(
            p.errorsEnabled &&
            !LS.isOpen &&
            (LS.wasOpenedOnce || p.forceInputErr) &&
            p.value.length === 0
        );
    }, [
        p.errorsEnabled,
        LS.isOpen,
        LS.wasOpenedOnce,
        p.forceInputErr,
        p.value.length,
    ]);

    const inputProps = {
        placeholder:
            p.value.length === 0
                ? LS.isOpen
                    ? transStr("Įvesk paslaugą", { id: "dexZqFwr" })
                    : transStr("Pasirink paslaugą", { id: "08cpByoa" })
                : "",
        leftIconClass: "icon-magnifier",
        error: isError,
        helperText: p.errorsEnabled
            ? transStr("Pasirinkite bent vieną paslaugą", { id: "tQNyrMTY" })
            : undefined,
        forceError: isError,
        // hide search input on mobile when OTHER_CATEG is chosen. To avoid this, it would be also possible to move this into `renderOption` method.
        ...(isMobile &&
            LS.activePartCategory === otherCategTransName && {
                display: "none",
            }),
    } as InputP;

    const mobileInputProps: InputP = React.useMemo(() => {
        return {
            InputProps: {
                startAdornment: [
                    p.value[0] && (
                        <S.ExpansionValueChip
                            key={p.value[0]}
                            label={p.value[0]}
                        />
                    ),
                    p.value.length > 1 && (
                        <S.ExpansionValueChip
                            sx={{
                                flexGrow: 111111,
                                flexShrink: 0,
                            }}
                            key={"other"}
                            label={`+${p.value.length - 1}`}
                        />
                    ),
                ],
            },
        };
    }, [p.value]);

    const onSearchValChangeCb = React.useCallback(
        (_e, v: string) => {
            runInAction(() => {
                LS.searchVal = v;
            });
        },
        [LS]
    );

    const isServiceOrPackageSelected = React.useCallback(
        (s: ServiceAutocompleteOption): boolean => {
            if (s.package) {
                return Boolean(
                    s.additionalTexts?.every((serviceName) => {
                        return p.value.includes(serviceName);
                    })
                );
            } else {
                return p.value.includes(s.serviceName);
            }
        },
        [p.value]
    );

    const handleServiceSelect = React.useCallback(
        (vals: string[]) => {
            const lastVal = vals.slice(-1)[0] as string | undefined; // can be undefined if array has no elements

            let serviceOrPackage: ServiceAutocompleteOption | undefined;

            if (lastVal) {
                serviceOrPackage = kServicesByName.get(lastVal);
                if (!serviceOrPackage) {
                    return console.error(
                        `service: ${lastVal} not found in autocomplete`
                    );
                }
            }

            if (serviceOrPackage?.package) {
                if (isServiceOrPackageSelected(serviceOrPackage)) {
                    const newVals = p.value.filter((serviceName) => {
                        return !serviceOrPackage?.additionalTexts?.includes(
                            serviceName
                        );
                    });
                    onChange(newVals);
                } else {
                    const newVals = [
                        ...p.value,
                        ...(serviceOrPackage.additionalTexts || []),
                    ];
                    onChange(newVals);
                }
            } else {
                onChange(vals);
            }
        },
        [isServiceOrPackageSelected, onChange, p.value, kServicesByName]
    );

    const onChangeCb = React.useCallback(
        (vals: string[]) => {
            const lastVal = vals.slice(-1)[0];
            const categs = Object.keys(servicesGroupedByPartCateg);

            if (categs.includes(lastVal)) {
                runInAction(() => {
                    LS.activePartCategory = lastVal;
                });
            } else if (
                !LS.activeSubCategory /* because there can be services names that match the subcategory name */ &&
                categorySubcategories.includes(lastVal)
            ) {
                runInAction(() => {
                    LS.activeSubCategory = lastVal;
                });
            } else {
                handleServiceSelect(vals);
                if (vals.length < p.value.length && onClose && !LS.isOpen) {
                    // if removing value via "X" chip button, call the onClose handler
                    onClose();
                }
            }
        },
        [
            categorySubcategories,
            handleServiceSelect,
            LS,
            servicesGroupedByPartCateg,
            onClose,
            p.value.length,
        ]
    );

    const onOtherServiceAddCb = React.useCallback(() => {
        if (!onOtherServiceAdd) return;

        onOtherServiceAdd();
        close();
    }, [onOtherServiceAdd, close]);

    const PaperComponentCb = React.useCallback(
        (props) => {
            if (translatedServices.length === 0) {
                return (
                    <S.DropdownContainer
                        {...props}
                        style={{ position: "static" }}
                    >
                        <CircularProgress
                            sx={{
                                position: "absolute",
                                left: "calc(50% - 20px)",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        />
                    </S.DropdownContainer>
                );
            }
            return (
                <S.DropdownContainer {...props}>
                    {isMobile ? undefined : (
                        <S.DesktopCategsWrapper>
                            <List disablePadding>
                                {orderedCategPairs.map(([categ, _services]) => {
                                    const partCategTranslatable =
                                        transNameMaps.partCategory.get(categ);

                                    const partCategImgUrl = isEnumValue(
                                        TranslatableCustomID_PartCategories,
                                        partCategTranslatable?.customID
                                    )
                                        ? PART_CATEG_TO_IMG_URL[
                                              partCategTranslatable?.customID
                                          ]
                                        : undefined;

                                    return (
                                        <S.ListItem
                                            $active={
                                                LS.activePartCategory === categ
                                            }
                                            sx={{
                                                whiteSpace: "nowrap",
                                                fontWeight: 500,
                                            }}
                                            key={categ}
                                            onClick={() => {
                                                runInAction(() => {
                                                    LS.activeSubCategory =
                                                        undefined;
                                                    LS.activePartCategory =
                                                        categ;
                                                    LS.searchVal = "";
                                                });
                                            }}
                                        >
                                            {partCategImgUrl && (
                                                <Img
                                                    src={partCategImgUrl}
                                                    width="32px"
                                                    height="32px"
                                                    mr={1.5}
                                                />
                                            )}
                                            {categ}
                                        </S.ListItem>
                                    );
                                })}
                            </List>
                        </S.DesktopCategsWrapper>
                    )}
                    {LS.activePartCategory === otherCategTransName ? (
                        <Box padding={"0px 16px"}>
                            <ListSubheader sx={{ padding: "0!important" }}>
                                {otherCategTransName}
                            </ListSubheader>
                            <Box mt={1}>
                                <S.Bold>
                                    <TransMsg
                                        default={"Kažko trūksta?"}
                                        id="1zErIZLd"
                                    />
                                </S.Bold>{" "}
                                <TransMsg
                                    default={
                                        "Paspauskite mygtuką žemiau ir galėsite įvesti paslaugą kurios nerandate. Mūsų komanda bus informuota, jog neradote šios paslaugos ir pridės ją į jūsų paslaugų sąrašą."
                                    }
                                    id="6AXP2a3D"
                                />
                            </Box>
                            <S.AddOtherServiceBtn
                                fullWidth
                                onClick={onOtherServiceAddCb}
                            >
                                <TransMsg
                                    default={"Pridėti kitą paslaugą"}
                                    id="s2QJLBTt"
                                />
                            </S.AddOtherServiceBtn>
                        </Box>
                    ) : (
                        <Box
                            width={1}
                            pl={1}
                            sx={(theme) => ({
                                pl: 1,
                                [theme.breakpoints.down("md")]: {
                                    pl: 0,
                                },
                            })}
                        >
                            <Box
                                pt={0.5}
                                mb={0.5}
                                sx={(t) => ({
                                    [t.breakpoints.down("md")]: {
                                        pl: 0,
                                    },
                                })}
                            >
                                {!LS.activeSubCategory && (
                                    <S.CommentText variant="subtitle1">
                                        <Icon
                                            className="icon-info-circle"
                                            mr={0.5}
                                        />
                                        <TransMsg
                                            default={
                                                "Rezervacijoje galėsite palikti komentarą"
                                            }
                                            id="E93KpD7r"
                                        />
                                    </S.CommentText>
                                )}
                                {LS.activeSubCategory && !isMobile ? (
                                    <Button
                                        sx={{ mb: 0.5 }}
                                        onClick={clearSubcateg}
                                        leftIconClass={"icon-arrow-left"}
                                        size="small"
                                        color="greyish"
                                    >
                                        {transCommonTranslatable("BACK")}
                                    </Button>
                                ) : undefined}
                            </Box>
                            {props.children}
                        </Box>
                    )}
                </S.DropdownContainer>
            );
        },
        [
            LS,
            isMobile,
            orderedCategPairs,
            onOtherServiceAddCb,
            translatedServices,
            clearSubcateg,
            otherCategTransName,
            transNameMaps,
        ]
    );

    return (
        <AutocompleteWithModal
            multiple
            modal={LS.modal}
            value={p.value}
            limitTags={1}
            canFinalizeChoice={canFinalizeChoice}
            inputValue={LS.searchVal}
            inputProps={inputProps}
            onChange={onChangeCb}
            onInputChange={onSearchValChangeCb}
            options={filteredOptions}
            className={p.className}
            open={LS.isOpen}
            onOpen={LS.open}
            onClose={LS.close}
            clearIcon={false}
            groupBy={groupBy}
            mobileInputProps={mobileInputProps}
            renderOption={(props, option, _state) => {
                console.log("renderOption", option);
                const isCategOpt = Object.keys(
                    servicesGroupedByPartCateg
                ).includes(option);
                const isSubCategOpt = categorySubcategories.includes(option);
                const service = kServicesByName.get(option);

                if (isCategOpt) {
                    const partCategTranslatable =
                        transNameMaps.partCategory.get(option);

                    const partCategImgUrl = isEnumValue(
                        TranslatableCustomID_PartCategories,
                        partCategTranslatable?.customID
                    )
                        ? PART_CATEG_TO_IMG_URL[partCategTranslatable?.customID]
                        : undefined;

                    /** This case will only happen on mobile views. */
                    const categActiveCount = servicesGroupedByPartCateg[
                        option
                    ]?.reduce((acc, service) => {
                        return (
                            acc +
                            (p.value.includes(service.serviceName) ? 1 : 0)
                        );
                    }, 0);

                    return (
                        <S.ListItem {...props}>
                            <>
                                {partCategImgUrl && (
                                    <Img
                                        src={partCategImgUrl}
                                        width="24px"
                                        height="24px"
                                        mr={1.5}
                                    />
                                )}
                                <Text span semiBold>
                                    {option}
                                </Text>
                                <S.CategRightWrapper>
                                    {categActiveCount && categActiveCount > 0
                                        ? `${categActiveCount} ${
                                              translatables().CHOSEN
                                          }`
                                        : ""}
                                    <S.RightArrow className="icon-arrow-right" />
                                </S.CategRightWrapper>
                            </>
                        </S.ListItem>
                    );
                } else if (isSubCategOpt && !LS.activeSubCategory) {
                    const thisSubcategory = option;
                    // const subCategActiveCount = servicesGroupedByPartCateg[
                    //     option
                    // ]?.reduce((acc, service) => {
                    //     return (
                    //         acc +
                    //         (p.value.includes(service.serviceName) ? 1 : 0)
                    //     );
                    // }, 0);

                    const subCategActiveCount = p.value.reduce(
                        (acc, serviceName) => {
                            const service = kServicesByName.get(serviceName);
                            const serviceBelongsToSubCateg =
                                service?.categorization.some(
                                    (opc) => opc.subCategory === thisSubcategory
                                );

                            return acc + (serviceBelongsToSubCateg ? 1 : 0);
                        },
                        0
                    );

                    return (
                        <S.ListItem {...props}>
                            <>
                                <Text span semiBold>
                                    {option}
                                </Text>
                                <S.CategRightWrapper>
                                    {subCategActiveCount &&
                                    subCategActiveCount > 0
                                        ? `${subCategActiveCount} ${
                                              translatables().CHOSEN
                                          }`
                                        : ""}
                                    <S.RightArrow className="icon-arrow-right" />
                                </S.CategRightWrapper>
                            </>
                        </S.ListItem>
                    );
                } else if (service) {
                    return (
                        <S.ListItem
                            {...props}
                            $bulky={service.bulky}
                            sx={{
                                pr: 1,
                            }}
                        >
                            {service.imgUrl && (
                                <S.ListItemImg
                                    src={service.imgUrl}
                                    alt={service.serviceName}
                                />
                            )}
                            {service.important &&
                                LS.activePartCategory !==
                                    popularCategTransName && (
                                    <Text
                                        mr={1}
                                        component="i"
                                        className="icon-star-full"
                                        color="#FFB600"
                                    />
                                )}
                            <span>{option}</span>
                            <S.ServiceRadio
                                size="small"
                                checked={isServiceOrPackageSelected(service)}
                            />
                        </S.ListItem>
                    );
                } else {
                    console.error(`Unknown scenario for option: ${option}`);
                    return null;
                }
            }}
            PaperComponent={PaperComponentCb}
            PopperComponent={S.PopperComponent}
            goBack={LS.goBack}
        />
    );
});

export default ServicesAutocomplete;
