import mergeServicesWithPackages, {
    MergedServiceOrPackage,
} from "./mergeServicesWithPackages";
import { WorkshopService } from "@FEShared/graphql/generated/graphql";
import mapKeyBy from "./mapKeyBy";
import getServicesPriceNDuration, {
    ServicesPriceNDuration,
} from "../../FEShared/utils/getServicesPriceNDuration";
import { WorkshopDTO } from "@FEClient/views/pages/Workshop/Workshop.types";

export type MergedServiceOrPackageForWorkshop = MergedServiceOrPackage & {
    priceNDuration: ServicesPriceNDuration;
    specialized?: boolean;
};

export default function mergeServicesWithPackagesForWorkshop(
    workshop: Pick<WorkshopDTO, "hourCost" | "services" | "servicePackages">
): MergedServiceOrPackageForWorkshop[] {
    const merged = mergeServicesWithPackages({
        packages: workshop.servicePackages,
        services: workshop.services.map((s) => s.type),
    });

    const mergedForWorkshop = merged
        .map((m) => {
            if (m.package) {
                const servicesMapByID = mapKeyBy(
                    workshop.services,
                    (s) => s.type.ID
                );

                const packageServices = m.package.servicesDefinitionsIDs
                    .map((serviceID) => {
                        const service = servicesMapByID.get(serviceID);
                        if (!service) {
                            return console.error(
                                `Service not found from package: ${m.serviceName}, serviceID: ${serviceID}`
                            );
                        }
                        return service;
                    })
                    .filter(Boolean) as WorkshopService[];

                const priceNDuration = getServicesPriceNDuration(
                    packageServices,
                    workshop.hourCost
                );

                return {
                    ...m,
                    priceNDuration,
                };
            } else {
                const servicesMapByName = mapKeyBy(
                    workshop.services,
                    (s) => s.type.serviceName
                );

                const service = servicesMapByName.get(m.serviceName);
                if (!service) {
                    return console.error(
                        `service not found in mergeServicesWithPackages`
                    );
                }

                const priceNDuration = getServicesPriceNDuration(
                    [service],
                    workshop.hourCost
                );

                return {
                    ...m,
                    priceNDuration,
                    specialized: service.specialized,
                };
            }
        })
        .filter(Boolean) as MergedServiceOrPackageForWorkshop[];

    return mergedForWorkshop;
}
