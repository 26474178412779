import Country from "@Shared/types/enums/Country";
import { transStr } from "@FEShared/i18n";

export function checkIsOpenNow(
    calendarDay?: {
        open: { hour: number; minute: number };
        close: { hour: number; minute: number };
    } | null
) {
    if (!calendarDay) return false;

    const { open, close } = calendarDay;
    const d = new Date();
    const hours = d.getHours();
    const mins = d.getMinutes();
    return (
        (hours > open.hour || (hours === open.hour && mins >= open.minute)) &&
        (hours < close.hour || (hours === close.hour && mins <= close.minute))
    );
}

export function timeAgo(date: Date): string {
    const now = new Date();
    const inputDate = new Date(date);
    const msPerMin = 1 * 60 * 1000;
    const msPerHour = 1 * 60 * 60 * 1000;
    const msPerDay = 24 * 60 * 60 * 1000;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerMonth * 12;

    const msDiff = now.getTime() - inputDate.getTime();

    if (msDiff >= msPerYear) {
        const years = Math.floor(msDiff / msPerYear);
        return transStr("prieš {years} metus", {
            data: { years },
            id: "GzeuG5bK",
        });
    } else if (msDiff >= msPerMonth) {
        const months = Math.floor(msDiff / msPerMonth);
        return transStr("prieš {months}mėn.", {
            data: { months },
            id: "hpZRfdhY",
        });
    } else {
        const days = Math.floor(msDiff / msPerDay);
        const hours = Math.floor(msDiff / msPerHour);
        if (hours === 0) {
            return transStr("prieš {mins}min.", {
                data: { mins: Math.floor(msDiff / msPerMin) || 1 },
                id: "uAU2eSUc",
            });
        } else if (days === 0) {
            return transStr("prieš {hours}val.", {
                data: { hours: Math.floor(msDiff / msPerHour) || 1 },
                id: "ubgVxLWH",
            });
        } else if (days === 1) {
            return transStr("vakar", { id: "poOzeGvp" });
        }

        return transStr("prieš {days}d.", { data: { days }, id: "rwVPE855" });
    }
}

type Meta = {
    title: string;
    descr: string;
    workshopKeyword: string;
};

export function getDynamicWorkshopMeta(p: {
    country: Country;
    workshopName: string;
    locationText: string;
}): Meta {
    const countryMeta: Meta = {
        title: "",
        descr: "",
        workshopKeyword:
            p.country === Country.LT ? "Autoservisai" : "Mechanics",
    };

    if (p.country === Country.LT) {
        countryMeta.title = `${p.workshopName} | 24/7 Registracija, Kainos, Garantija`;
        countryMeta.descr = `${p.workshopName}, ${p.locationText} - 24/7 Registracija, Paslaugų kainos, 100% Darbų garantija, verifikuoti atsiliepimai.`;
    } else if (p.country === Country.CA) {
        countryMeta.title = `${p.workshopName} | Book 24/7, Prices, 100% Warranty`;

        countryMeta.descr = `${p.workshopName}, ${p.locationText} - Book 24/7, Repairs prices, 100% Warranty, verified reviews.`;
    } else {
        console.error(`No meta for country: ${p.country}`);
    }
    return countryMeta;
}

// export function calcPriceRange(hourCost: number): string {
//     const hourPriceAvg = 42;

//     const priceRanges = {
//         oneDollarRange: hourPriceAvg * 0.66,
//         twoDollarRange: hourPriceAvg * 1.33,
//         threeDollarRange: hourPriceAvg * 1.33 * 2,
//     };

//     if (hourCost <= priceRanges.oneDollarRange) {
//         return "${window._COUNTRY_META.currencySymbol}";
//     } else if (
//         priceRanges.oneDollarRange < hourCost &&
//         hourCost <= priceRanges.twoDollarRange
//     ) {
//         return "${window._COUNTRY_META.currencySymbol}${window._COUNTRY_META.currencySymbol}";
//     } else if (
//         priceRanges.twoDollarRange < hourCost &&
//         hourCost >= priceRanges.twoDollarRange
//     ) {
//         return "${window._COUNTRY_META.currencySymbol}${window._COUNTRY_META.currencySymbol}${window._COUNTRY_META.currencySymbol}";
//     } else {
//         console.error("Unhandled dollar ranges case");
//         return "${window._COUNTRY_META.currencySymbol}${window._COUNTRY_META.currencySymbol}";
//     }
// }
