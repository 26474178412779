import CAR_MODELS from "@FEShared/components/UI/CarAutocomplete/CarModels.json";
import Country from "@Shared/types/enums/Country";
import urlifyString from "@FEShared/utils/urlifyString";
import { CITIES_WITH_ALL } from "@Shared/consts/CITIES";

function reverseMapKeysWithValues(
    map: Map<string, string>
): Map<string, string> {
    const reversedKeyValuePairs = Array.from(map).map(([key, value]) => [
        value,
        key,
    ]);

    return new Map(reversedKeyValuePairs as [string, string][]);
}

export const URLIFIED_CAR_BRANDS_MAP: Map<
    string /* urlified */,
    string /* original */
> = Object.keys(CAR_MODELS).reduce((acc, carBrand) => {
    acc.set(urlifyString(carBrand), carBrand);
    return acc;
}, new Map());

export const BRAND_TO_URLIFIED_MAP = reverseMapKeysWithValues(
    URLIFIED_CAR_BRANDS_MAP
);

export const URLIFIED_CITIES_MAP: Map<
    string /* urlified */,
    string /* original */
> = Object.values(CITIES_WITH_ALL)
    .flat()
    .reduce((acc, cityName) => {
        acc.set(urlifyString(cityName), cityName);
        return acc;
    }, new Map());

export const CITY_TO_URLIFIED_MAP =
    reverseMapKeysWithValues(URLIFIED_CITIES_MAP);

export const ALL_SERVICES_NAME_FILLER: Record<Country, string> = {
    [Country.LT]: "visos-paslaugos",
    [Country.CA]: "repair-maintenance",
};
